import { AddCircle, ArrowBack } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartmentList } from 'src/redux/department/actions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ConfirmDelete from 'src/components/ConfirmDelete'
import { axiosDELETE, axiosGET } from 'src/utility/apis'
import { getOrganizationList } from 'src/redux/organization/actions'
import { toast } from 'react-toastify'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import DeleteIcon from '../../../assets/adminBoard/trash.svg'
import AddModalCategory from './AddModalCategory'
import { getCategoryList } from 'src/redux/category/actions'
import { Skeleton } from '@mui/material'
import NoDataImage from '../../../assets/adminBoard/nodata.svg'
import pulse from '../../../assets/adminBoard/pulse.svg'
import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'

export default function Category() {
  const [visibleCategoryModal, setsetVisibleCategoryModal] = useState(false)
  const { data, isLoading } = useSelector((state) => state.category)
  const { id } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteobjId, setDeleteobjId] = useState(null)
  const [iseditObj, setIseditObj] = useState(null)
  const location = useLocation()
  const [deptData, setDeptData] = useState(location?.state?.data)

  const handleDeleteModalCancel = () => {
    setDeleteModal(false)
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      dispatch(getCategoryList(navigate, 10, 1, '', id))
    }
  }, [id])

  const userRole = useSelector((state) => state.auth.userData?.user_type)
  const department_id = useSelector((state) => state.auth.userData?.department_id)
  const organization_id = useSelector((state) => state.auth.userData?.organization_id)
  const {id:DepartmentId} = useSelector((state) => state.department?.data?.data[0]);

  const getOrganizationDetails = async () => {
    try {
      let res = await axiosGET(`department/${DepartmentId}`)
      setDeptData(res)
    } catch (err) {
      toast('Something went wrong !!', 'error')
    }
  }

  useEffect(() => {
    if (userRole === 3) {
      getOrganizationDetails()
    }
  }, [])

  const handleDeleteCategory = async (delId) => {
    try {
      await axiosDELETE(`category/${delId}`)
      toast('Deleted successfully!', 'success')
      dispatch(getCategoryList(navigate, 10, 1, '', id))
      setDeleteModal(false)
    } catch (err) {
      toast('Something went wrong !!', 'error')
    }
  }

  useEffect(() => {
    if (!visibleCategoryModal) {
      dispatch(getCategoryList(navigate, 10, 1, '', id))
    }
  }, [visibleCategoryModal])

  return (
    <>
      <div className="content-area ">
        {userRole === 2 && (

          <div
            className="d-flex mb-3"
            onClick={() => navigate('/department/' + organization_id)}
            style={{ cursor: 'pointer' }}
          >
            <ArrowBack style={{ marginRight: '8px', fontSize: '1.5rem' }} />
            <h4>{deptData?.name}</h4>
          </div>

        )}
        {userRole === 3 && (
          <div
            className="d-flex mb-3"
            onClick={() => navigate('/department/' + organization_id)}
            style={{ cursor: 'pointer' }}
          >
            <ArrowBack style={{ marginRight: '8px', fontSize: '1.5rem' }} />
            <h4>{deptData?.name}</h4>
          </div>

          )}
        {userRole === 1 && (
          <><div className='breadcrumb-org'>
            <CBreadcrumb style={{ "--cui-breadcrumb-divider": "'>'" }}>
              <CBreadcrumbItem className='breadcrumb-item' href="/user-management">{'Organisations'}</CBreadcrumbItem>
              <CBreadcrumbItem href={`/department/${deptData?.organization_id}`} onClick={() => {
                navigate(`/department/${deptData?.organization_id}`, {
                  state: { data: location?.state?.orgDetails },
                })
              }} >{'Departments'}</CBreadcrumbItem>
              <CBreadcrumbItem active >{deptData?.name}</CBreadcrumbItem>

            </CBreadcrumb>
          </div>
            <div className="brief-cstm mb-4">
              <h5>
                <u>Department details</u>
              </h5>
              <h6>Department name : {deptData?.name}</h6>
              <h6>Department mail : {deptData?.email}</h6>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between mb-4">
          <h4 className="d-flex align-items-end">Categories</h4>
          <button
            className="btn btn-add"
            onClick={() => setsetVisibleCategoryModal(true)}
            style={{ cursor: 'pointer' }}
          >
            <AddCircle style={{ marginRight: '8px', fontSize: '20px' }} />
            Add new
          </button>
        </div>
        <div className='d-flex flex-column gap-1'>
        {!isLoading &&
          data?.data?.map((itm) => (
            <>
              <div className="upload-items uploded" key={itm?.id}>
                <div className="uploaded-data d-flex">
                  <div className="progress-sc me-3">
                    <h6 className="mb-0">
                      <span>{itm?.label}</span>
                    </h6>
                  </div>
                  <div className="close-icon d-flex">
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '160px',
                        justifyContent: 'end',
                      }}
                    >
                      <CIcon
                        style={{ marginRight: '12px', cursor: 'pointer' }}
                        icon={cilPencil}
                        onClick={() => {
                          setsetVisibleCategoryModal(true)
                          setIseditObj(itm)
                        }}
                      />
                      <img
                        src={DeleteIcon}
                        style={{ marginRight: '12px', cursor: 'pointer' }}
                        alt=""
                        width={18}
                        height={22}
                        onClick={() => {
                          setDeleteobjId(itm.id)
                          setDeleteModal(true)
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
        {isLoading && (
          <div className="upload-items uploded">
            <div className="uploaded-data d-flex">
              <div className="progress-sc me-3">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center mx-2">Loading </div>
                  <img src={pulse} alt="loader" width={36} />
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoading && data?.length === 0 && (
          <div className="nodata text-center">
            <img src={NoDataImage} alt="nodata" width={40} height={40} />
            <span style={{ marginLeft: '8px', fontWeight: '700' }}>No data available</span>
          </div>
        )}
      </div>
      <AddModalCategory
        visible={visibleCategoryModal}
        setVisible={setsetVisibleCategoryModal}
        iseditObj={iseditObj}
        deptId={id}
        closeModal={() => {
          setsetVisibleCategoryModal(false)
          setIseditObj(null)
        }}
      />
      <ConfirmDelete
        isCustomBoard={true}
        visible={deleteModal}
        cancelDelete={handleDeleteModalCancel}
        confirmDelete={() => handleDeleteCategory(deleteobjId)}
      />
    </>
  )
}
