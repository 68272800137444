/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import NoAuthHeader from 'src/components/NoAuthHeader'
import Input from './input'
import { useEffect, useMemo, useRef, useState } from 'react'

import skeltonLoaderImage from '../../../assets/adminBoard/skelton-loader.png'

import Messages from './messages'
import uuid from 'react-uuid'
import { ContentCopy } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { axiosPOST } from 'src/utility/apis'
import { logout } from 'src/redux/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import clearChat from '../../../assets/adminBoard/trash.svg'
import { clearHistory } from 'src/redux/historyManagment/actions'
import { appendMessage, clearMessages, deleteMessage } from 'src/redux/message/action'

export default function Chat({
  Category,
  subText,
  categoryTitle,
  categoryId,
  expandChat,
  firstMessage,
  isEmbed,
  token,
  setCategoryTitle,
  setCategoryId,
}) {
  const { msgs } = useSelector((state) => state.userChatMessages)
  const { userData } = useSelector((state) => state.auth)  
  const userMsgs = useMemo(() => msgs?.filter((data) => data.user === userData?.id) || [], [msgs])
  const [loading, setLoading] = useState(false)
  const [chatLoading, setChatloading] = useState(false)
  const [streamingStatus, setStreamingStatus] = useState(false)
  // eslint-disable-next-line
  const [, setEventSource] = useState(null)
  const dispatch = useDispatch()
  const [selectedValue] = useState('HELPDESK')
  const [isUserScroll, setIsUserScroll] = useState(false)
  const [runningStream, setRunningStream] = useState(null)
  const [reader, setReader] = useState(null); 
  const chatAreaRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  useEffect(() => {
    if (firstMessage && categoryId) {
      let id = uuid()
      dispatch(
        appendMessage([
          {
            msg: "I'm Whizz, your AI Chatbot, trained on your data. I'm in learning mode, continuously improving with your feedback.",
            me: false,
            _id: id,
            id: id,
            defaultMsg: true,
            user: userData?.id,
            // category_id: categoryId,
          },
        ]),
      )
    }
  }, [firstMessage, categoryId])

  function handleClearHistory() {
    dispatch(clearHistory())
    dispatch(clearMessages())
    setCategoryId(null)
    setCategoryTitle(null)
  }

  function addMessage(msg) {
    dispatch(appendMessage([msg]))
  }

  function toggleLoading(value) {
    setLoading(value)
  }

  // function stoporRegenrateMessage() {
  //   if (streamingStatus) {
  //     if (!loading) {
  //       setStreamingStatus(false)
  //       setChatloading(false)
  //       // runningStream.close()
  //       toggleLoading(false)
  //     }
  //   } else {
  //     onRegenerate()
  //     setIsUserScroll(false)
  //     setStreamingStatus(true)
  //     setChatloading(true)
  //   }
  // }

  const stoporRegenrateMessage = () => {
    if (streamingStatus && reader) {
      reader.cancel();
      setStreamingStatus(false)
      setChatloading(false)
      toggleLoading(false)
    } else {
      onRegenerate(); 
      setIsUserScroll(false)
      setStreamingStatus(true)
      setChatloading(true)
    }
  }
  // function onRegenerate() {
  //   if (userMsgs?.length !== 0 && !userMsgs[userMsgs.length - 1].me) {
  //     var input = userMsgs[userMsgs?.length - 2].msg
  //     dispatch(deleteMessage(userMsgs.slice(0, userMsgs?.length - 1)))
  //     // setMessages(userMsgs.slice(0, userMsgs.length - 1))
  //     toggleLoading(true)
  //     const encodeInput = encodeURIComponent(input)
  //     let categoryOrToken =
  //       categoryId !== null ? `&category_id=${categoryId}` : token !== null ? `&token=${token}` : ''

  //     const eventSource = new EventSource(
  //       `${
  //         process.env.REACT_APP_API_ENDPOINT
  //       }/conversion?question=${encodeInput}${categoryOrToken}&session_id=${localStorage.getItem(
  //         'sessionId',
  //       )}`,
  //     )
  //     setRunningStream(eventSource)
  //     var updateid = uuid()
  //     eventSource.onmessage = (event) => {
  //       setStreamingStatus(true)

  //       // const response = JSON.parse(event)
  //       toggleLoading(false)
  //       addMessage(
  //         {
  //           msg: event.data,
  //           me: false,
  //           _id: updateid,
  //           id: updateid,
  //           category_id: categoryId,
  //           user: userData?.id,
  //         },
  //         'ADD_MESSAGE',
  //       )
  //     }
  //     eventSource.onerror = (error) => {
  //       setStreamingStatus(false)
  //       setChatloading(false)
  //       console.error('Error with EventSource:', error)
  //       eventSource.close()
  //     }
  //     setEventSource(eventSource)

  //     return () => {
  //       if (eventSource) {
  //         eventSource.close()
  //       }
  //     }
  //   }
  // }

  const onRegenerate = async () => {
    if (userMsgs?.length !== 0 && !userMsgs[userMsgs.length - 1].me) {
      const input = userMsgs[userMsgs.length - 2].msg;
      dispatch(deleteMessage(userMsgs.slice(0, userMsgs.length - 1)));
      setLoading(true); 

      const bodyData = {
        question: input,
        session_id: localStorage.getItem('sessionId'),
        user_id: userData?.id,
        ...(categoryId ? { category_id: categoryId } : {}),
        ...(token ? { token: token } : {}),
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/chat/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const newReader = response.body.getReader(); // Get the new reader
        setReader(newReader); // Set the reader to state
        const decoder = new TextDecoder();
        let done = false;

        const updateid = uuid();
        setStreamingStatus(true);

        while (!done) {
          const { value, done: readerDone } = await newReader.read();
          done = readerDone;
          setLoading(false);

          if (value) {
            const chunk = decoder.decode(value, { stream: !done });

            // Update the message list with the new message
            addMessage(
              {
                msg: chunk,
                me: false,
                _id: updateid,
                id: updateid,
                category_id: categoryId,
                user: userData?.id,
              },
              'ADD_MESSAGE',
            );
          }
        }
      } catch (error) {
        console.error('Error during POST request:', error);
      } finally {
        setLoading(false); // Reset loading state
        setStreamingStatus(false); // Reset streaming status
        setChatloading(false)
      }
    }
  };

  useEffect(() => {
    // Add a slight delay to prevent glitches during rapid message updates
    const scrollTimeout = setTimeout(() => {
      if (isAtBottom && chatAreaRef.current) {
        chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
      }
    }, 10);
  
    return () => clearTimeout(scrollTimeout); // Clear timeout to prevent memory leaks
  }, [userMsgs,isAtBottom]);
  
const chatScroll=()=>{
  const { scrollTop, scrollHeight, clientHeight } = chatAreaRef.current;
    // Check if user is at the bottom
    const isCurrentlyAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setIsAtBottom(isCurrentlyAtBottom);
}

  useEffect(() => {
    if (runningStream) {
      runningStream.close()
      setStreamingStatus(false)
      setChatloading(false)
      toggleLoading(false)
    }
  }, [categoryId])

  const copyCode = async () => {
    if (categoryId) {
      let payload = {
        category_id: categoryId,
      }
      const result = await axiosPOST('embedded/', payload)
      if (result?.status === 401) {
        dispatch(logout())
      }

      const iframeUrl = `<iframe src="${window.location.origin}/chat/embed/${result.chat_id}" width="465" height="870"></iframe>`
      navigator.clipboard.writeText(iframeUrl)
      codeCopied()
    } else {
      toast.error('Please choose a category')
    }
  }

  const codeCopied = () => toast.success('Code Copied')
  return (
    <>
      <div
        className={`chat-section ${expandChat ? 'expand-chat' : ''}`}
        style={{ left: isEmbed && '0', height: isEmbed && '100vh' }}
      >
        <div className="chattitle d-flex justify-content-between">
          <h5 className="mb-0">{"Welcome to Whizz's World: Let's Chat!!"}</h5>

          {userMsgs?.length > 0 && (
            <img
              src={clearChat}
              width="20"
              style={{ cursor: 'pointer' }}
              onClick={handleClearHistory}
              alt="Clear Chat"
            />
          )}
          {!isEmbed && <ContentCopy onClick={copyCode} style={{ cursor: 'pointer' }} />}
        </div>
        {categoryId && categoryTitle && (
          <p
            style={{
              marginBottom: '0',
              fontSize: '12px',
              fontWeight: '500',
              position: 'absolute',
              top: '46px',
              left: '16px',
              color: '#faebd7',
            }}
          >
            {categoryTitle}
          </p>
        )}
        <div
          className={`chating-area ${ 
            userMsgs?.length > 0 && !isEmbed ? 'cstm-chat-height cstm-chat-height-web' : ''
          }`}
          ref={chatAreaRef}
          onScroll={chatScroll}
          style={{
            height:
              userMsgs?.length > 0 && isEmbed
                ? 'calc(100vh - 200px)'
                : isEmbed
                ? 'calc(100vh - 156px)'
                : '',
          }}
        >
          {userMsgs?.length !== 0 ? (
            <>
              <Messages
                show={loading}
                messages={userMsgs}
                // setMessages={setMessages}
                toggleLoading={toggleLoading}
                category={Category?.Category ? Category?.Category : selectedValue}
                categoryId={categoryId}
                addMessage={addMessage}
                streamingStatus={streamingStatus}
                setStreamingStatus={setStreamingStatus}
                runningStream={runningStream}
                setRunningStream={setRunningStream}
                isUserScroll={isUserScroll}
                setChatloading={setChatloading}
                setIsUserScroll={setIsUserScroll}
              />
            </>
          ) : (
            <img src={skeltonLoaderImage} alt="" className="skelton-img" />
          )}
        </div>
        <div className="p-3">
          <div className="chat-send" style={{position: "fixed",bottom: 20,width: "400px"}}>
            {userMsgs?.length > 0 && (userMsgs?.length !== 1 || !userMsgs[0]?.defaultMsg) && (
              <input
                type="button"
                onClick={stoporRegenrateMessage}
                className="btn btn-primary mb-3"
                style={{ borderRadius: '100px', visibility: loading && 'hidden' }}
                value={streamingStatus || loading ? 'Stop Generating ' : 'Regenerate Response '}
              />
            )}
            <Input
              setReader={setReader}
              reader={reader}
              setStreamingStatus={setStreamingStatus}
              setRunningStream={setRunningStream}
              maxwidth
              token={token}
              chatLoading={chatLoading}
              setChatloading={setChatloading}
              toggleLoading={toggleLoading}
              addMessage={addMessage}
              category={Category?.Category ? Category.Category : selectedValue}
              categoryId={categoryId}
              subText={subText}
              setIsUserScroll={setIsUserScroll}
            />
          </div>
        </div>
      </div>
    </>
  )
}
