import { AddCircle, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartmentList } from 'src/redux/department/actions'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ConfirmDelete from 'src/components/ConfirmDelete'
import { CBreadcrumb, CBreadcrumbItem, CFormInput } from '@coreui/react'
import { axiosDELETE, axiosGET } from 'src/utility/apis'
import { getOrganizationList } from 'src/redux/organization/actions'
import { toast } from 'react-toastify'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import DeleteIcon from '../../../assets/adminBoard/trash.svg'
import AddModal from '../AddModal'
import NoDataImage from '../../../assets/adminBoard/nodata.svg'
import { Paginate } from 'src/components'
import pulse from '../../../assets/adminBoard/pulse.svg'
import AddModalUser from '../UserManagment/AddModalUser'
import { getUserList } from 'src/redux/userList/action'
import UserModalUpdate from './UserModalUpdate'

export default function UserCreation() {
  const [visibleDepartmentModal, setVisibleDepartmentModal] = useState(false)
  const [visibleUserModal, setVisibleUserModal] = useState(false)
  const { data, isLoading } = useSelector((state) => state.department)
  const { id } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteobjId, setDeleteobjId] = useState(null)
  const [iseditObj, setIseditObj] = useState(null)
  const handleDeleteModalCancel = () => {
    setDeleteModal(false)
  }
  const [currentPage, setCurrentPage] = useState(1)
  const location = useLocation()
  const [orgData, setOrgData] = useState(location?.state?.data)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector((state) => state.auth.userData?.user_type)
  const orgId = useSelector((state) => state.auth.userData?.organization_id)
  const authState = useSelector((state) => state.auth);
  const { data:UserList, isLoading:Loading } = useSelector((state) => state.userList)
  const [searchTerm, setSearchTerm] = useState('')
  const debounceDelay = 300 // Set your desired debounce delay in milliseconds
  const debounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }

  const handleSearch = debounce((query) => {
    setTimeout(() => {
      const results = dispatch(getDepartmentList(navigate, 10, currentPage, query, id,authState?.userData)) // Replace with your actual search function
    }, 500)
  }, debounceDelay)

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value
    setSearchTerm(newSearchTerm)
    handleSearch(newSearchTerm)
  }

  const getOrganizationDetails = async () => {
    try {
      let res = await axiosGET(`organization/${orgId}`)
      setOrgData(res)
    } catch (err) {
      toast('Something went wrong !!', 'error')
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1
    setCurrentPage(selectedPage)
    dispatch(getDepartmentList(navigate, 10, selectedPage, searchTerm, id,authState?.userData))
  }

  // useEffect(() => {
  //   if (userRole === 2) {
  //     getOrganizationDetails()
  //   }
  // }, [])

  useEffect(() => {
    if (id) {
      dispatch(getUserList(orgId))
    }
  }, [id])

  const handleDeleteDepartment = async (delId) => {
    try {
     const res= await axiosDELETE(`testing/${delId}`)
     if(res.status >= 200 && res.status < 300) {
       toast.success('Deleted successfully!')
       dispatch(getUserList(orgId))
      }else{
        toast.error('Something went wrong !!')
      }
      setDeleteModal(false)
    } catch (err) {
      toast.error('Something went wrong !!')
    }
  }
  useEffect(() => {
    if (!visibleDepartmentModal) {
      dispatch(getUserList(orgId))
    }
  }, [visibleDepartmentModal])

  return (
    <>
      <div className="content-area ">
        {userRole === 2 && (
          <>
             <h4 className="d-flex align-items-end">Users Listing</h4>
        <div className="d-flex justify-content-end mb-4">
          <div className="search-cstmOrg" style={{ marginRight: '16px' }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              className="search-cstm-text"
              onChange={handleInputChange}
            />
            <Search className="search-icon-cstm" />
          </div>
        </div>
        <div className='d-flex flex-column gap-1'> 
          {!isLoading &&
          UserList?.data?.map((itm,indx) => (
              <div className="upload-items uploded" key={itm?.id} style={{ padding: 0 }}>
                <div className="uploaded-data d-flex">
                  <div
                    className="progress-sc me-3"
                    style={{ padding: '1.5rem'}}
                  >
                    <h6 className="mb-0">
                      <span>{itm?.name}</span>
                    </h6>
                  </div>
                  <div className="close-icon d-flex">
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '1.5rem',
                      }}
                    >
                      {/* <CIcon
                        style={{ marginRight: '16px', cursor: 'pointer' }}
                        icon={cilPencil}
                        onClick={() => {
                          setVisibleDepartmentModal(true)
                          setIseditObj(UserList)
                        }}
                      /> */}
                      <img
                        src={DeleteIcon}
                        style={{ marginRight: '12px', cursor: 'pointer' }}
                        alt=""
                        width={16}
                        height={16}
                        onClick={() => {
                          setDeleteobjId(itm?.id)
                          setDeleteModal(true)
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
           ))}
        </div>
          </>
        )}

        {isLoading && (
          <div className="upload-items uploded">
            <div className="uploaded-data d-flex">
              <div className="progress-sc me-3">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center mx-2">Loading </div>
                  <img src={pulse} alt="loader" width={36} />
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoading && data?.length === 0 && (
          <div className="nodata text-center">
            <img src={NoDataImage} alt="nodata" width={40} height={40} />
            <span style={{ marginLeft: '8px', fontWeight: '700' }}>No data available</span>
          </div>
        )}
        {data?.data?.total_count > 10 && (
          <div className="mt-4 d-flex justify-content-end">
            <Paginate
              handlePageClick={handlePageClick}
              currentPage={currentPage}
              totalCount={data?.data?.total_count}
              limit={10}
            />
          </div>
        )}
      </div>
      <UserModalUpdate
        visible={visibleDepartmentModal}
        setVisible={setVisibleDepartmentModal}
        iseditObj={iseditObj}
        orgID={id}
        closeModal={() => {
          setVisibleDepartmentModal(false)
          setIseditObj(null)
        }}
      />
       <AddModalUser
       department={data?.data}
        visible={visibleUserModal}
        setVisible={setVisibleUserModal}
        iseditObj={iseditObj}
        orgID={id}
        closeModal={() => {
          setVisibleUserModal(false)
          setIseditObj(null)
        }}
      />
      <ConfirmDelete
        isCustomBoard={true}
        visible={deleteModal}
        cancelDelete={handleDeleteModalCancel}
        confirmDelete={() => handleDeleteDepartment(deleteobjId)}
      />
    </>
  )
}
