import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  // cilSync,
  cilSpeedometer,
  // cilStorage,
  // cilLanguage,
  cilSpreadsheet,
  cilDescription,
  cibBuffer,
} from '@coreui/icons'
import { CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    callBack: undefined,
  },
  // {
  //   component: CNavItem,
  //   name: 'Word Conversion',
  //   to: '/word-conversion',
  //   icon: <CIcon icon={cilSync} customClassName="nav-icon" />,
  //   callBack: undefined,
  // },
  {
    component: CNavItem,
    name: 'Dataset Category',
    to: '/datasetCategory',
    icon: <CIcon icon={cibBuffer} customClassName="nav-icon" />,
    callBack: undefined,
  },
  // {
  //   component: CNavItem,
  //   name: 'Dataset',
  //   to: '/dataset',
  //   icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
  //   callBack: undefined,
  // },
  {
    component: CNavItem,
    name: 'Data Log',
    to: '/data-log',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    callBack: undefined,
  },
  // {
  //   component: CNavItem,
  //   name: 'Rephrasing Management',
  //   to: '/rephrasing-management',
  //   icon: <CIcon icon={cilLanguage} customClassName="nav-icon" />,
  //   callBack: undefined,
  // },
  // {
  //   component: CNavItem,
  //   name: 'AI Query Management',
  //   to: '/query-management',
  //   icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
  //   callBack: undefined,
  // },
  {
    component: CNavItem,
    name: 'Document Processing',
    to: '/doc-management',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    callBack: undefined,
  },
  {
    component: CNavItem,
    name: 'Logout',
    to: '/login',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    callBack: undefined,
  },
]

export default _nav
