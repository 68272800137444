import axios from 'axios'
import { getFormHeaders, getHeaders } from '../../utility/commonUtils'

export const siteMap = (
  category_id,
  setUploadPercentage,
  url,
  sourceType,
  urlFile,
  onUploadProgress = undefined,
  user_id
) => {
  const form_data = new FormData()
  form_data.append(sourceType === 'gdrive' && !url.includes('https://drive.google.com/') ? 'folder_id' : 'url', sourceType === 'audio' ? null : url)
  form_data.append('source_type', sourceType)
  form_data.append('category_id', category_id)
  sourceType === 'audio' && form_data.append('file', urlFile)
  form_data.append('user_id',user_id)
  // form_data.append('file', sourceType === 'audio' ? audioFile : null)
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/sources`, form_data, {
      headers: getFormHeaders(),
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadPercentage(percentCompleted)
      },
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error.response
    })
}
