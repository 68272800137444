import axios from 'axios'
import { toast } from 'src/helpers/toast'
import { Configuration } from 'src/utility/configuration'

export const ActionTypes = {
  LOADER_START_AUTH: 'LOADER_START_AUTH',
  LOADER_STOP_AUTH: 'LOADER_STOP_AUTH',
  LOGIN: 'LOGIN',
}

export const handleLogin = (data, navigate) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOADER_START_AUTH })

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/${Configuration.user.login}`, data,{  headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }})
      .then((response) => {
        console.log("response",response);
        if (response.data?.access_token) {
          const userData = response.data.data
          userData.token = response.data.access_token
          dispatch({
            type: ActionTypes.LOGIN,
            payload: userData,
          })
          navigate('/mydashboard')
        } else toast('Invalid Password', 'error')

        dispatch({ type: ActionTypes.LOADER_STOP_AUTH })
      })
      .catch((error) => {
        toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.LOADER_STOP_AUTH })
      })
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT', payload: {} })
  }
}
