/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import UserDashboard from './dashboard'
import { AddCircle, CloseRounded } from '@mui/icons-material'
import Input from '../../CustomGraphBot/input'
import Messages from '../../CustomGraphBot/messages'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { cilPlus } from '@coreui/icons'
import { Skeleton } from '@mui/material'
import { toast } from 'react-toastify'
import { CModal, CModalBody, CModalHeader } from '@coreui/react'
import chatBot from '../../../assets/images/avatars/whizz-white.svg'
import { AddDataBaseModal } from './AddDatabaseModal'
import pulseImg from '../../../assets/adminBoard/pulse.svg'
import { getHeaders } from 'src/utility/commonUtils'
import clearChat from '../../../assets/adminBoard/trash.svg'
import skeltonLoaderImage from '../../../assets/adminBoard/skelton-loader.png'

const DashboardLayout = () => {
  const { sidebarShow } = useSelector((state) => state.sidebar)
  const [messages, setMessages] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [confirmdeleteId, setconfirmdeleteId] = useState(null)
  const [dashboardNameValidation, setDashboardNameValidation] = useState(false)
  const [dashId, setDashbId] = useState(null)
  const [isTblListLoading, setIsTblListLoading] = useState(true)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const loadingDashboard = false
  const [isUserScroll, setIsUserScroll] = useState(false)
  const [isBotVisible, setIsBotVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [chatLoading, setChatloading] = useState(false)
  const [dashboardCreationLoading, setDashboardCreationLoading] = useState(false)
  const streamingStatus = false
  const [dashboardName, setDashboardName] = useState('')
  const [allDBArray, setAllDBArray] = useState([])
  const [dashboardId, setdashboardId] = useState(null)
  const location = useLocation()
  const [isCreateDashboradModal, setIsCreateDashboradModal] = useState(false)
  const [isCreateDatabaseModal, setIsCreateDatabaseModal] = useState(false)
  const [selectedDBid, setSelectedDBid] = useState('')
  const [exportChartArr, setExportChartArr] = useState([])
  const [isExportChartArrLoading, setIsExportChartArrLoading] = useState(false)
  const [selectedDbItem, setSelectedDbItem] = useState(undefined)
  const [chatQuestion, setChatQuestion] = useState(undefined)
  const [userSelectedList,setUserSelectedList]=useState(false)
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    const parts = location.pathname.split('/')
    setdashboardId(parts[parts.length - 1])
  }, [location])

  /**
   * Get html data from  chat input
   */
  function getHtmlDataFromChatInput(inputTxt = '') {
    let result = typeof inputTxt === 'string' ? inputTxt : ''
    result = result.replace(/\n/gim, '<br />')
    result = result.replace(/Loading/gim, '')

    return result
  }

  function addMessage(msg) {
    setMessages((prev) => {
      // Check if the message with the given ID already exists in the array
      const existingIndex = prev.findIndex((item) => item.id === msg.id)

      if (existingIndex !== -1) {
        // If the message exists, update it with the new message
        const updatedMessages = [...prev]
        updatedMessages[existingIndex].msg = getHtmlDataFromChatInput(
          updatedMessages[existingIndex].msg + msg?.msg,
        )
        return updatedMessages
      } else {
        // If the message doesn't exist, add it as a new message
        return [...prev, msg]
      }
    })
  }

  const handleCreateNew = async () => {
    setDashboardCreationLoading(true)
    try {
      await axios
        .post(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}dashboards/`, {
          title: dashboardName,
          database_id: selectedDBid,
        })
        .then((response) => {
          if (response?.status === 201) {
            setDashboardCreationLoading(false)
            setIsCreateDashboradModal(false)
            setDashboardName('')
          }
        })
    } catch (err) {
      if (err.response.status === 422) {
        toast.error('Title already exist.')
        setDashboardCreationLoading(false)
      }
    }
  }

  function toggleLoading(value) {
    setLoading(value)
  }

  const onDashboardNameChange = (value) => {
    let letterNumber = /^[0-9a-zA-Z]+$/
    if (value) {
      if (value.match(letterNumber)) {
        setDashboardName(value)
        setDashboardNameValidation(false)
      } else {
        setDashboardNameValidation(true)
      }
    } else {
      setDashboardNameValidation(false)
    }
  }

  /**
   * Get table list
   */
  const getTableList = async () => {
    try {
      setIsTblListLoading(true)
      const reqObj = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/table/?record_count=${10}&page=${1}&search=${''}&user_id=${authState?.userData?.id}`,{
        headers: getHeaders(),
      },)
      if (Array.isArray(reqObj?.data?.data)) {
        setAllDBArray(reqObj.data.data)
      }
      setIsTblListLoading(false)
    } catch (errorObj) {
      setIsTblListLoading(false)
    }
  }

  /**
   * Get export datas
   */
  const getExportDatas = async ({ offset = 0 }) => {
    try {
      setIsExportChartArrLoading(true)
      const reqObj = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/chart/`, { 
        headers: getHeaders(),
        params: { page: offset, record_count: 10,user_id:authState?.userData?.id },
      })
      if (Array.isArray(reqObj?.data?.data)) {
        const results = reqObj.data?.data?.map((itemObj) => {
          let itemObj1 = itemObj
          // itemObj1.chart_data = isValidJsonString(itemObj1.chart_data)
          //   ? JSON.parse(itemObj1.chart_data)
          //   : undefined
          
          return itemObj1
        })
        setExportChartArr(results)
      }
      setIsExportChartArrLoading(false)
    } catch (error) {
      setIsExportChartArrLoading(false)
    }
  }

  /**
   * Export chart data
   */
  const exportChartData = async ({ inputObj = undefined }) => {
    try {
      setIsExportChartArrLoading(true)
      const reqObj = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/chart/`,
        { chart_data: inputObj,user_id:authState?.userData?.id},
        {
          headers: getHeaders(),
        },
      )
      if (!Number.isNaN(parseInt(reqObj?.data?.data?.id))) {
        await getExportDatas({ offset: 1 })
      }

      setIsExportChartArrLoading(false)
    } catch (errorObj) {
      setIsExportChartArrLoading(false)
    }
  }

  /**
   * Check whether the input is correct json or not
   */
  const isValidJsonString = (jsonStr = '') => {
    try {
      JSON.parse(jsonStr)

      return true
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    getTableList()
    getExportDatas({ offset: 1 })
  }, [])

  return (
    <div>
      <div id="wrapper" style={{ width: '97vw',display:'flex', paddingLeft: sidebarShow && '520px' }}>
        <div
          id="sidebar-wrapper"
          className="d-md-block d-none"
          style={{ left: sidebarShow && '534px' }}
        >
          {!isTblListLoading ? (
            <>
              <div className="p-2 row mt-2">
                <div className="col-10 text-center">Select the data you want to query</div>
                <div className="d-flex justify-content-end cursor-pointer col-2">
                  <AddCircle
                    icon={cilPlus}
                    style={{ color: 'rgb(75, 198, 65)',fontSize:'2.5rem' }}
                    onClick={() => setIsCreateDatabaseModal(true)}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="sidebar-nav" style={{ marginTop: '64px' }}>
            {isTblListLoading ? (
              <>
                <div className="upload-items uploded mb-4 mx-2">
                  <div className="uploaded-data d-flex">
                    <div className="progress-sc me-3">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center mx-2">Loading </div>
                        <img src={pulseImg} alt="loader" width={36} />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {(Array.isArray(allDBArray) ? allDBArray : []).map((dbItem, dbIndex) => (
                  <>
                    <div
                      className={`breif-chart-tbl text-white my-2 mx-2 cursor-pointer p-2 text-center ${
                        selectedDbItem === dbItem ? 'background-00f008d3 fw-bold' : ''
                      }`}
                      key={'dbItem' + dbIndex}
                      onClick={(event) => {
                        event.preventDefault()
                        toggleLoading(false)
                        setChatloading(false)
                        if (selectedDbItem === dbItem) {
                          setIsBotVisible(false)
                          setMessages([])
                          setSelectedDbItem(undefined)
                          setChatQuestion(undefined)
                          setUserSelectedList(false)
                        } else {
                          setUserSelectedList(true)
                          setMessages([
                            {
                              msg: "Hello! It's great to have you here. How can I assist you today?",
                              me: false,
                              _id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
                              id: 'f15e6896-4117-d67c-2008-42b2aa82c96e',
                            },
                          ])
                          setSelectedDbItem(dbItem)
                          setIsBotVisible(true)
                        }
                      }}
                    >
                      {typeof dbItem?.table_name === 'string' ? dbItem?.table_name : ''}
                    </div>
                  </>
                ))}
              </>
            )}

            <div style={{ marginTop: '8px' }}>
              {loadingDashboard && (
                <div className="m-2">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              )}
            </div>
          </div>
        </div>

        <UserDashboard
          exportChartArr={exportChartArr}
          isExportChartArrLoading={isExportChartArrLoading}
          setIsExportChartArrLoading={(val1) => {
            setIsExportChartArrLoading(val1)
          }}
          getExportDatas={() => {
            getExportDatas({ offset: 1 })
          }}
        />
        {/* new ui */}
        <div className="ms-3">
        <div className="">
            <div className="main_cont bot-chatwindow">
              <div className="bot-headercstmboard">
                <div className="row">
                  <div className="bot-headercstmboard-title col-10">
                    <div>Welcome to Whizz&apos;s World: Let&apos;s Chat!!</div>
                    <div>
                      {typeof selectedDbItem?.table_name === 'string' && selectedDbItem?.table_name.trim() !== '' ? (
                        selectedDbItem?.table_name
                      ) : (
                        <>&nbsp;</>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end col-2">
                    <div
                      className={`me-2 ${
                        typeof selectedDbItem === 'string' && selectedDbItem.trim() !== ''
                          ? ''
                          : 'd-none'
                      }`}
                    >
                      <img
                        src={clearChat}
                        width="20"
                        className="cursor-pointer"
                        onClick={(event) => {
                          event.preventDefault()
                          toggleLoading(false)
                          setChatloading(false)
                          setIsBotVisible(false)
                          setSelectedDbItem(undefined)
                          setMessages([])
                        }}
                        alt="Clear chat"
                      />
                    </div>
                    <div>
                      {/* <CloseRounded
                        onClick={() => {
                          setIsBotVisible(false)
                          toggleLoading(false)
                          setChatloading(false)
                          setChatQuestion(undefined)
                        }}
                        style={{ fontSize: '26px', cursor: 'pointer' }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="inner_cont"
                onWheel={() => setIsUserScroll(true)}
                style={{
                  height: 'calc(100vh - 231px)',
                  overflowY: 'auto',
                  paddingRight: '8px',
                  background: '#121d12',
                }}
              >
                {/* <div style={{ display: messages.length !== 0 ? 'block' : 'none' }}> */}
                  {messages.length !== 0 ?(<Messages
                    show={loading}
                    messages={messages}
                    setMessages={setMessages}
                    // cstmBoard={true}
                    toggleLoading={toggleLoading}
                    addMessage={addMessage}
                    streamingStatus={streamingStatus}
                    setChatloading={setChatloading}
                    setExportChartArr={(chatObj) => {
                      exportChartData({ inputObj: chatObj })
                    }}
                    chatQuestion={chatQuestion}
                    selectedDbItem={selectedDbItem}
                  />): (
                    <img src={skeltonLoaderImage} alt="" className="skelton-img" />
                  )}
                {/* </div> */}
              </div>
            <Input
                  cstmBoard={true}
                  maxwidth
                  userSelectedList={userSelectedList}
                  chatLoading={chatLoading}
                  setChatloading={setChatloading}
                  toggleLoading={toggleLoading}
                  addMessage={addMessage}
                  setIsUserScroll={setIsUserScroll}
                  dashboardId={dashboardId}
                  selectedDbItem={selectedDbItem}
                  setChatQuestion={(questionTxt) => {
                    setChatQuestion(questionTxt)
                  }}
                />
            </div>
          </div>
        </div>
      </div>

      <>
        {/* {isBotVisible ? (
          <div className="slide-in cstm-slide-in">
            <div className="main_cont bot-chatwindow">
              <div className="bot-headercstmboard">
                <div className="row">
                  <div className="bot-headercstmboard-title col-10">
                    <div>Welcome to Whizz&apos;s World: Let&apos;s Chat!!</div>
                    <div>
                      {typeof selectedDbItem === 'string' && selectedDbItem.trim() !== '' ? (
                        selectedDbItem
                      ) : (
                        <>&nbsp;</>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end col-2">
                    <div
                      className={`me-2 ${
                        typeof selectedDbItem === 'string' && selectedDbItem.trim() !== ''
                          ? ''
                          : 'd-none'
                      }`}
                    >
                      <img
                        src={clearChat}
                        width="20"
                        className="cursor-pointer"
                        onClick={(event) => {
                          event.preventDefault()
                          toggleLoading(false)
                          setChatloading(false)
                          setIsBotVisible(false)
                          setSelectedDbItem(undefined)
                          setMessages([])
                        }}
                        alt="Clear chat"
                      />
                    </div>
                    <div>
                      <CloseRounded
                        onClick={() => {
                          setIsBotVisible(false)
                          toggleLoading(false)
                          setChatloading(false)
                          setChatQuestion(undefined)
                        }}
                        style={{ fontSize: '26px', cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="inner_cont"
                onWheel={() => setIsUserScroll(true)}
                style={{
                  height: '97%',
                  overflowY: 'auto',
                  paddingRight: '8px',
                  background: '#121d12',
                }}
              >
                <div style={{ display: messages.length !== 0 ? 'block' : 'none' }}>
                  <Messages
                    show={loading}
                    messages={messages}
                    setMessages={setMessages}
                    cstmBoard={true}
                    toggleLoading={toggleLoading}
                    addMessage={addMessage}
                    streamingStatus={streamingStatus}
                    setChatloading={setChatloading}
                    setExportChartArr={(chatObj) => {
                      console.log("cht",chatObj)
                      exportChartData({ inputObj: chatObj })
                    }}
                    chatQuestion={chatQuestion}
                    selectedDbItem={selectedDbItem}
                  />
                </div>
              </div>
              {messages.length > 0 && (
                <Input
                  cstmBoard={true}
                  maxwidth
                  chatLoading={chatLoading}
                  setChatloading={setChatloading}
                  toggleLoading={toggleLoading}
                  addMessage={addMessage}
                  setIsUserScroll={setIsUserScroll}
                  dashboardId={dashboardId}
                  selectedDbItem={selectedDbItem}
                  setChatQuestion={(questionTxt) => {
                    setChatQuestion(questionTxt)
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          <div
            className={`${isExportChartArrLoading || isTblListLoading ? 'd-none' : ''}`}
            onClick={() => setIsBotVisible(true)}
          >
            <img className="botShortcut-cstm" src={chatBot} alt="bot" />
          </div>
        )} */}
      </>
      <CModal
        alignment="center"
        visible={isCreateDashboradModal}
        onClose={() => setIsCreateDashboradModal(false)}
        disabled={dashboardName === ''}
      >
        <CModalHeader className="cstmModalRadius  customBoardModalbg">
          Create new dashboard
        </CModalHeader>
        <CModalBody className="cstmModalRadiusBottom" style={{ background: '#121d12' }}>
          <label className="mb-1"> Enter dashboard name</label>
          <input
            className="form-control mb-4 cstm-text-area cstm-textbox"
            type="text"
            placeholder="Enter dashboard name"
            onChange={(e) => onDashboardNameChange(e?.target.value)}
          />
          {dashboardNameValidation ? (
            <div style={{ color: 'red', margin: '-10px 0 10px 5px' }}>
              Please enter alphanumeric characters only
            </div>
          ) : (
            ''
          )}
          <div className="d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-secondary"
              style={{ marginRight: '8px' }}
              onClick={() => {
                setIsCreateDashboradModal(false)
                setDashboardName('')
                setDashboardNameValidation(false)
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={() => handleCreateNew()}
              disabled={dashboardName === '' || dashboardNameValidation}
            >
              Create
            </button>
          </div>
        </CModalBody>
      </CModal>
      <AddDataBaseModal
        visible={isCreateDatabaseModal}
        closeModal={() => setIsCreateDatabaseModal(false)}
        loadTableList={() => {
          getTableList()
        }}
      />
    </div>
  )
}

export default DashboardLayout
