// **  Initial State
const initialState = {
  categoryTitle: '',
  categoryId: 0,
}

export const frontendCategoryPage = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FRONTEND_CATEGORY_PAGE_CATEGORY_TITLE':
      return {
        ...state,
        categoryTitle: action.payload,
      }
    case 'SET_FRONTEND_CATEGORY_PAGE_CATEGORY_ID':
      return {
        ...state,
        categoryId: action.payload,
      }
    default:
      return state
  }
}
