/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import ai3 from '../../assets/adminBoard/aiLogo.svg'
import user from '../../assets/images/avatars/user.png'
import PropTypes from 'prop-types'
import './chatbot.css'
import { Pie } from 'react-chartjs-2'
import { Box } from '@mui/material'
import uuid from 'react-uuid'
import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import Chart from 'chart.js/auto';
import ReactMarkdown from 'react-markdown';
export default function Message({
  me,
  msg,
  id,
  addMessage,
  cstmBoard,
  isError = false,
  exportId = 0,
  isExportChartShow = false,
  setExportChartArr,
  chartType = 'table',
  isShowGenerateChart = false,
  toggleLoading,
  setChatloading,
  chatQuestion = undefined,
  selectedDbItem = undefined,
}) {

  const chartRef = useRef(null);
  const [chartData,setChartData]=useState({});

  const [chartRender,setChartRender]=useState(false);

  useEffect(() => {
    const ctx = chartRef?.current?.getContext('2d');

    const chartInstance = new Chart(ctx, chartData);

    return () => {
        chartInstance.destroy();
    };
  }, [chartData]);

  /**
   * Get chart datas
   */
  console.log("msg",msg)
  async function getChartDatas() {
    try {
      toggleLoading(true)
      setChatloading(true)
      const payload = { question: chatQuestion, uuid: selectedDbItem?.table_name }
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/visualization`,
        payload,
      )
      console.log({ response }) 

      // if (typeof response?.data?.results?.[0] !== 'undefined') {
      //   const keys = Object.keys(response.data.results[0])
      //   const chartType =
      //     typeof response?.data?.chart_type === 'string' ? response.data.chart_type : 'table'

      //   let output = { tableDatas: { header: [], body: [] } }
      //   output.tableDatas.header = keys
      //   const tblBody = Array.isArray(response.data.results) ? response.data.results : []
      //   output.tableDatas.body = tblBody.map((itemObj) => {
      //     const itemObj1 = { ...itemObj }
      //     for (const keyTxt of keys) {
      //       if (!Number.isNaN(parseInt(itemObj1?.[keyTxt]))) {
      //         itemObj1[keyTxt] = parseInt(itemObj1?.[keyTxt])
      //       }
      //     }

      //     return itemObj1
      //   })
      //   let updateIds = uuid()
      //   // addMessage({
      //   //   msg: output,
      //   //   me: false,
      //   //   _id: updateIds,
      //   //   id: updateIds,
      //   //   isError: false,
      //   //   exportId: chartType !== 'pie' ? dataObj?.exportId : undefined,
      //   //   isExportChartShow: chartType !== 'pie',
      //   // })

      //   // if (chartType === 'pie') {
      //   //   setPieChartData({ tblBody, exportId: dataObj?.exportId })
      //   // }

      //   toggleLoading(false)
      //   setChatloading(false)
      // }
      const validChartTypes = ['bar', 'line', 'pie', 'doughnut', 'radar', 'polarArea', 'bubble', 'scatter'];
      if(typeof  response?.data?.chart_data!==undefined&&validChartTypes.includes(response.data.chart_data?.type)){
       console.log(response?.data?.chart_data);
       setChartData(response?.data?.chart_data);
       setChartRender(true);
    
       toggleLoading(false)
       setChatloading(false)
      } 
      else {
        const updateIds = uuid()
        addMessage({
          msg: "I'm sorry, but I couldn't find any data that matches your request at the moment. " +
          ' If you have a different question or need assistance with something else, ' +
          " please feel free to ask, and I'll do my best to assist you.",
          me: false,
          _id: updateIds,
          id: updateIds,
          isError: false,
        })
        toggleLoading(false)
        setChatloading(false)
      }
    } catch (errorObj) {
      console.log({ errorObj })
      setChatloading(false)
      toggleLoading(false)
      var updateIds = uuid()
      addMessage({
        msg:
          "I'm sorry, but I couldn't find any data that matches your request at the moment. " +
          ' If you have a different question or need assistance with something else, ' +
          " please feel free to ask, and I'll do my best to assist you.",
        me: false,
        _id: updateIds,
        id: updateIds,
        isError: true,
      })
    }
  }

  /**
   * Set pie chart data
   */
  const setPieChartData = ({ tblBody = [], exportId = 0 }) => {
    const updateIds = uuid()
    let output = {
      labels: [],
      datasets: [{ label: '', data: [], backgroundColor: [] }],
    }
    for (const objbody of tblBody) {
      for (const objbodyKey in objbody) {
        if (
          typeof objbodyKey === 'string' &&
          objbodyKey.trim() !== '' &&
          typeof objbody?.[objbodyKey] === 'string' &&
          typeof output.datasets?.[0].label === 'string' &&
          output.datasets[0].label.trim() === ''
        ) {
          output.datasets[0].label = objbodyKey
        }
        if (
          typeof objbodyKey === 'string' &&
          objbodyKey.trim() !== '' &&
          typeof objbody?.[objbodyKey] === 'string'
        ) {
          output.labels = [...output.labels, objbody[objbodyKey]]
        } else if (
          typeof objbodyKey === 'string' &&
          objbodyKey.trim() !== '' &&
          !Number.isNaN(parseInt(objbody?.[objbodyKey]))
        ) {
          output.datasets[0].data = [...output.datasets[0].data, objbody[objbodyKey]]
          output.datasets[0].backgroundColor = [
            ...output.datasets[0].backgroundColor,
            getPieBgColor(),
          ]
        }
      }
    }
    addMessage({
      msg: output,
      me: false,
      _id: updateIds,
      id: updateIds,
      isError: false,
      exportId: exportId,
      isExportChartShow: true,
      chartType: 'pie',
    })
  }

  /**
   * Get pie background colour
   */
  const getPieBgColor = (cIndex = 0) => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16)

    return '#' + n.slice(0, 6)
  }

  return (
    <>
      <div
        className="chat-panel"
        style={{
          backgroundColor: !me ? 'transparent' : cstmBoard ? '#133a0ea6' : '#133a0ea6',
          color: !me ? '#fff' : cstmBoard ? '#fff ' : '#fff',
          flexDirection: me ? 'row-reverse' : 'initial',
          borderRadius: me ? '20px 0px 20px 20px' : '0px 20px 20px 20px',
        }}
      >
        <div className="chatIconDiv" style={{ marginLeft: me && '0px', marginRight: me && '32px' }}>
          <div className="chatIcon">
            {me && <img src={user} width="30" alt="" />}
            {!me && <img src={ai3} width="30" alt="" />}
          </div>
        </div>
        {typeof msg === 'string' && msg.trim() !== '' && (
          <div
            id={'msg_text_pre_' + id}
            className={`cstm-msg ${isError ? 'text-danger' : ''}`}
            style={{
              marginLeft: me ? '35px' : '28px',
              textAlign: me ? 'end' : 'inherit',
              overflow: 'hidden',
              fontFamily: 'Instrument Sans,sans-serif',
              wordBreak: 'break-word',
            }}
          >
            {/* <div dangerouslySetInnerHTML={{ __html: msg }}></div> */}
            <ReactMarkdown>{msg}</ReactMarkdown>
            {isShowGenerateChart && !['', 'Loading'].includes(msg.trim()) ? (
              <>
                <div className="mt-2">
                  <button
                    className="border-0 text-white btn-primary background-00f008d3 fw-bold p-2"
                    type="button"
                    value="export"
                    onClick={(event) => {
                      event.preventDefault()
                      getChartDatas()
                    }}
                  >
                    Generate chart
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}

        {Array.isArray(msg?.tableDatas?.header) && Array.isArray(msg?.tableDatas?.body) ? (
          <>
            <div>
              <div className="table-responsive text-white ms-28px">
                <table className="table">
                  {msg.tableDatas.header.length > 0 ? (
                    <>
                      <thead>
                        <tr>
                          {msg.tableDatas.header.map((hText, hIndex) => {
                            return (
                              <th scope="col" key={'hIndex' + hIndex} className="text-color-white">
                                {typeof hText === 'string' ? hText : ''}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                    </>
                  ) : (
                    <></>
                  )}
                  {msg.tableDatas.body.length > 0 ? (
                    <>
                      <tbody>
                        {msg.tableDatas.body.map((bItemObj, bIndex) => {
                          return (
                            <>
                              <tr key={'body' + bIndex}>
                                {msg.tableDatas.header.map((hText, hIndex) => {
                                  return (
                                    <td
                                      key={'hIndex_' + hIndex + '_bIndex_' + bIndex}
                                      className="text-color-white"
                                    >
                                      {bItemObj?.[hText]}
                                    </td>
                                  )
                                })}
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </>
                  ) : (
                    <></>
                  )}
                </table>
              </div>
              {isExportChartShow ? (
                <>
                  <div className="ms-28px">
                    <button
                      className="border-0 btn-070707"
                      type="button"
                      value="export"
                      onClick={(event) => {
                        event.preventDefault()
                        setExportChartArr(exportId)
                      }}
                    >
                      Export to chart
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        {typeof chartType === 'string' && chartType.trim() === 'pie' && msg?.datasets && (
          <div>
            <Box
              className="chart-container ms-28px"
              style={{
                padding: '8px 0px',
                marginBottom: '0px',
                borderRadius: '0 20px 20px 20px',
              }}
            >
              <Pie
                data={msg}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      labels: {
                        color: 'rgb(255, 255, 255)',
                      },
                    },
                  },
                }}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            {isExportChartShow ? (
              <>
                <div className="ms-28px mt-3">
                  <button
                    className="border-0 btn-070707"
                    type="button"
                    value="export"
                    onClick={(event) => {
                      event.preventDefault()
                      setExportChartArr(exportId)
                    }}
                  >
                    Export to chart
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    {chartRender?
    (
    <>
<div style={{background: "aliceblue",borderRadius: "1rem",padding: "1rem",height: '360px',width:'360px',marginLeft: "1.3rem"}}>
  <canvas ref={chartRef}></canvas>
</div>
    <div className="ms-28px">
      <button
        className="border-0 btn-070707"
        type="button"
        value="export"
        onClick={(event) => {
          event.preventDefault()
          setExportChartArr(chartData)
        }}
      >
        Export to dashboard
      </button>
    </div>               
    </>
    ):<></>}
    </>
  )
}

Message.propTypes = {
  me: PropTypes.bool,
  msg: PropTypes.string,
  id: PropTypes.string,
}
