/* eslint-disable no-loop-func */
import React, { useRef, useState } from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CForm,
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'
import PropTypes from 'prop-types'
import addDocImg from '../../../assets/images/avatars/add-document-icon.svg'
import LoaderImg from '../../../assets/images/avatars/loader.gif'
import axios from 'axios'
import { getServerErrorMsg } from '../../../utility/commonUtils'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'

export const AddDataBaseModal = ({ visible, closeModal, loadTableList }) => {
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState({
    tblName: '',
    tblFiles: [],
  })
  const [formErrObj, setFormErrObj] = useState({
    tblName: '',
    tblFiles: '',
  })
  const uploadFileRef = useRef(null)
  const [isFormSubmit, setIsFormSubmit] = useState(false)
  const authState = useSelector((state) => state.auth);

  /**
   * Handle form submit
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setLoading(true)

    if (!isFormSubmit) {
      setIsFormSubmit(true)
    }

    const isValid = validateForm()
    if (!isValid) {
      setLoading(false)
      return false
    }
    const form_data = new FormData()
    form_data.append('file', input?.tblFiles?.[0])
    form_data.append("table_name",input?.tblName)
    form_data.append("user_id",authState?.userData?.id)
    await addTableData({ formDataObj: form_data, tablename: input?.tblName })
    setLoading(false)
  }

  /**
   *
   */
  const addTableData = async ({ formDataObj = undefined, tablename = '' }) => {
    let errorObj = { tblName: '', tblFiles: '' }
    try {
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/table`, formDataObj)
    if (response.status >= 200 && response.status < 300) {
       closeModal()
       setInput({
        tblName: '',
        tblFiles: [],
       })
       loadTableList()
       }
     }
      catch ({response}) {
      toast.error(response?.data?.detail)
      console.log("Error on Upload:", response?.data);
      }
    // if (typeof response?.data?.filename === 'string') {
    //   closeModal()
    //   setInput({
    //     tblName: '',
    //     tblFiles: [],
    //   })
    //   loadTableList()
    // } else if (typeof response?.data?.detail !== 'undefined') {
    //   errorObj.tblFiles = getServerErrorMsg(response?.data?.detail)
    // } else if (typeof response?.data?.error !== 'undefined') {
    //   errorObj.tblFiles = getServerErrorMsg(response.data.error)
    // }
    setFormErrObj(errorObj)
  }

  /**
   * Form validation
   */
  const validateForm = () => {
    let result = true
    let errorObj = { ...formErrObj }
    if (!(typeof input?.tblName === 'string' && input.tblName.trim() !== '')) {
      errorObj.tblName = 'Name is Required'
      result = false
    } else if (typeof input?.tblName === 'string' && input.tblName.trim() !== '') {
      errorObj.tblName = ''
    }

    if (
      !(typeof errorObj?.tblFiles === 'string' && errorObj.tblFiles.trim() !== '') &&
      !(Array.isArray(input?.tblFiles) && input.tblFiles.length > 0)
    ) {
      errorObj.tblFiles = 'Upload file is Required'
      result = false
    }

    if (result && typeof errorObj?.tblFiles === 'string' && errorObj.tblFiles.trim() !== '') {
      result = false
    }
    setFormErrObj(errorObj)

    return result
  }

  /**
   * Form on change event handle
   */
  const onChange = (event) => {
    const newValue = event?.target?.value
    const inputName = event?.target?.name
    setInput((prevState) => {
      return {
        ...prevState,
        [inputName]: newValue,
      }
    })
    validateForm()
  }

  /**
   * Handle drag over
   */
  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 2px gray' // Highlight the drop area when dragging over
  }

  /**
   * Handle drag leave
   */
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 1px white' // Restore the border when leaving
  }

  /**
   * Handle drag
   */
  const handleDrop = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 1px white' // Restore the border

    const droppedFiles = e.dataTransfer.files
    processDroppedFiles(droppedFiles)
  }

  const processDroppedFiles = (files) => {
    // Handle dropped files similar to the file input's onChange event
    let errMsg = ''
    let totalFileSize = 0
    const acceptTypes = ['csv']
    let newFiles = Array.isArray(input?.tblFiles) ? input.tblFiles.slice() : []

    for (let i = 0; i < files.length; i++) {
      const fileObj = files[i]
      const filePortions = fileObj.name.split('.')
      const fileExtension = filePortions.pop().toLowerCase()

      if (!acceptTypes.includes(fileExtension)) {
        errMsg = 'Please upload csv file'
        break
      }

      if (newFiles.length + 1 > 2) {
        errMsg = 'Only 1 file accepted'
        newFiles = []
        break
      }

      if (!Number.isNaN(parseFloat(fileObj.size))) {
        totalFileSize += parseFloat(fileObj.size)
      }

      if (totalFileSize > 1000 * 1024 * 1024) {
        errMsg = 'Please select a file smaller than 100 MB'
        break
      }

      newFiles.push(fileObj)
    }

    if (errMsg.trim() === '') {
      setFormErrObj((prevState) => {
        return { ...prevState, tblFiles: '' }
      })
      setInput((state) => {
        return {
          ...state,
          tblFiles: newFiles,
        }
      })
    } else {
      setFormErrObj((prevState) => {
        return { ...prevState, tblFiles: errMsg }
      })
    }
  }

  return (
    <CModal visible={visible} onClose={closeModal} size="md" backdrop="static">
      <CModalHeader className="customBoardModalbg">
        <CModalTitle>Add table</CModalTitle>
      </CModalHeader>
      <CForm noValidate onSubmit={handleSubmit}>
        <CModalBody className={`${'customBoardModalbg '}`}>
          <CRow>
            <CCol md={12} className="ps-lg-2">
              <div>
                <CFormLabel>Name</CFormLabel>
                <CFormInput
                  required
                  className="mb-3"
                  placeholder="Enter Name"
                  name="tblName"
                  value={input?.tblName}
                  onChange={onChange}
                  readOnly={loading}
                />
                {isFormSubmit &&
                typeof formErrObj?.tblName === 'string' &&
                formErrObj.tblName.trim() !== '' ? (
                  <>
                    <div className="text-danger">{formErrObj.tblName}</div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </CCol>
          </CRow>
          <>
            <CRow className="mb-3">
              <CCol md={12} className="ps-lg-2">
                <div className="mt-5">
                  <div
                    className="d-grid justify-content-center mb-4 pt-4 pb-2"
                    style={{
                      cursor: 'pointer',
                      border: 'dashed 1px white',
                      borderRadius: '18px',
                    }}
                    onClick={() => uploadFileRef.current.click()}
                    onDragOver={(e) => handleDragOver(e)}
                    onDragLeave={(e) => handleDragLeave(e)}
                    onDrop={(e) => handleDrop(e)}
                    disabled={loading}
                  >
                    <div className="d-grid justify-content-center">
                      <img src={addDocImg} alt="docimage" />
                    </div>
                    <p className="mt-3 px-3">Drag and drop files here, or click to select files.</p>
                  </div>

                  <CFormInput
                    ref={uploadFileRef}
                    style={{ display: 'none' }}
                    required
                    className="mb-2"
                    placeholder="Upload file"
                    name="tblFiles"
                    type="file"
                    multiple={false}
                    accept=".csv"
                    onChange={(event) => {
                      event.preventDefault()
                      let prevFileObj
                      let errMsg = ''
                      let fileObj
                      let totalFileSize = 0
                      let filePortions = []
                      const acceptTypes = ['csv']
                      let files = Array.isArray(input?.tblFiles) ? input.tblFiles : []
                      let inputFiles =
                        typeof event?.target?.files !== 'undefined' ? event.target.files : []
                      for (let i = 0; i < inputFiles.length; i++) {
                        fileObj = inputFiles?.[i]

                        filePortions = (
                          typeof fileObj?.name === 'string' ? fileObj.name : ''
                        ).split('.')
                        filePortions = filePortions.pop()
                        filePortions = (
                          typeof filePortions === 'string' ? filePortions : ''
                        ).toLowerCase()
                        if (!acceptTypes.includes(filePortions)) {
                          errMsg = 'Please upload csv file'
                        }

                        for (prevFileObj of files) {
                          if (!Number.isNaN(parseFloat(prevFileObj?.size))) {
                            totalFileSize += parseFloat(prevFileObj?.size)
                          }
                        }

                        if (!Number.isNaN(parseFloat(fileObj?.size))) {
                          totalFileSize += parseFloat(fileObj?.size)
                        }

                        if (errMsg.trim() === '' && totalFileSize > 1000 * 1024 * 1024) {
                          errMsg = 'Please select a file smaller than 1000 MB'
                        }

                        if (typeof errMsg === 'string' && errMsg.trim() !== '') {
                          setFormErrObj((prevState) => {
                            return { ...prevState, tblFiles: errMsg }
                          })
                        } else {
                          setFormErrObj((prevState) => {
                            return { ...prevState, tblFiles: '' }
                          })
                          setInput((state) => {
                            return {
                              ...state,
                              tblFiles: [...state?.tblFiles, event?.target?.files?.[i]],
                            }
                          })
                        }
                      }
                    }}
                  />
                  {isFormSubmit &&
                  typeof formErrObj?.tblFiles === 'string' &&
                  formErrObj.tblFiles.trim() !== '' ? (
                    <>
                      <span style={{ color: 'red' }}>{formErrObj.tblFiles}</span>
                    </>
                  ) : (
                    <></>
                  )}
                  <p style={{ fontSize: '14px', color: 'gray' }}>
                    Please ensure that your file is in CSV format. Maximum of the total file should
                    be 1000 MB. A user can only upload one file.
                  </p>
                </div>
              </CCol>
            </CRow>
            {(Array.isArray(input?.tblFiles) ? input.tblFiles : []).length > 0 ? (
              <>
                {(Array.isArray(input?.tblFiles) ? input.tblFiles : []).map(
                  (itemObj, itemIndex) => {
                    return (
                      <>
                        <CRow className="my-2" key={'documentUploadRow' + itemIndex}>
                          <CCol md={10} className="ps-lg-2 text-truncate">
                            {typeof itemObj?.name === 'string'
                              ? `${itemObj.name} (${parseInt(itemObj.size / 1024)} KB)`
                              : ''}
                          </CCol>
                          <CCol
                            md={2}
                            className="d-flex justify-content-md-end justify-content-sm-start"
                          >
                            <span
                              className={`${loading ? 'd-none' : ''}`}
                              onClick={(event) => {
                                event.preventDefault()
                                let prevFiles = [...input.tblFiles]
                                prevFiles.splice(itemIndex, 1)
                                setInput((prevState) => {
                                  return { ...prevFiles, tblFiles: prevFiles }
                                })
                              }}
                            >
                              <CIcon
                                icon={cilTrash}
                                color="danger"
                                size="lg"
                                title="Delete"
                                style={{ cursor: 'pointer' }}
                              />
                            </span>
                            <span
                              className={`${
                                !loading ? 'd-none' : ''
                              } d-flex justify-content-md-end justify-content-sm-start`}
                            >
                              <img src={LoaderImg} alt="Loader" className="w-50" />
                            </span>
                          </CCol>
                        </CRow>
                      </>
                    )
                  },
                )}
              </>
            ) : (
              <></>
            )}
          </>
        </CModalBody>
        <CModalFooter className={`${'customBoardModalbg '}`}>
          <CButton className="add-btn" type="submit" disabled={loading}>
            {'Add'}
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  )
}

AddDataBaseModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  loadWordList: PropTypes.func,
  isEdit: PropTypes.bool,
  editData: PropTypes.array,
}
