import { CButton, CModal, CModalBody, CModalFooter, CSpinner } from '@coreui/react'

const ConfirmDelete = ({
  visible,
  cancelDelete,
  confirmDelete,
  deleteButtonDisable,
  isCustomBoard,
  isDeleteButtonDisable,
}) => {
  return (
    <>
      <CModal transition={false} alignment="center" visible={visible} onClose={cancelDelete}>
        {/* <CModalHeader>
        <CModalTitle>Modal title</CModalTitle>
      </CModalHeader> */}
        <CModalBody
          className={`${isCustomBoard ? 'customBoardModalbg cstmModalRadius' : 'normalModalbg'}`}
        >
          Are you sure you want to delete this item?
        </CModalBody>
        <CModalFooter
          className={`${
            isCustomBoard ? 'customBoardModalbg cstmModalRadiusBottom' : 'normalModalbg'
          }`}
        >
          <CButton
            className={isCustomBoard && 'btn btn-secondary btn-sm me-2 text-white px-4'}
            color="secondary"
            onClick={cancelDelete}
            disabled={isCustomBoard ? isDeleteButtonDisable : cancelDelete}
          >
            Cancel
          </CButton>
          <CButton
            className={isCustomBoard && 'btn btn-primary btn-sm me-2 text-white px-4'}
            onClick={confirmDelete}
            disabled={isCustomBoard ? isDeleteButtonDisable : deleteButtonDisable}
          >
            {isDeleteButtonDisable && <CSpinner size="sm" style={{ marginRight: '8px' }} />}
            Confirm
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ConfirmDelete
