const initialState = {
  history: [],
  selectedCategory: null,
  categoryTitleName: null,
}

export const chatHistory = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_HISTORY':
      return {
        ...state,
        history: action.payload,
      }
    case 'SAVE_ID':
      return {
        ...state,
        selectedCategory: action.payload,
      }
    case 'SAVE_TITLE':
      return {
        ...state,
        categoryTitleName: action.payload,
      }
    case 'CLEAR_HISTORY':
      return {
        ...state,
        history: [],
        selectedCategory: null,
        categoryTitleName: null,
      }
    default:
      return state
  }
}
