import { combineReducers } from 'redux'
import SidebarReducer from './feature/sidebarSlice'
import { auth } from './auth/reducer'
import { wordConversion } from './wordConversion/reducer'
import { wordParaphrase } from './rephrasingManagement/reducer'
import { dataSet } from './dataSet/reducer'
import { dataLog } from './dataLog/reducer'
import { dashBoard } from './dashboard/reducer'
import { AIQueryList } from './queryManagment/reducer'
import { documentList } from './documentManagment/reducer'
import { dataSetCategory } from './dataSetCategory/reducer'
import { frontendCategoryPage } from './frontendCategoryPage/reducer'
import { documentEditList } from './documentEdit/reducer'
import { organization } from './organization/reducer'
import { department } from './department/reducer'
import { chatHistory } from './historyManagment/reducer'
import { category } from './category/reducer'
import { userChatMessages } from './message/reducer'
import {audioFile} from './audioToSpeechConversion/reducer'
import { userList } from './userList/reducer'

const appReducer = combineReducers({
  sidebar: SidebarReducer,
  audioFile,
  auth,
  AIQueryList,
  documentList,
  documentEditList,
  wordConversion,
  wordParaphrase,
  dataSet,
  dataLog,
  organization,
  department,
  category,
  dashBoard,
  dataSetCategory,
  frontendCategoryPage,
  chatHistory,
  userChatMessages,
  userList
})

export const middlewares = []

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') return appReducer(undefined, action)
  else return appReducer(state, action)
}

export default rootReducer
