import { useEffect, useRef, useState } from 'react'
import ChatIcon from '../../assets/adminBoard/aiLogo.svg'
import '../OpenChat/openchat.css'
import Chip from '@mui/material/Chip';
import {ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import greenuserImage from '../../assets/adminBoard/greenUsers.svg';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import uuid from 'react-uuid'
const Policy = () => {
  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const textareaRef = useRef(null)
  const chatContainerRef = useRef(null);
  const [selectedChipIndex, setSelectedChipIndex] = useState(-1);
  const navigate = useNavigate()
  const { isLoading:docloading, data:documentList } = useSelector((state) => state.documentList)
  const { userData } = useSelector((state) => state.auth);
  const [categoryId,setCategoryId]=useState(null);
  const [sessionId,setSessionId]=useState(null);
  const [reader, setReader] = useState(null); 
  const [isAtBottom, setIsAtBottom] = useState(true);

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = '55px' // Reset height to auto
    textarea.style.height = `${textarea.scrollHeight}px` // Set to scrollHeight
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
    adjustTextareaHeight(e.target)
  }

  const handleSendMessage = async() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '55px' //reset textarea height
    }
    if (inputValue.trim()) {
      const newMessage = {
        question: inputValue,
        response: '',
      }
      setMessages((prevMessages) => [...prevMessages, newMessage])
      setInputValue('')
      setIsGenerating(true)
      setIsLoading(true)

        try {

          const bodyData = {
            question: inputValue,
            session_id: sessionId,
            user_id: userData?.id,
            category_id:'6734336b6f31d9e4be305be8'
          };

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/policy-assistant/`,{ 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData), 
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Optionally handle response in chunks using ReadableStream
      const newReader = response.body.getReader(); // Get the new reader
      setReader(newReader); 
      const decoder = new TextDecoder();
      let done = false;
      let accumulatedResponse = '';
  
      while (!done) {
        const { value, done: readerDone } = await newReader.read();
        done = readerDone;
        setIsLoading(false)
        if (value) {
          const chunk = decoder.decode(value, { stream: !done });
          accumulatedResponse += chunk;
          setMessages((prevMessages) => {
            if (prevMessages.length === 0) return prevMessages; 
            const updatedMessages = [...prevMessages]
            updatedMessages[updatedMessages.length - 1].response = accumulatedResponse;
            return updatedMessages
          })     
        }

        if(done){
          setIsLoading(false)
          setIsGenerating(false)
        }
      }
  
    } catch (error) {
      toast.error('Something went wrong, Please try again later.')
      setIsLoading(false)
      setIsGenerating(false)
     }
    }
  }

  const handleStopGeneration = () => {
     if(reader)
     reader.cancel();
    setIsLoading(false)
    setIsGenerating(false)
  }

  const handleKeyDown = (e) => {
    // checking enter key
    if (e.key === 'Enter' && !e.shiftKey && window.innerWidth > 800) {
      e.preventDefault()
      if (isLoading && isGenerating) {
        handleStopGeneration()
      } else {
        setIsAtBottom(true);
        handleSendMessage()
      }
    }
  }

  useEffect(() => {
    if (chatContainerRef.current && isAtBottom) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, isAtBottom]);

  useEffect(() => {
    // Generate a new session ID at the start of a new chat session if it’s not set
    if (!sessionId) {
      setSessionId(uuid());
    }
  }, [sessionId]);
  
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    // Check if user is at the bottom
    const isCurrentlyAtBottom = scrollTop + clientHeight >= scrollHeight - 5;
    setIsAtBottom(isCurrentlyAtBottom);
  };

  // useEffect(()=>{
  //   if(documentList.data)
  // console.log("documentList",documentList.data.data.map((item)=>item?.category_name))
  // },[])

//   const handleChipClick = (index,Id) => {
//     // Set the clicked chip as selected, toggle it off if it's already selected
//     setInputValue('')
//     setSessionId(uuid())
//     setCategoryId(Id)
//     setSelectedChipIndex((prevIndex) => (prevIndex === index ? -1 : index));
//   };
  return (
    <div className="content-area d-flex justify-content-between">
      {!messages.length && (
        <div className="animation-container">
            {/* <div className="chip">
              {documentList?.data?.data?.map((item,index) => (
                <Chip
                  key={index}
                  label={item?.category_name}
                  variant={selectedChipIndex === index ? 'filled' : "outlined"}
                  color={selectedChipIndex === index ? 'success' : 'success'}
                  sx={{ cursor: 'pointer', m: 0.5,height:'40px',padding: '0 12px', fontSize: '1rem' }}
                  onClick={() => handleChipClick(index,item?.id)}
                />
              ))}
            </div> */}
          <div className="typewriter">
            <h1>How can i help you?</h1>
          </div>
          <div className="typing-content">
            <div className="typing-textarea">
              <textarea
                ref={textareaRef}
                value={inputValue}
                onChange={handleInputChange}
                // disabled={selectedChipIndex=='-1'}
                id="chatinput"
                onKeyDown={handleKeyDown}
                placeholder={"Enter your prompt here"}
                required
              />
              <span
                id="send-btn"
                style={{
                  cursor: 'pointer',
                  fontSize: '2.5rem',
                  color: inputValue.trim() ? 'rgb(75, 198, 65)' : 'gray',
                }}
                className="material-symbols-rounded"
                onClick={handleSendMessage}
              >
                arrow_circle_up
              </span>
            </div>
          </div>
        </div>
      )}
      {!!messages.length && (
        <div className="chat-container" ref={chatContainerRef} onScroll={handleScroll}>
          <div>
          <div
            className="d-flex mb-3 position-fixed"
            onClick={() => {
              setMessages([]);
              setSessionId(null);
              setSelectedChipIndex(-1)
            }}
            style={{ cursor: 'pointer' }}
          >
            <ArrowBack style={{ marginRight: '8px', fontSize: '1.2rem' }} />
            <h4 style={{fontSize:'17px'}}>Back</h4>
          </div>
          </div>
          {messages.map((message, index) => (
            <div key={index}>
              <div className="chat outgoing">
                <div className="chat-content">
                  <div className="chat-details">
                    <img src={greenuserImage} width={30} alt="chat" />
                    <p>{message.question}</p>
                  </div>
                </div>
              </div>

              {isLoading && index === messages.length - 1 && ( // Only show loading for the latest question
                  <div className="chat incoming">
                    <div className="chat-content">
                      <div className="chat-details">
                        <img src={ChatIcon} width={30} alt="chat" />
                        <div className="typing-animation">
                          <div className="typing-dot" style={{ '--delay': '0.2s' }}></div>
                          <div className="typing-dot" style={{ '--delay': '0.3s' }}></div>
                          <div className="typing-dot" style={{ '--delay': '0.4s' }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {message.response && (
                <div className="chat incoming">
                  <div className="chat-content">
                    <div className="chat-details">
                      <img src={ChatIcon} width={30} alt="chat" />
                       <div>
                      <ReactMarkdown>{message.response}</ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Typing container */}
      {!!messages.length && (
        <div className="typing-container">
          <div className="typing-content">
            <div className="typing-textarea">
              <textarea
                ref={textareaRef}
                value={inputValue}
                onChange={handleInputChange}
                id="chatinput"
                onKeyDown={handleKeyDown}
                placeholder="Enter a prompt here"
                required
              />
              {isGenerating ? (
                <span
                  style={{ cursor: 'pointer', fontSize: '2.5rem', color: 'white' }}
                  onClick={handleStopGeneration}
                  className="material-symbols-rounded"
                >
                  radio_button_checked
                </span>
              ) : (
                <span
                  id="send-btn"
                  style={{
                    cursor: 'pointer',
                    fontSize: '2.5rem',
                    color: inputValue.trim() ? 'rgb(75, 198, 65)' : 'gray',
                  }}
                  className="material-symbols-rounded"
                  onClick={handleSendMessage}
                >
                  arrow_circle_up
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Policy
