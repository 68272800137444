// reducer.js
const initialState = {
  msgs: []
};

export const userChatMessages= (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      
    const existingIndex = state?.msgs?.findIndex((item) => item.id === action.payload[0]?.id)
    
      if (existingIndex !== -1) {
        // If the message exists, update it with the new message
        const updatedMessages = [...state?.msgs]
        updatedMessages[existingIndex].msg = updatedMessages[existingIndex].msg + action.payload[0]?.msg
        return {
          ...state,
          msgs: updatedMessages
        }
      } else {
        // if(state?.msgs?.length > 0 && state?.msgs[state?.msgs?.length-1]?.msg.startsWith("I'm Whizz")){

        //   return {
        //     ...state,
        //     msgs: [...state?.msgs]
        //   };
        // }
        
        // If the message doesn't exist, add it as a new message
        return {
          ...state,
          msgs: [...state?.msgs, ...action.payload]
        };

      }
    case 'UPDATE_MESSAGE':
      const { index, updatedMessage } = action.payload;
      const updatedMessages = [...state.msgs];
      updatedMessages[index] = updatedMessage;
      return {
        ...state,
        msgs: updatedMessages
      };
    case 'DELETE_MESSAGE':
      return {
        ...state,
        msgs: action.payload
      }
    case 'CLEAR_MESSAGES':
      return {
        ...state,
        msgs: []
      };
    default:
      return state;
  }
};
