// **  Initial State
const initialState = {
  isDatasetCategoryLoading: false,
  datasetCategories: [],
}

export const dataSetCategory = (state = initialState, action) => {
  switch (action.type) {
    case 'DATASET_CATEGORY_LOADER_START':
      return {
        ...state,
        isDatasetCategoryLoading: true,
      }
    case 'DATASET_CATEGORY_LOADER_STOP':
      return {
        ...state,
        isDatasetCategoryLoading: false,
      }
    case 'SET_DATASET_CATEGORIES':
      return {
        ...state,
        datasetCategories: action.payload,
      }
    default:
      return state
  }
}
