export function generateVIBGYORColorCodes(n) {
  const colors = [
    '#F23A6C',
    '#7BCD3A',
    '#4859E2',
    '#C8A15F',
    '#92E38D',
    '#F76D25',
    '#5E72B0',
    '#D9409E',
    '#1FC6A7',
    '#E23F89',
    '#6A589C',
    '#B5D276',
    '#F52E47',
    '#73AEC0',
    '#FF8C38',
    '#4FA23B',
    '#C15B95',
    '#37D8E1',
    '#E67058',
    '#9B42A9',
    '#58E294',
    '#D26330',
    '#3276C8',
    '#A8C745',
    '#E14FA2',
    '#67B9D9',
    '#F3921E',
    '#5DAB67',
    '#D34677',
    '#42C3B0',
    '#9C4F82',
    '#6FB856',
    '#E5613A',
    '#4D68AE',
    '#BDCC5E',
    '#1EC18F',
    '#F04E5B',
    '#79A4CB',
    '#C97E34',
    '#3EA65D',
    '#A24880',
    '#52D392',
    '#E75523',
    '#6392B7',
    '#CCD649',
    '#26C1A1',
    '#F76273',
    '#87BB57',
    '#D53A69',
    '#6A7CBF',
    '#B4D167',
    '#F33048',
    '#58A39A',
    '#E14E9F',
    '#4BCE7E',
    '#A44D68',
    '#3EAC4B',
    '#C5A642',
    '#29CFAE',
    '#F7525D',
    '#72A58C',
    '#DD4971',
    '#5F9D46',
    '#B44F6D',
    '#4F9E82',
    '#E35D32',
    '#63A4CB',
    '#AEBD56',
    '#F53B82',
    '#6E7C59',
    '#D94A5E',
    '#40B98F',
    '#9163A7',
    '#7CC456',
    '#E4473A',
    '#56B4AE',
    '#C37A2B',
    '#38DB7C',
    '#A3477D',
    '#65CC42',
    '#DC615E',
    '#4E8FBA',
    '#BFCF58',
    '#F15B4C',
    '#73A875',
    '#E6547B',
    '#5CBB98',
    '#D14934',
    '#328CBF',
    '#A1D74A',
    '#F3446E',
    '#6A8261',
    '#C94E3F',
    '#42B69F',
    '#915B8A',
    '#78CC5D',
    '#E24B2F',
    '#5D90B4',
    '#DBD257',
    '#316F9C',
  ]

  return Array.from({ length: n }, () => {
    const randomIndex = Math.floor(Math.random() * colors.length)
    return `${colors[randomIndex]}`
  })
}
