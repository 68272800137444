// **  Initial State
const initialState = {
  isLoading: false,
  data: null,
}

export const AIQueryList = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_STARTS':
      return {
        ...state,
        isLoading: true,
      }
    case 'LOADER_STOPS':
      return {
        ...state,
        isLoading: false,
      }
    case 'SET_QUERY_LIST':
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}
