// **  Initial State
const initialState = {
    audioPath:null,
  }
  
  export const audioFile = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SPEECH_TO_AUDIO_CONVERSION':
        return {
          ...state,
        audioPath:action.payload
        }
     
      default:
        return state
    }
  }
  