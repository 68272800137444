import axios from 'axios'
import { toast } from 'src/helpers/toast'
import { Configuration } from 'src/utility/configuration'
import { logout } from '../auth/actions'
import { getHeaders } from 'src/utility/commonUtils'

export const ActionTypes = {
  LOADER_START: 'DATASET_CATEGORY_LOADER_START',
  LOADER_STOP: 'DATASET_CATEGORY_LOADER_STOP',
  SET_DATA_SET: 'SET_DATASET_CATEGORIES',
}

export const getDataSetCategoryList = (navigate) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOADER_START })

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/${Configuration.datasetCategory.category}`, {
        headers: getHeaders(),
      })
      .then((response) => {
        dispatch({
          type: ActionTypes.SET_DATA_SET,
          payload: Array.isArray(response?.data?.data) ? response.data.data : [],
        })

        dispatch({ type: ActionTypes.LOADER_STOP })
      })
      .catch((error) => {
        if (error?.response?.request?.status === 401) {
          dispatch(logout())
        }
        dispatch({ type: ActionTypes.LOADER_STOP })
      })
  }
}
