/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ImpulseSpinner } from 'react-spinners-kit'
import ai3 from '../../assets/adminBoard/aiLogo.svg'
import PropTypes from 'prop-types'
import Message from './message'

export default function Messages({
  messages,
  show,
  cstmBoard,
  addMessage,
  setExportChartArr,
  generateChart,
  toggleLoading,
  setChatloading,
  chatQuestion = undefined,
  selectedDbItem = undefined,
}) {
  const scrollRef = useRef(null)
  const viewRef = useRef(null)

  const [chart_id] = useState('')
  useEffect(() => {
    if (Array.isArray(messages) && messages.length > 0) {
      updateScroll()
    }
  }, [messages])

  useEffect(() => {
    if (Array.isArray(messages) && messages.length > 0 && scrollRef?.current) {
      // wait for the elementRef to be available
      const resizeObserver = new ResizeObserver(() => {
        updateScroll()
      })
      resizeObserver.observe(scrollRef.current)
      return () => resizeObserver.disconnect() // clean up
    }
  }, [messages])

  function updateScroll() {
    if (scrollRef?.current) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Fragment>
      <div className="msg_cont" style={{ overflowY: 'auto' }}>
        <div className="message-container" style={{ marginTop: '0px' }}>
          <div ref={viewRef}></div>

          {messages.map((item) => {
            return (
              <div
                ref={scrollRef}
                key={item._id}
                style={{
                  marginRight: !item.me ? (item?.msg?.datasets ? '0px' : '140px') : '',
                  marginLeft: item.me ? '140px' : '',
                  marginBottom: '16px',
                }}
              >
                <Message
                  id={item._id}
                  cstmBoard={cstmBoard}
                  me={item.me}
                  msg={item.msg}
                  _id={item._id}
                  key={item._id}
                  chart_id={chart_id}
                  animate={item._id === messages[messages.length - 1]._id}
                  addMessage={addMessage}
                  isError={item?.isError}
                  exportId={item?.exportId}
                  isExportChartShow={item?.isExportChartShow}
                  isShowGenerateChart={item?.isShowGenerateChart}
                  // setExportChartArr={(exportId = 0) => {
                  //   const resultObj = { title: '', table: undefined }
                  //   let messageObj = (Array.isArray(messages) ? messages : []).find((itemObj) => {
                  //     return (
                  //       itemObj?.exportId === exportId &&
                  //       typeof itemObj?.msg === 'string' &&
                  //       itemObj.msg.trim() !== ''
                  //     )
                  //   })
                  //   resultObj.title = typeof messageObj?.msg === 'string' ? messageObj.msg : ''
                  //   messageObj = (Array.isArray(messages) ? messages : []).find((itemObj) => {
                  //     return (
                  //       itemObj?.exportId === exportId &&
                  //       typeof itemObj?.msg?.tableDatas !== 'undefined'
                  //     )
                  //   })
                  //   resultObj.table =
                  //     typeof messageObj?.msg !== 'undefined' ? messageObj.msg : undefined
                  //   messageObj = (Array.isArray(messages) ? messages : []).find((itemObj) => {
                  //     return (
                  //       itemObj?.exportId === exportId &&
                  //       typeof itemObj?.chartType === 'string' &&
                  //       itemObj.chartType.trim() === 'pie' &&
                  //       typeof itemObj?.msg?.datasets !== 'undefined'
                  //     )
                  //   })
                  //   resultObj.pieChart =
                  //     typeof messageObj?.msg !== 'undefined' ? messageObj.msg : undefined
                  //   setExportChartArr(resultObj)
                  // }}
                  setExportChartArr={setExportChartArr}
                  chartType={item?.chartType}
                  generateChart={generateChart}
                  toggleLoading={toggleLoading}
                  setChatloading={setChatloading}
                  chatQuestion={chatQuestion}
                  selectedDbItem={selectedDbItem}
                />
              </div>
            )
          })}
        </div>
        {show && (
          <div className="chat-panel-loading" style={{ background: 'transparent' }}>
            <div className="chatIconDiv">
              <div className="chatIcon">
                <img src={ai3} width="30" alt="" />
              </div>
            </div>
            <pre className="spinner-container">
              <ImpulseSpinner frontColor="white" size={25} />
            </pre>
          </div>
        )}
      </div>
    </Fragment>
  )
}

Messages.propTypes = {
  messages: PropTypes.array,
  show: PropTypes.bool,
  setMessages: PropTypes.array,
  toggleLoading: PropTypes.func,
  category: PropTypes.string,
}
