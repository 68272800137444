export const ActionTypes = {
  SAVE_HISTORY: 'SAVE_HISTORY',
  CLEAR_HISTORY: 'CLEAR_HISTORY',
}

export const saveHistory = (data) => {
  return (dispatch) => {
    dispatch({ type: 'SAVE_HISTORY', payload: data })
  }
}

export const saveId = (data) => {
  return (dispatch) => {
    dispatch({ type: 'SAVE_ID', payload: data })
  }
}
export const saveTitle = (data) => {
  return (dispatch) => {
    dispatch({ type: 'SAVE_TITLE', payload: data })
  }
}
export const clearHistory = () => {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_HISTORY', payload: [] })
  }
}
