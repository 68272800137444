import { CButton, CModal, CModalHeader, CModalFooter, CModalBody, CModalTitle } from '@coreui/react'
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js'
import { useEffect, useState } from 'react'
import chart1 from '../../assets/images/avatars/chart1.png'
import chart2 from '../../assets/images/avatars/chart2.png'
import chart3 from '../../assets/images/avatars/chart3.png'
import chart4 from '../../assets/images/avatars/chart4.png'
import axios from 'axios'
import { generateVIBGYORColorCodes } from './data'
import { toast } from 'react-toastify'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement)
export const AddModal = ({ visible, setVisible, data, getChartList }) => {
  const [selectedValue, setSelectedValue] = useState('')
  const [promptMsg, setPromptMsg] = useState('')
  const [chartData, setChartData] = useState(null)
  const [chart_id, setChart_id] = useState('')
  const [chart_schema, setChart_schema] = useState([])
  const [, setChartGenerateResponse] = useState([])

  const [, setIsPromptGenerating] = useState(false)
  const parts = window.location.pathname.split('/')
  const dashboardId = parts[parts.length - 1]
  const [labeltoPlot, setLabeltoPlot] = useState(null)
  const [datatoPlot, setDatatoPlot] = useState(null)

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value)
  }
  useEffect(() => {
    setSelectedValue(data?.chartType?.toString())
  }, [data])

  const handleGenerate = async () => {
    let payload = {
      dashboard_id: dashboardId,
      type: selectedValue,
      prompt: promptMsg,
    }
    setIsPromptGenerating(true)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/`,
        payload,
      )
      let schema = response.data.schema.fields

      setChart_schema(schema)
      setChart_id(response.data.chart_id)
      setChartGenerateResponse(response.data.data)

      setIsPromptGenerating(false)
      setScreen('3')
    } catch (error) {
      setIsPromptGenerating(false)
      toast.error('something went wrong')
      setChart_schema([])
      console.error('Error while processing the API response:', error)
      // You can handle the error here (e.g., show an error message to the user).
    } finally {
      setIsPromptGenerating(false) // Make sure to set it back to false whether there was an error or not.
    }
  }

  const plotChart = () => {
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/generate`, {
        chart_id: chart_id,
        keys: [
          {
            key: 'label',
            field: labeltoPlot,
          },
          {
            key: 'data',
            field: datatoPlot,
          },
        ],
      })
      .then((resp) => {
        setScreen('4')
        let datas = {
          data: {
            labels: resp?.data?.data?.label,
            datasets: [
              {
                data: resp?.data?.data?.data,
                backgroundColor: generateVIBGYORColorCodes(resp?.data?.data?.data?.length),
                borderColor: '#fff',
                borderWidth: 2,
              },
            ],
          },
          options: {
            plugins: {
              legend: {
                display:
                  selectedValue === 'pie-chart' || selectedValue === 'dognut-chart' ? true : false,
              },
            },
          },
        }
        setChartData(datas)
      })

    // const labels = chartGenerateResponse.map((product) => product[labeltoPlot])
    // const unitPrices = chartGenerateResponse.map((product) => product[datatoPlot])
  }
  const handleAddToHome = async () => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}charts/add_to_dashboard`,
        {
          chart_id: chart_id,
        },
      )
      setVisible(false)
      setChart_schema([])
      setChart_id([])
      getChartList()
      setPromptMsg('')
    } catch (error) {
      setVisible(false)

      console.error('Error while processing the API response:', error)
      // You can handle the error here (e.g., show an error message to the user).
    }
  }

  const [screen, setScreen] = useState('1')
  return (
    <CModal
      size="lg"
      backdrop="static"
      visible={visible}
      alignment="center"
      onClose={() => {
        setVisible(false)
        setScreen('1')
        setChart_id([])
        getChartList()
        setPromptMsg('')
      }}
    >
      <CModalHeader>
        <CModalTitle>
          {screen === '1' && 'Select Chart'}
          {screen === '2' && 'Generate Chart'}
          {screen === '3' && 'Add Chart'}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        {screen === '1' && (
          <div className="grid">
            <div
              className="row"
              style={{ marginLeft: '0', marginRight: '0', justifyContent: 'space-between' }}
            >
              <label className="modal-card">
                <input
                  name="plan"
                  className="radio"
                  type="radio"
                  value="pie-chart"
                  checked={selectedValue === 'pie-chart'}
                  onChange={handleRadioChange}
                />
                <span className="plan-details">
                  <img src={chart1} alt="1" />
                </span>
              </label>
              <label className="modal-card">
                <input
                  name="plan"
                  className="radio"
                  type="radio"
                  value="dognut-chart"
                  checked={selectedValue === 'dognut-chart'}
                  onChange={handleRadioChange}
                />
                <span className="plan-details">
                  <img src={chart2} alt="1" />
                </span>
              </label>
            </div>
            <div
              className="row"
              style={{ marginLeft: '0', marginRight: '0', justifyContent: 'space-between' }}
            >
              <label className="modal-card">
                <input
                  name="plan"
                  className="radio"
                  type="radio"
                  value="line-chart"
                  checked={selectedValue === 'line-chart'}
                  onChange={handleRadioChange}
                />
                <span className="plan-details">
                  <img src={chart3} alt="1" />
                </span>
              </label>
              <label className="modal-card">
                <input
                  name="plan"
                  className="radio"
                  type="radio"
                  value="bar-chart"
                  checked={selectedValue === 'bar-chart'}
                  onChange={handleRadioChange}
                />
                <span className="plan-details">
                  <img src={chart4} alt="1" />
                </span>
              </label>
            </div>
          </div>
        )}
        {screen === '2' && (
          <div className="grid" style={{ width: '100%' }}>
            <div
              className="row"
              style={{
                display: 'flex',
                marginLeft: '0',
                marginRight: '0',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div style={{ width: '100%' }}>
                <textarea
                  placeholder="Enter the prompt"
                  style={{ width: '100%' }}
                  value={data?.prompt}
                  onChange={(e) => setPromptMsg(e.target.value)}
                />
                <p style={{ color: 'gray', fontSize: '12px' }}>
                  Example: Please create a bar chart comparing the sales performance of different
                  products for the first half of the year. Make sure to label the axes and provide a
                  title for the chart.
                </p>
              </div>
            </div>
          </div>
        )}
        {screen === '3' && (
          <>
            <h6>Please select a label</h6>
            <div className="d-flex" style={{ flexWrap: 'wrap' }}>
              {chart_schema.map((item, i) => (
                <div
                  key={i}
                  className="d-flex p-2 cstm-chip mx-2 my-1"
                  style={{
                    border: item.name === labeltoPlot ? '2px solid black' : '',
                    cursor: 'pointer',
                  }}
                  onClick={() => setLabeltoPlot(item.name)}
                >
                  <span style={{ marginRight: '8px' }}>{item.name}</span>
                  <span>{'( ' + item.type + ' )'}</span>
                </div>
              ))}
            </div>
            <hr />

            <h6>Please select a data</h6>
            <div className="d-flex " style={{ flexWrap: 'wrap' }}>
              {chart_schema
                ?.filter((itm) => itm?.type !== 'string')
                .map((item, i) => (
                  <div
                    key={i}
                    onClick={() => setDatatoPlot(item.name)}
                    className="d-flex p-2 cstm-chip mx-2 my-1"
                    style={{
                      border: item.name === datatoPlot ? '2px solid black' : '',
                      cursor: 'pointer',
                    }}
                  >
                    <span className="mr-2">{item.name}</span>
                    <span>{'( ' + item.type + ' )'}</span>
                  </div>
                ))}
            </div>
          </>
        )}

        {screen === '4' && (
          <div>
            {selectedValue === 'pie-chart' && chartData !== null && (
              <Pie data={chartData?.data} options={chartData?.options} />
            )}

            {selectedValue === 'dognut-chart' && chartData !== null && (
              <Doughnut data={chartData?.data} />
            )}
            {selectedValue === 'line-chart' && chartData !== null && (
              <Line data={chartData?.data} options={chartData?.options} />
            )}
            {selectedValue === 'bar-chart' && chartData !== null && (
              <Bar data={chartData?.data} options={chartData?.options} />
            )}
          </div>
        )}
      </CModalBody>
      <CModalFooter>
        {screen === '1' && (
          <CButton
            onClick={() => {
              setVisible(!visible)
              setScreen('1')
            }}
            color="primary"
          >
            Cancel
          </CButton>
        )}
        {screen === '2' && (
          <CButton onClick={() => setScreen('1')} color="primary">
            Back
          </CButton>
        )}
        {screen === '3' && (
          <CButton onClick={() => setScreen('2')} color="primary">
            Back
          </CButton>
        )}
        {screen === '4' && (
          <CButton onClick={() => setScreen('3')} color="primary">
            Back
          </CButton>
        )}
        {screen === '1' && (
          <CButton onClick={() => setScreen('2')} color="primary">
            Next
          </CButton>
        )}
        {screen === '2' && (
          <CButton
            onClick={() => {
              handleGenerate()
            }}
            color="primary"
          >
            Generate
          </CButton>
        )}
        {screen === '3' && (
          <CButton onClick={() => plotChart()} color="primary">
            Next
          </CButton>
        )}
        {screen === '4' && (
          <CButton onClick={handleAddToHome} color="primary">
            Add To Home screen
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  )
}
