import axios from 'axios'
import { getFormHeaders } from '../../utility/commonUtils'
import { Configuration } from '../../utility/configuration'
export const documentUpload = (payload = {}, setUploadPercentage, onUploadProgress = undefined) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.documentManagement.document}`,
      payload,
      {
        headers: getFormHeaders(),
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          setUploadPercentage(percentCompleted)
        },
      },
    )
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error.response
    })
}
