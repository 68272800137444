/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { CRow, CCol, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
import { toast } from 'src/helpers/toast'
import * as Yup from 'yup'
import CustomModal from '../../../components/CustomModal'
import { axiosPOST, axiosPOSTMeta, axiosPUTMeta } from 'src/utility/apis'
import { ErrorMessage, Form, Formik } from 'formik'
import { getOrganizationList } from 'src/redux/organization/actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'

const AddModal = ({ visible, closeModal, editObj }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (values) => {
    if (editObj) {
      // setIsLoading(true)
      // const formvalues = new FormData()
      // formvalues.append('name', values.name)
      // formvalues.append('username', values.username)
      // formvalues.append('email', values.email)
      // try {
      //   let resp = await axiosPUTMeta(`organization/?id=${editObj?.id}`, formvalues)
      //   dispatch(getOrganizationList(navigate, 10, 1, '', false))
      //   if (resp?.detail) {
      //     toast(resp?.data?.detail?.[0]?.msg, 'error')
      //     setIsLoading(false)
      //   } else {
      //     setIsLoading(false)
      //     closeModal()
      //     // setImage(null)
      //   }
      // } catch (err) {
      //   toast('Something went wrong !!', 'error')
      //   setIsLoading(false)
      // }
    } else {
      setIsLoading(true)
      // const formvalues = new FormData()
      // formvalues.append('name', values.name)
      // if (image) {
      //   formvalues.append('logo', image)
      // }
      try {
        let res = await axiosPOST('organization/', {name:values.name})
        if (res.status === 422) {
          toast(res?.data?.detail?.[0]?.msg, 'error')
          setIsLoading(false)
        } else {
          const payload = {
            'name':values.username,
            'password': values.password,
            'username': values.email,
            organization_id:res.data.id,
            user_type:2,
            departments:[]
          }
          try {
            let secondRes = await axiosPOST(`register/`, payload);
              toast(secondRes.message, 'success');
              setIsLoading(false);
              closeModal();
          } catch (secondErr) {
            toast('Something went wrong !!', 'error')
            setIsLoading(false)
            closeModal()
          }
          setIsLoading(false)
          closeModal()
          // setImage(null)
        }
      } catch (err) {
        setIsLoading(false)
        toast('Something went wrong !!', 'error')
      }
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file && event?.target?.files?.[0]?.type?.includes('image')) {
      setImage(file)
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file && event.dataTransfer.files[0].type.includes('image')) {
      setImage(file)
    }
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(50, 'Maximum 50 characters allowed'),
    username: Yup.string()
      .required('Username is required')
      .max(50, 'Maximum 50 characters allowed'),
    password: Yup.string()
      .required('Password is required')
      .max(50, 'Maximum 50 characters allowed'),
    email: Yup.string()
      .required('Email is required')
      .email('Invalid email address format. Please enter a valid email address.'),
  })
  return (
    <>
      <CustomModal
        isCustomBoard={true}
        visible={visible}
        closeModal={closeModal}
        modalSize="md"
        noMinHeight={true}
        headerText={editObj ? 'Edit organization' : 'Add organization'}
      >
        <Formik
          initialValues={{
            name: editObj ? editObj.name : '',
            username: editObj ? editObj.username : '',
            password: editObj ? '**********' : '',
            email: editObj ? editObj.email : '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleSubmit, setFieldValue, isValid }) => (
            <Form>
              <CRow className="mb-3">
                <CCol md={12} className="ps-lg-2">
                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Organization Name"
                        fullWidth
                        defaultValue={values?.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      <ErrorMessage name="name" component="div" className="error" />
                    </div>
                  </div>
                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Username"
                        fullWidth
                        defaultValue={values?.username}
                        onChange={(e) => setFieldValue('username', e.target.value)}
                      />
                      <ErrorMessage name="username" component="div" className="error" />
                    </div>
                  </div>

                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Password"
                        fullWidth
                        defaultValue={values?.password}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                      />
                      <ErrorMessage name="password" component="div" className="error" />
                    </div>
                  </div>

                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Email"
                        fullWidth
                        defaultValue={values?.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                      <ErrorMessage name="email" component="div" className="error" />
                    </div>
                  </div>

                  {/* <div>
                    <div className="px-2 mb-2">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="image">Logo</label>
                        {image && (
                          <span
                            type="button"
                            className="clear-image"
                            onClick={() => setImage(null)}
                          >
                            clear image
                          </span>
                        )}
                      </div>
                      <input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                      />
                      <div
                        className="cstm-image-upload mb-4 pt-4 pb-2 d-grid justify-content-center "
                        style={{
                          cursor: 'pointer',
                          border: 'dashed 1px white',
                          borderRadius: '18px',
                        }}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onClick={() => document.getElementById('image').click()}
                      >
                        {image ? (
                          <img
                            src={URL.createObjectURL(image)}
                            alt="Uploaded"
                            width={450}
                            style={{ objectFit: 'contain' }}
                            height={100}
                            className="uploaded-image"
                          />
                        ) : (
                          <>
                            <div className="d-grid justify-content-center mb-2">
                              <img src={addDoc} alt="docImage" />
                            </div>
                            <div className="upload-text">Drag & Drop or Click to Upload</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div> */}
                </CCol>
              </CRow>
              <div className="d-flex justify-content-end">
                <button type="button" onClick={closeModal} className="btn btn-secondary">
                  Cancel
                </button>
                <button
                  disabled={
                    isSubmitting ||
                    !(
                      values?.email?.trim() &&
                      values?.name?.trim() &&
                      values?.password?.trim() &&
                      values?.username?.trim()
                    )
                  }
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit(values) // Prevent default form submission
                  }}
                  // type='submit'
                  style={{ marginLeft: '8px' }}
                >
                  {isLoading && <CSpinner size="sm" style={{ marginRight: '8px' }}></CSpinner>}{' '}
                  {editObj ? 'Save' : 'Add'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  )
}

AddModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  // loadWordList: PropTypes.func,
  isEdit: PropTypes.bool,
  editData: PropTypes.array,
}

export default AddModal
