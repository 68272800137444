import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
import { Configuration } from 'src/utility/configuration'
import { toast } from '../../helpers/toast'
import { logout } from '../auth/actions'

export const ActionTypes = {
  LOADER_START: 'LOADER_START',
  LOADER_STOP: 'LOADER_STOP',
  SET_DATA_LOG: 'SET_DATA_LOG',
}

export const getDataLogList = (navigate, count, page = 1, search = '', notApproved,user_id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOADER_START })

    axios
      .get(
        // eslint-disable-next-line prettier/prettier
        `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.dataLog.logging
        }?record_count=${count}&page=${page}&search=${search}&user_id=${user_id}`,
        {
          headers: getHeaders(),
        },
      )
      .then((response) => {
        dispatch({
          type: ActionTypes.SET_DATA_LOG,
          payload: response.data,
        })

        dispatch({ type: ActionTypes.LOADER_STOP })
      })
      .catch((error) => {
        if (error?.response?.request?.status === 401) {
          dispatch(logout())
        }
        dispatch({ type: ActionTypes.LOADER_STOP })
      })
  }
}
