/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { CRow, CCol, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
import { toast } from 'src/helpers/toast'
import * as Yup from 'yup'
import CustomModal from '../../../components/CustomModal'
import { axiosPOST, axiosPOSTMeta, axiosPUT } from 'src/utility/apis'
import addDoc from '../../../assets/images/avatars/add-document-icon.svg'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Button,
    TextField,
    Chip,
    Box
  } from "@mui/material";

const AddModalUser = ({ visible, closeModal, iseditObj, orgID,department=[] }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (values) => {
    console.log("values",values)
    // if (iseditObj) {
    //   let payload = {
    //     name: values.name,
    //   }
    //   try {
    //     setIsLoading(true)
    //     let res = await axiosPUT(`register/${iseditObj.id}`, payload)
    //     if (res?.detail) {
    //       setIsLoading(false)
    //       toast(res?.data?.detail?.[0]?.msg, 'error')
    //     } else {
    //       setIsLoading(false)
    //       toast('Department updated successfully', 'success')
    //       closeModal()
    //     }
    //   } catch (err) {
    //     setIsLoading(false)
    //     toast('Something went wrong !!', 'error')
    //   }
    // } 
    // else {
      let payload = {
        organization_id: orgID,
        name: values.name,
        password:values.password,
        username:values.email,
        departments:values.departmentName,
        user_type:3
      }
      try {
        setIsLoading(true)
        let res = await axiosPOST('register/', payload)
        if (res.status === 422) {
          setIsLoading(false)
          toast(res?.data?.detail?.[0]?.msg, 'error')
        } else {
          setIsLoading(false)
          closeModal()
        }
      } catch (err) {
        setIsLoading(false)
        toast('Something went wrong !!', 'error')
      }
    // }
  }
  const handleChange = (event, setFieldValue) => {
    const { target: { value } } = event
    setFieldValue("departmentName", typeof value === 'string' ? value.split(',') : value)
  }

  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(50, 'Maximum 50 characters allowed'),
    email: Yup.string()
      .email()
      .required('Email is required')
      .max(50, 'Maximum 50 characters allowed'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
    .max(10, 'Password must not exceed 10 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one digit')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    departmentName: Yup.array().min(1, 'Select at least one name').required('Required'),
  })
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 210, // Set your desired max height
        overflowY: 'auto', // Allow vertical scrolling
      },
    },
  };
  return (
    <>
      <CustomModal
        isCustomBoard={true}
        visible={visible}
        closeModal={closeModal}
        modalSize="md"
        noMinHeight
        headerText={iseditObj ? 'Edit User' : 'Add User'}
      >
        <Formik
          initialValues={{
            name: iseditObj ? iseditObj.name : '',
            email: iseditObj ? iseditObj.email : '',
            password: iseditObj ? iseditObj.password : '',
            departmentName: iseditObj ? iseditObj.departmentName : []
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleSubmit, setFieldValue, errors }) => (
            <Form>
              <CRow className="mb-3">
                <CCol md={12} className="ps-lg-2">
                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Name"
                        fullWidth
                        defaultValue={values?.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      <ErrorMessage name="name" component="div" className="error" />
                    </div>
                  </div>

                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Email"
                        fullWidth
                        defaultValue={values?.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                      <ErrorMessage name="email" component="div" className="error" />
                    </div>
                  </div>

                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Password"
                        fullWidth
                        defaultValue={values?.password}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                      />
                      <ErrorMessage name="password" component="div" className="error" />
                    </div>
                  </div>
                  {/*multi select  */}
                  <div>
                    <FormControl sx={{margin:"0px 5px", width: 450 }}>
                      <InputLabel id="demo-multiple-name-label">Department</InputLabel>
                      <Field
                        name="departmentName"
                        render={({ field }) => (
                            <Select
                            {...field}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={values.departmentName}
                            onChange={(e) => handleChange(e, setFieldValue)}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((id) => {
                                      const dept = department.find((item) => item.id === id);
                                      return(
                                        <Chip
                                        sx={{ backgroundColor: 'white', color: 'black' }}
                                         key={id} label={dept ? dept.name : id} />
                                    )})}
                                </Box>
                            )}
                            MenuProps={MenuProps} 
                        >
                            {department.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    <Checkbox checked={values.departmentName.indexOf(item.id) > -1} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))}
                        </Select>
                        )}
                      />
                      <ErrorMessage name="departmentName" component="div" style={{ color: 'red' }} />
                    </FormControl>
                  </div>

                  {/* <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Email"
                        fullWidth
                        defaultValue={values?.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                      <ErrorMessage name="email" component="div" className="error" />
                    </div>
                  </div> */}
                </CCol>
              </CRow>
              <div className="d-flex justify-content-end">
                <button type="button" onClick={closeModal} className="btn btn-secondary">
                  Cancel
                </button>
                <button
                  disabled={isSubmitting || !values?.name?.trim()}
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit(values) // Prevent default form submission
                  }}
                  // type='submit'
                  style={{ marginLeft: '8px' }}
                >
                  {isLoading && <CSpinner size="sm" style={{ marginRight: '8px' }}></CSpinner>}{' '}
                  {iseditObj ? 'Save' : 'Add'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  )
}

AddModalUser.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  // loadWordList: PropTypes.func,
  isEdit: PropTypes.bool,
  editData: PropTypes.array,
}

export default AddModalUser
