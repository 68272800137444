import React, { useEffect, useRef, useState } from 'react'
import sendImage from '../../../assets/adminBoard/send.svg'
import microphoneImage from '../../../assets/adminBoard/microphone.svg'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import Dictaphone from 'src/views/CustomBoard/chatComponents/microphone'

export default function Input({
  addMessage,
  toggleLoading,
  chatLoading,
  categoryId,
  setChatloading,
  subText,
  maxwidth,
  setStreamingStatus,
  setRunningStream,
  setIsUserScroll,
  token,
  inputFromMainScreen,
  setReader,
  reader
}) {
  function handleMicrophone(data) {
    setInput(data)
  }
  const [input, setInput] = useState('')
  const [, setEventSource] = useState(null)
  const [sessionId, setSessionId] = useState(null)
  const inputChatRef = useRef(null)
  const { userData } = useSelector((state) => state.auth)
  useEffect(() => {
    if (chatLoading === false) {
      inputChatRef.current.focus()
    }
  }, [chatLoading])
  useEffect(() => {
    let session_id = uuid()
    setSessionId(session_id)
    inputChatRef.current.style.height = 0.5
  }, [categoryId])

  useEffect(() => {
    if (inputChatRef.current) {
      inputChatRef.current.scrollTop = inputChatRef.current.scrollHeight
    }
    if (inputChatRef.current.value === '') {
      inputChatRef.current.style.overflowY = 'hidden'
      inputChatRef.current.style.height = 0.5
    } else {
      inputChatRef.current.style.overflowY = 'hidden'
    }
  }, [input])

  // function handleInput() {
  //   if (categoryId === null && (token === null || token === undefined)) {
  //     toast.error('Before we proceed with any questions, please select an item from the list !! ')
  //     return
  //   }

  //   var id = uuid()
  //   addMessage({
  //     msg: input,
  //     me: true,
  //     _id: id,
  //     id: id,
  //     category_id: categoryId,
  //     user: userData?.id,
  //   })
  //   setInput('')
  //   toggleLoading(true)
  //   setIsUserScroll(false)
  //   setChatloading(true)
  //   const encodeInput = encodeURIComponent(input)
  //   let categoryOrToken =
  //     categoryId !== null ? `&category_id=${categoryId}` : token !== null ? `&token=${token}` : ''
  //   const eventSource = new EventSource(
  //     `${process.env.REACT_APP_API_ENDPOINT}/chat?question=${encodeInput}${categoryOrToken}&session_id=${sessionId}`,
  //   )
  //   setRunningStream(eventSource)

  //   var updateid = uuid()

  //   eventSource.onmessage = (event) => {
  //     setStreamingStatus(true)

  //     // const response = JSON.parse(event)
  //     toggleLoading(false)

  //     addMessage({
  //       msg: event.data,
  //       me: false,
  //       _id: updateid,
  //       id: updateid,
  //       category_id: categoryId,
  //       user: userData?.id,
  //     })
  //   }
  //   eventSource.onerror = (error) => {
  //     setStreamingStatus(false)
  //     setChatloading(false)

  //     console.error('Error with EventSource:', error)
  //     eventSource.close()
  //   }
  //   setEventSource(eventSource)

  //   return () => {
  //     if (eventSource) {
  //       eventSource.close()
  //     }
  //   }
  // }

  async function handleInput() {
    if (inputChatRef.current) {
      inputChatRef.current.style.height = '55px' //reset textarea height
    }
   if(!input.trim()){
    return;
   }
    if (categoryId === null && (token === null || token === undefined)) {
      toast.error('Before we proceed with any questions, please select an item from the list !!');
      return;
    }
  
    var id = uuid();
    addMessage({
      msg: input,
      me: true,
      _id: id,
      id: id,
      category_id: categoryId,
      user: userData?.id,
    });
    setInput('');
    toggleLoading(true);
    setIsUserScroll(false);
    setChatloading(true);
  
    const encodeInput = encodeURIComponent(input);
  
    // Create a plain JavaScript object to send as JSON
    const bodyData = {
      question: input,
      session_id: sessionId,
      user_id: userData?.id,
    };
  
    if (categoryId !== null) {
      bodyData.category_id = categoryId;
    } else if (token !== null) {
      bodyData.token = token;
    }
  
    try {
      // Make the POST request using fetch
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/chat/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData), // Send the data as a JSON string
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Optionally handle response in chunks using ReadableStream
      const newReader = response.body.getReader(); // Get the new reader
      setReader(newReader); 
      const decoder = new TextDecoder();
      let done = false;
  
      var updateid = uuid();
      setStreamingStatus(true);
  
      while (!done) {
        setStreamingStatus(true);
        toggleLoading(false);
        const { value, done: readerDone } = await newReader.read();
        done = readerDone;
  
        if (value) {
          const chunk = decoder.decode(value, { stream: !done });
  
          addMessage({
            msg: chunk,
            me: false,
            _id: updateid,
            id: updateid,
            category_id: categoryId,
            user: userData?.id,
          });
        }

        if(done){
          setStreamingStatus(false);
          toggleLoading(false);
          setChatloading(false);
        }
      }
  
    } catch (error) {
      setStreamingStatus(false);
      setChatloading(false);
      console.error('Error during POST request:', error);
    }
  }
  
  

  // function autoResize() {
  //   const textarea = inputChatRef.current
  //   if (textarea&&input.trim()) {
  //     textarea.style.height = 0.5 // Reset height
  //     const computedStyle = window.getComputedStyle(textarea)
  //     const lineHeight = parseInt(computedStyle.lineHeight)
  //     const paddingTop = parseInt(computedStyle.paddingTop)
  //     const paddingBottom = parseInt(computedStyle.paddingBottom)
  //     const borderTop = parseInt(computedStyle.borderTopWidth)
  //     const borderBottom = parseInt(computedStyle.borderBottomWidth)
  //     const scrollHeight = textarea.scrollHeight
  //     const maxHeight = lineHeight * 1.5 + paddingTop + paddingBottom + borderTop + borderBottom
  //     textarea.style.height = Math.min(scrollHeight, maxHeight) + 'px'
  //   }
  // }

  const adjustTextareaHeight = (textarea) => {
    console.log(textarea.scrollHeight)
    textarea.style.height = '55px' // Reset height to auto
    textarea.style.height = `${textarea.scrollHeight}px` // Set to scrollHeight
  }

  const handleInputChange = (e) => {
    setInput(e.target.value)
    adjustTextareaHeight(e.target)
  }

  return (
    <div className="send">
      {!input.trim()&&<Dictaphone handleMicrophone={handleMicrophone} />}
      <textarea
        type="text"
        disabled={chatLoading}
        autoFocus={true}
        ref={inputChatRef}
        onKeyDown={(e) => {
           if(e.keyCode === 13 && !e.shiftKey){
             e.preventDefault();
             handleInput()
           } 
          }}
        onChange={handleInputChange}
        value={input}
        className="send-input"
        placeholder="Send a message"
        // onInput={autoResize}
        style={{ resize: 'none', cursor: 'pointer',maxHeight:'90px' }}
      ></textarea>
      <button
        disabled={chatLoading || input.length === 0}
        onClick={() => handleInput()}
        className="send-icon"
      >
        <img
          src={sendImage}
          className={chatLoading || !input.trim() ? 'send-btn-disable' : 'send-btn'}
          alt=""
        />
      </button>
    </div>
  )
}

Input.propTypes = {
  addMessage: PropTypes.func,
  toggleLoading: PropTypes.func,
  chatLoading: PropTypes.bool,
  category: PropTypes.string,
}
