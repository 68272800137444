import React from 'react'
import chartImg from '../../assets/images/avatars/charts.png'
import { CButton } from '@coreui/react'
const CreateNew = ({ setHideStart, setVisible }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="card-dashboard">
        <div className="selection__title">Get Started</div>
        <div>Create your own customized charts</div>
        <div style={{ marginTop: '30px' }}>
          <img src={chartImg} alt="chart-img" style={{ maxWidth: '100%' }} />
        </div>
        {/* <div className="d-flex justify-content-center ">
          <CButton
            className="cstm-btn"
            onClick={() => {
              setHideStart(true)
              setVisible(true)
            }}
          >
            Get Started
          </CButton>
        </div> */}
      </div>
    </div>
  )
}

export default CreateNew
