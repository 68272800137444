import { Accordion, AccordionDetails, AccordionSummary, Typography, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getDataLogList } from 'src/redux/dataLog/actions'
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from 'src/components/ConfirmDelete'
import { toast } from 'react-toastify'
import { axiosDELETE } from 'src/utility/apis'
import { Configuration } from 'src/utility/configuration'
import ReactMarkdown from 'react-markdown';

export default function CustomBoardDatalog() {
  const { data } = useSelector((state) => state.dataLog)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleteButtonDisable, setIsDeleteButtonDisable] = useState(false)
  const [loading, setLoading] = useState(null)
   const [deleteItem, setDeleteItem] = useState(null)
  useEffect(() => {
    loadDataLogList()
  }, [])

  const handleDeleteFile = ({id:dId}) => {
    setDeleteModal(true)
    setDeleteItem(dId)
  }

  const onConfirmCancel = () => {
    setDeleteModal(false)
    setDeleteItem(null)
  }

  const onDelete = async () => {
    setIsDeleteButtonDisable(true)

    await axiosDELETE(`${Configuration.dataLog.logging}${deleteItem}`)
      .then((data) => {
        setIsDeleteButtonDisable(false)
        if (data.status === 200) {
          loadDataLogList()
          setIsDeleteButtonDisable(false)
          const msgTxt = typeof data?.message === 'string' ? data.message : 'Deleted successfully!'
          toast.success(msgTxt)
        } else toast.error('Failed to delete. Try again.')
      })

      .catch((error) => {
        setLoading(false)
        if (error?.response?.request?.status === 422) {
          toast.error('Failed to delete. Try again.')
        } else if (error?.response?.request?.status === 401) {
          // dispatch(logout())
          setIsDeleteButtonDisable(false)
        } else {
          toast.error('Something went wrong , Please try again later !!')
        }
      })
    setLoading(null)
    setDeleteModal(false)
    setDeleteItem(null)
    setIsDeleteButtonDisable(false)
  }
  

  const loadDataLogList = () => {
    dispatch(getDataLogList(navigate, 10, 1, '', false,authState?.userData?.id))
  }
  const [expandedAccordion, setExpandedAccordion] = useState(0)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false)
  }

  return (
    <>
      <div className="content-area ">
        {data?.data?.length > 0 ? (
          <>
            <div style={{ overflowY: 'auto', height: 'calc(100vh - 168px)' }}>
              {data?.data?.map((itm, i) => (
                <div key={itm?.id}>
                  <h6>
                    <u>{itm?.category_name}</u>
                  </h6>
                  {itm?.logs?.length > 0 ? (
                    itm?.logs?.map((logs, index) => (
                      <Accordion
                        key={logs?.id}
                        expanded={expandedAccordion === `${i}-${index}`}
                        onChange={handleChange(`${i}-${index}`)}
                        style={{ background: '#121d12', color: '#fff', marginBottom: '8px' }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                          aria-controls={`panel${index + 1}-content`}
                          id={`panel${index + 1}-header`}
                          color="white"
                        >
                          <Typography style={{ wordBreak: 'break-word' }}>
                            Question : {logs?.question}
                          </Typography>
                          <IconButton style={{ color: 'white', marginLeft: 'auto' }} onClick={(e)=>{e.stopPropagation();handleDeleteFile({id:logs?.id})}}>
                              <DeleteIcon sx={{fontSize:'18px'}} />
                          </IconButton>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {/* {logs?.log_details?.map((ans) => ( */}
                              <span style={{ fontSize: '14px' }} className="mb-0" key={logs?.id}>
                                <ReactMarkdown>{`Answer : ${logs?.answer}`}</ReactMarkdown> 
                              </span>
                            {/* ))} */}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))
                  ) : (
                    <p className="text-center">No Logs</p>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '70px', fontSize: '18px' }}>No data</p>
        )}
      </div>
      <ConfirmDelete
        isCustomBoard={true}
        visible={deleteModal}
        cancelDelete={onConfirmCancel}
        confirmDelete={onDelete}
        isDeleteButtonDisable={isDeleteButtonDisable}
        deleteButtonDisable={loading}
      />
    </>
  )
}
