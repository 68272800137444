import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
import { Configuration } from 'src/utility/configuration'
import { toast } from '../../helpers/toast'
import { logout } from '../auth/actions'

export const ActionTypes = {
  LOADER_START: 'LOADER_STARTS',
  LOADER_STOP: 'LOADER_STOPS',
  SET_DOCUMENT_LIST: 'SET_DOCUMENT_LIST',
  UNAUTHORIZED: 'UNAUTHORIZED',
}

export const getDocumentList = (
  userRole,
  orgId,
  department_id,
  page,
  search = '',
  organization,
  auth_id
) => {
  // let param =
  //   userRole === 2 ? `&organization=${orgId}` : userRole === 3 ? `&department=${department_id}` : ''
  let param =
    userRole === 2 ? `&organization=${orgId}` : userRole === 3 ? `&organization=${orgId}` : ''
  let orgparam = organization ? `&organization=${organization}` : ''
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOADER_START })
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.documentManagement.document}?page=${page}${param}&search=${search}${orgparam}&user_id=${auth_id}`,
        {
          headers: getHeaders(),
        },
      )
      .then((response) => {
        dispatch({
          type: ActionTypes.SET_DOCUMENT_LIST,
          payload: response,
        })

        dispatch({ type: ActionTypes.LOADER_STOP })
      })
      .catch((error) => {
        if (error?.response?.request?.status === 401) {
          dispatch(logout())
        }
        dispatch({ type: ActionTypes.LOADER_STOP })
      })
  }
}
