import { IconButton, Menu, MenuItem, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddCircle, FilterAlt, MoreVert, Search } from '@mui/icons-material'
import AddModal from './AddModal'
import { getOrganizationList } from 'src/redux/organization/actions'
import { axiosDELETE } from 'src/utility/apis'
import { toast } from 'react-toastify'
import ConfirmDelete from 'src/components/ConfirmDelete'
import DeleteIcon from '../../../assets/adminBoard/trash.svg'
import NoDataImage from '../../../assets/adminBoard/nodata.svg'
import pulse from '../../../assets/adminBoard/pulse.svg'
import CIcon from '@coreui/icons-react'
import { cilPencil } from '@coreui/icons'
import { Paginate } from 'src/components'
import EditModal from './EditModal'

export default function UserManagment() {
  const { data, isLoading } = useSelector((state) => state.organization)
  const [visible, setVisible] = useState(false)
  const [iseditObj, setIseditObj] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteobjId, setDeleteobjId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const [searchTerm, setSearchTerm] = useState('')
  const debounceDelay = 300 // Set your desired debounce delay in milliseconds

  const debounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }

  const handleSearch = debounce((query) => {
    setTimeout(() => {
      const results = dispatch(getOrganizationList(navigate, 10, currentPage, query, false)) // Replace with your actual search function
    }, 500)
  }, debounceDelay)

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value
    setSearchTerm(newSearchTerm)
    handleSearch(newSearchTerm)
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    loadOrgList()
  }, [])

  const handleDeleteModalCancel = () => {
    setDeleteModal(false)
  }
  const loadOrgList = () => {
    dispatch(getOrganizationList(navigate, 10, currentPage, searchTerm, false))
  }

  const handleDeleteOrganization = async (id) => {
    try {
      const paramObj = { id: id }
      await axiosDELETE(`organization/${id}`)
      toast('Deleted successfully!', 'success')
      dispatch(getOrganizationList(navigate, 10, currentPage, searchTerm, false))
      setDeleteModal(false)
    } catch (err) {
      toast('Something went wrong !!', 'error')
    }
  }
  const userRole = useSelector((state) => state.auth.userData?.user_type)
  const orgId = useSelector((state) => state.auth.userData?.organization_id)
  const department_id = useSelector((state) => state.auth.userData?.department_id)

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1
    setCurrentPage(selectedPage)
    dispatch(getOrganizationList(navigate, 10, selectedPage, searchTerm, false))
  }
  useEffect(() => {
    if (userRole === 1) {
      // do nothing
    } else if (userRole === 2) {
      navigate(`/department/${orgId}`)
    } else if (userRole === 3) {
      // navigate(`/category/${department_id}`)
      navigate(`/department/${orgId}`)
    }
  }, [])

  useEffect(() => {
    if (!visible) {
      dispatch(getOrganizationList(navigate, 10, currentPage, searchTerm, false))
    }
  }, [visible])
  return (
    <>
      <div className="content-area ">
        <div className="d-flex justify-content-between mb-4">
          <h4 className="d-flex align-items-end">Organizations</h4>
        </div>

        <div className="d-flex justify-content-end mb-4">
          <div className="search-cstmOrg" style={{ marginRight: '16px' }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              className="search-cstm-text"
              onChange={handleInputChange}
            />
            <Search className="search-icon-cstm" />
          </div>
          <button
            className="btn btn-add"
            onClick={() => setVisible(true)}
            style={{ cursor: 'pointer' }}
          >
            <AddCircle style={{ marginRight: '8px', fontSize: '20px' }} />
            Add new
          </button>
        </div>
        {!isLoading &&
          data?.data?.map((itm) => (
            <>
              <div
                className="upload-items uploded"
                key={itm?.id}
                style={{ padding: 0 }}
              >
                <div className="uploaded-data d-flex">
                  <div
                    className="progress-sc me-3"
                    style={{ padding: '1.5rem' }}
                    // onClick={(e) => {
                    //   navigate(`/department/${itm.id}`, {
                    //     state: { data: itm },
                    //   })
                    //   // handleSubmit(values) // Prevent default form submission
                    // }}
                  >
                    <h6 className="mb-0">
                      <span>{itm?.name}</span>
                    </h6>
                  </div>
                  <div className="close-icon d-flex">
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '1.5rem',
                      }}
                    >
                      <CIcon
                        style={{ marginRight: '16px', cursor: 'pointer' }}
                        icon={cilPencil}
                        onClick={() => {
                          setVisible(true)
                          setIseditObj(itm)
                        }}
                      />
                      <img
                        src={DeleteIcon}
                        style={{ marginRight: '12px', cursor: 'pointer' }}
                        alt=""
                        width={16}
                        height={16}
                        onClick={() => {
                          setDeleteobjId(itm.id)
                          setDeleteModal(true)
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </>
          ))}
        {isLoading && (
          <div className="upload-items uploded">
            <div className="uploaded-data d-flex">
              <div className="progress-sc me-3">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center mx-2">Loading </div>
                  <img src={pulse} alt="loader" width={36} />
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoading && data?.length === 0 && (
          <div className="nodata text-center">
            <img src={NoDataImage} alt="nodata" width={40} height={40} />
            <span style={{ marginLeft: '8px', fontWeight: '700' }}>No data available</span>
          </div>
        )}
        {data?.data?.total_count > 10 && (
          <div className="mt-4 d-flex justify-content-end">
            <Paginate
              handlePageClick={handlePageClick}
              currentPage={currentPage}
              totalCount={data?.data?.total_count}
              limit={10}
            />
          </div>
        )}
      </div>
     {!iseditObj? <AddModal
        visible={visible}
        setVisible={setVisible}
        closeModal={() => {
          setVisible(false)
          setIseditObj(null)
        }}
        editObj={iseditObj}
      />:<EditModal
      visible={visible}
      setVisible={setVisible}
      editObj={iseditObj}
      closeModal={() => {
        setVisible(false)
        setIseditObj(null)
      }}
    />}
      <ConfirmDelete
        isCustomBoard={true}
        visible={deleteModal}
        cancelDelete={handleDeleteModalCancel}
        confirmDelete={() => handleDeleteOrganization(deleteobjId)}
      />
    </>
  )
}
