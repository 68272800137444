import React, { useEffect, useState } from 'react'

import hamburgerImage from '../../assets/adminBoard/hamburger.svg'
import logoImage from '../../assets/adminBoard/whizz-01.svg'
import logoutImage from '../../assets/adminBoard/logoutt.svg'
import menuImage from '../../assets/adminBoard/icon-2.svg'
import datalogImage from '../../assets/adminBoard/dashboard.svg'
import chartImage from '../../assets/adminBoard/graph-white.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import whizzGreen from '../../assets/adminBoard/whizzGreen.svg'
import greenChartImage from '../../assets/adminBoard/greenChartImage.svg'
import greenDatalogImage from '../../assets/adminBoard/greenDatalogImage.svg'
import greenMenuImage from '../../assets/adminBoard/greenMenuImage.svg'
import user from '../../assets/adminBoard/users.svg'
import greenuserImage from '../../assets/adminBoard/greenUsers.svg'
import greenCategoryImage from '../../assets/adminBoard/greencategoryIcon.svg'
import categoryImage from '../../assets/adminBoard/categoryIcon.svg'
import userImage from '../../assets/adminBoard/users.svg'
import { setSidebar } from 'src/redux/feature/sidebarSlice'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from 'src/redux/auth/actions'
import { toast } from 'src/helpers/toast'
import  CIcon  from '@coreui/icons-react';
import {cilPeople,cilDescription, cilChatBubble } from '@coreui/icons';
export default function Layout({ children }) {
  const [menuSelected, setMenuSelected] = useState('mydashboard')
  const [isToggleOn, setIsToggleOn] = useState(false)
  const location = useLocation()
  const [title, setTitle] = useState('')
  const { sidebarShow } = useSelector((state) => state.sidebar)
  const { isAuthenticated, userData } = useSelector((state) => state.auth)
  const [embedScreen, setembedScreen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = useSelector((state) => state.auth.userData?.user_type)
  useEffect(() => {
    dispatch(setSidebar(false))
  }, [])

  useEffect(() => {
    if (location.pathname === '/mydashboard') {
      setTitle('Dashboard')
      setMenuSelected('mydashboard')
    } else if (location.pathname === '/mydatalog') {
      setTitle('Datalog')
      setMenuSelected('mydatalog')
    } else if (location.pathname === '/mychart') {
      setTitle('Chart')
      setMenuSelected('mychart')
    }else if(location.pathname==='/user-creation'){
      setTitle('Users')
      setMenuSelected('user-creation')
    }else if(location.pathname==='/OpenChat'){
      setTitle('Chat')
      setMenuSelected('OpenChat')
    }else if(location.pathname==='/InsuranceAdvisor'){
      setTitle('Insurance Advisor')
      setMenuSelected('InsuranceAdvisor')
    }else if(location.pathname==='/Policy'){
      setTitle('Policy Assistant')
      setMenuSelected('Policy')
    }   
    else {
      setTitle('User Management')
      setMenuSelected('user-management')
    }
    if (location.pathname.includes('chat/embed')) {
      setembedScreen(true)
    } else {
      setembedScreen(false)
    }
  }, [location?.pathname])

  const handleToggleScreen = () => {
    if (isToggleOn) {
      const body = document.getElementById('mainBody')
      dispatch(setSidebar(false))
      body.classList.remove('openbox')
    } else {
      const body = document.getElementById('mainBody')
      body.classList.add('openbox')
      dispatch(setSidebar(true))
    }
    setIsToggleOn(!isToggleOn)
  }

  useEffect(() => {
    if (!isAuthenticated && location?.pathname !== '/login') {
      toast('Session Expired! Please log in again to continue your journey.', 'error')
      navigate('/login')
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated && !location.pathname.includes('chat/embed')) {
      navigate('/login')
    }
  }, [])

  const handleLogout = () => {
    dispatch(logout())
    navigate('/login')
  }
  return (
    <>
      {isAuthenticated && !embedScreen ? (
        <div id="mainBody">
          <header className="whizz-header" id="whizz-header" style={{ minHeight: '66px' }}>
            <div className="hdr-main">
              <nav className="navbar navbar-dark header-bar px-2" style={{ marginTop: '10px' }}>
                <div className="left d-flex">
                  <h4>{title}</h4>
                </div>
                <div className="left d-flex align-items-center">
                  <span>{userData?.username}</span>
                  <img src={user} alt="" width="30" style={{ margin: '0 10px' }} />
                </div>
              </nav>
            </div>
          </header>

          <section className="main-content">
            <div className="leftside-bar" style={{ width: isToggleOn ? '254px' : '84px' }}>
              <div className="arrow-toggle">
                <p onClick={handleToggleScreen} style={{ cursor: 'pointer' }}>
                  <img src={hamburgerImage} alt="" width="20" />
                </p>
              </div>
              <div className="logobox mb-5 text-center">
                <img src={whizzGreen} alt="" width={'25px'} />
                {isToggleOn && <span className="ctsm-whizzHeader">{'Whizz'}</span>}
              </div>
              <ul className="list-unstyled sidebar-menu" style={{ cursor: 'pointer' }}>
              <li className="sidemenu-items" onClick={() => navigate('/Policy')} title='Policy'>
                  <p
                    style={{ alignItems: 'end' }}
                    className={`${isToggleOn ? 'cstm-margin' : 'text-center'}`}
                  >
                      <img
                      height={30}
                      width={30}
                      src={menuSelected === 'Policy' ? greenMenuImage : menuImage}
                      style={{ marginRight: isToggleOn && '14px' }}
                    />
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'Policy' && '#4bc641' }}>
                        {'Policy'}
                      </span>
                    )}
                  </p>
                </li>
              <li className="sidemenu-items" onClick={() => navigate('/InsuranceAdvisor')} title='InsuranceAdvisor'>
                  <p
                    style={{ alignItems: 'end' }}
                    className={`${isToggleOn ? 'cstm-margin' : 'text-center'}`}
                  >
                     <img
                      height={30}
                      width={30}
                      src={menuSelected === 'InsuranceAdvisor' ? greenMenuImage : menuImage}
                      style={{ marginRight: isToggleOn && '14px' }}
                    />
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'InsuranceAdvisor' && '#4bc641' }}>
                        {'Insurance Advisor'}
                      </span>
                    )}
                  </p>
                </li>
              <li className="sidemenu-items" onClick={() => navigate('/OpenChat')} title='OpenChat'>
                  <p
                    style={{ alignItems: 'end' }}
                    className={`${isToggleOn ? 'cstm-margin' : 'text-center'}`}
                  >
                    {/* <img
                      height={30}
                      width={30}
                      src={menuSelected === 'OpenChat' ? greenMenuImage : menuImage}
                      style={{ marginRight: isToggleOn && '14px' }}
                    /> */}
                    <CIcon icon={cilChatBubble} style={{color:menuSelected === 'OpenChat'?'#4bc641':'',marginRight:isToggleOn && '14px'}} size="xxl"/>
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'OpenChat' && '#4bc641' }}>
                        {'Chat'}
                      </span>
                    )}
                  </p>
                </li>
                <li className="sidemenu-items" onClick={() => navigate('/mydashboard')} title='Dashboard'>
                  <p
                    style={{ alignItems: 'end' }}
                    className={`${isToggleOn ? 'cstm-margin' : 'text-center'}`}
                  >
                    <img
                      height={30}
                      width={30}
                      src={menuSelected === 'mydashboard' ? greenMenuImage : menuImage}
                      style={{ marginRight: isToggleOn && '14px' }}
                    />
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'mydashboard' && '#4bc641' }}>
                        {'Dashboard'}
                      </span>
                    )}
                  </p>
                </li>
                <li className="sidemenu-items" onClick={() => navigate('/mydatalog')} title='Datalog'> 
                  <p
                    style={{ alignItems: 'end' }}
                    className={`${isToggleOn ? 'cstm-margin' : 'text-center'}`}
                  >
                    <img
                      height={30}
                      width={30}
                      src={menuSelected === 'mydatalog' ? greenDatalogImage : datalogImage}
                      style={{ marginRight: isToggleOn && '14px' }}
                      alt="logo"
                    />
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'mydatalog' && '#4bc641' }}>
                        {'Datalog'}
                      </span>
                    )}
                  </p>
                </li>
                {/* <li className="sidemenu-items" onClick={() => navigate('/mychart')} title='Chart'>
                  <p
                    style={{ alignItems: 'end' }}
                    className={isToggleOn ? 'cstm-margin' : 'text-center'}
                  >
                    <img
                      height={28}
                      width={28}
                      src={menuSelected === 'mychart' ? greenChartImage : chartImage}
                      style={{ marginRight: isToggleOn && '14px' }}
                      alt="ss"
                    />
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'mychart' && '#4bc641' }}>
                        {'Chart'}
                      </span>
                    )}
                  </p>
                </li> */}
                <li className="sidemenu-items" onClick={() => navigate('/user-management')} title='User Management'>
                  <p
                    style={{ alignItems: 'end' }}
                    className={isToggleOn ? 'cstm-margin' : 'text-center'}
                  >
                    <img
                      height={30}
                      width={30}
                      src={
                        menuSelected === 'user-management'
                          ? userRole === 2
                            ? greenCategoryImage
                            : greenCategoryImage
                          : userRole === 2
                          ? categoryImage
                          : categoryImage
                      }
                      style={{ marginRight: isToggleOn && '14px' }}
                      alt="ss"
                    />
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'user-management' && '#4bc641' }}>
                        {'User Management'}
                      </span>
                    )}
                  </p>
                </li>
                {userRole === 2&&<li className="sidemenu-items" onClick={() => navigate('/user-creation')} title='Users'>
                  <p
                    style={{ alignItems: 'end' }}
                    className={isToggleOn ? 'cstm-margin' : 'text-center'}
                  >
                    <img
                      height={30}
                      width={30}
                      src={
                        menuSelected === 'user-creation'
                        ? userRole === 2?greenuserImage: userImage:userImage
                      }
                      style={{ marginRight: isToggleOn && '14px' }}
                      alt="ss"
                    />
                    {isToggleOn && (
                      <span style={{ color: menuSelected === 'user-creation' && '#4bc641' }}>
                        {'Users'}
                      </span>
                    )}
                  </p>
                </li>}
              </ul>
              <div className="logout-sc" title='Logout'>
                <p
                  className={isToggleOn ? 'cstm-margin' : 'text-center'}
                  style={{ cursor: 'pointer', alignItems: 'end' }}
                  onClick={handleLogout}
                >
                  <img src={logoutImage} alt="" />
                  <span>Logout</span>
                </p>
              </div>
            </div>
            {children}
          </section>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  )
}
