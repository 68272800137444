export const ActionTypes = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  GET_MESSAGE: 'GET_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGES'
}

export const appendMessage = (msgs) => {
  return (dispatch) => {
    dispatch({ type: "ADD_MESSAGE", payload: msgs })
  }
};
export const deleteMessage = (msgs) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_MESSAGE, payload: msgs })
  }
};

export const editMessage = (index, updatedMessage) => {
  return(dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MESSAGE,
    payload: { index, updatedMessage }
  })
}
};

export const clearMessages = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_MESSAGE, payload: [] })
  }
}