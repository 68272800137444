const initialState = {
    isLoading: false,
    data: null,
  }
  
  export const userList = (state = initialState, action) => {
    switch (action.type) {
      case 'LOADER_START':
        return {
          ...state,
          isLoading: true,
        }
      case 'LOADER_STOP':
        return {
          ...state,
          isLoading: false,
        }
      case 'SET_UESRS':
        return {
          ...state,
          data: action.payload,
        }
      default:
        return state
    }
  }