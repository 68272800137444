import axios from 'axios'
import { getFormHeaders, getHeaders } from 'src/utility/commonUtils'

export const axiosGET = (url, paramObj = undefined) => {
  return axios
    .get(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
      headers: getHeaders(),
      params: paramObj,
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error
    })
}

export const axiosDELETE = (url = '', paramObj = undefined) => {
  return axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, {
      data: {},
      headers: getHeaders(),
      params: paramObj,
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error?.response?.data
    })
}

export const axiosPOST = (url, payload) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error?.response
    })
}
export const axiosPOSTMeta = (url, payload) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getFormHeaders(),
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error?.response
    })
}
export const axiosPOSTAIQuery = (url, payload) => {
  return axios
    .post(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error?.response
    })
}

export const axiosDELETEAIQuery = (url) => {
  return axios
    .delete(`${process.env.REACT_APP_API_ENDPOINT_AI_QUERY}/${url}`, {
      data: {},
      headers: getHeaders(),
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error?.response
    })
}

export const axiosPUT = (url, payload) => {
  return axios
    .put(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error?.response?.data
    })
}

export const axiosPUTMeta = (url, payload) => {
  return axios
    .put(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getFormHeaders(),
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error?.response?.data
    })
}

export const axiosPATCH = (url, payload) => {
  return axios
    .patch(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response?.data
    })
    .catch((error) => {
      return error?.response?.data
    })
}

export const axiosDataPUT = (url = '', payload = {}) => {
  return axios
    .put(`${process.env.REACT_APP_API_ENDPOINT}/${url}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    })
}
