import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
import { Configuration } from 'src/utility/configuration'
import { toast } from '../../helpers/toast'
import { logout } from '../auth/actions'

export const ActionTypes = {
  LOADER_START: 'LOADER_START',
  LOADER_STOP: 'LOADER_STOP',
  SET_UESRS: 'SET_UESRS',
}


export const getUserList=(org_id=1)=>{
    return (dispatch)=>{
        dispatch({ type: ActionTypes.LOADER_START })
        axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/users/organization/${org_id}`,
          {
            headers: getHeaders(),
          },
        )
        .then((response) => {
          dispatch({
            type: ActionTypes.SET_UESRS,
            payload: response.data,
          })
  
          dispatch({ type: ActionTypes.LOADER_STOP })
        })
        .catch((error) => {
          if (error?.response?.request?.status === 401) {
            dispatch(logout())
          }
          dispatch({ type: ActionTypes.LOADER_STOP })
        })
    }
}