/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Skeleton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import CreateNew from './createNew'
import './dashboard.css'

const UserDashboard = ({
  exportChartArr = [],
  isExportChartArrLoading = false,
  setIsExportChartArrLoading = () => {},
  getExportDatas = () => {},
}) => {
  const location = useLocation()
  const skeltonArray = Array(6).fill(1)
  const parts = location.pathname.split('/')
  const dashboardId = parts[parts.length - 1]

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  return (
    <div className="ml-2 w-100" style={{overflow:'auto'}} onDragOver={handleDragOver}>
      <>
        {dashboardId === 'mychart' && (
          <CreateNew
            exportChartArr={exportChartArr}
            isExportChartArrLoading={isExportChartArrLoading}
            setIsExportChartArrLoading={(val1) => {
              setIsExportChartArrLoading(val1)
            }}
            getExportDatas={getExportDatas}
          />
        )}
        <div className="d-flex flex-wrap">
          {dashboardId !== 'mychart' &&
            skeltonArray?.map((d, index) => (
              <Box key={index} className="chart-container">
                <Skeleton variant="rectangular" width={330} height={250} />

                <Box sx={{ pt: 0.5, width: 210 }}>
                  <Skeleton width={330} />
                  <Skeleton width={330} />

                  <Skeleton width="60%" />
                </Box>
              </Box>
            ))}
        </div>
      </>
    </div>
  )
}

export default UserDashboard
