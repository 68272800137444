import React, { useEffect, useRef, useState } from 'react'
import SendIcon from '../../assets/adminBoard/send.svg'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import Dictaphone from '../CustomBoard/chatComponents/microphone'
import { toast } from 'react-toastify'
export default function Input({
  cstmBoard,
  addMessage,
  toggleLoading,
  chatLoading,
  setChatloading,
  subText,
  maxwidth,
  selectedDbItem = undefined,
  setChatQuestion = () => {},
  userSelectedList
}) {
  function handleMicrophone(data) {
    setInput(data)
  }
  const [input, setInput] = useState('')
  const inputChatRef = useRef(null)

  useEffect(() => {
    if (inputChatRef.current) {
      inputChatRef.current.scrollTop = inputChatRef.current.scrollHeight
    }
    if (inputChatRef.current.value === '') {
      inputChatRef.current.style.overflowY = 'hidden'
      inputChatRef.current.style.height = 0.5
    } else {
      inputChatRef.current.style.overflowY = 'hidden'
    }
  }, [input])

  const handleGenerate = async () => {
    if(userSelectedList===true){
      if(!input.trim()){
        return;
       }
      try {
        toggleLoading(true)
        setChatloading(true)
        await getChatAnswer()
      } catch {
        setChatloading(false)
        toggleLoading(false)
        var updateIds = uuid()
        addMessage({
          msg:
            "I'm sorry, but I couldn't find any data that matches your request at the moment. " +
            ' If you have a different question or need assistance with something else, ' +
            " please feel free to ask, and I'll do my best to assist you.",
          me: false,
          _id: updateIds,
          id: updateIds,
          isError: true,
        })
      }
    }else{
      toast.error('Before we proceed with any questions, please select an item from the list !! ')
      return
    }
  }

  /**
   * Get chat answer
   */
  const getChatAnswer = async () => {
    try {
      var id = uuid()
      addMessage({
        msg: input,
        me: true,
        _id: id,
        id: id,
        exportId: id,
        isExportChartShow: false,
      })
      setChatQuestion(input)
      setInput('')
      const payload = { question: input, uuid: selectedDbItem?.table_name }
      const updateIds = uuid()
      toggleLoading(true)
      setChatloading(true)
      // addMessage({
      //   msg: 'Loading',
      //   me: false,
      //   _id: updateIds,
      //   id: updateIds,
      //   isError: false,
      //   exportId: undefined,
      //   isExportChartShow: false,
      //   isShowGenerateChart: true,
      // })
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/sql/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
      if (!res.body) {
        console.error('No stream found.')
        return
      }

      const reader = res.body.getReader()
      const decoder = new TextDecoder('utf-8')
      let done = false
      let chunkedResponse = ''

      while (!done) {
        // toggleLoading(true);
        setChatloading(false)
        const { value, done: readerDone } = await reader.read()
        done = readerDone
        chunkedResponse = decoder.decode(value, { stream: true })
        addMessage({
          msg: chunkedResponse,
          me: false,
          _id: updateIds,
          id: updateIds,
          isError: false,
          exportId: undefined,
          isExportChartShow: false,
          isShowGenerateChart: true,
        })
      }
      if(done){
        toggleLoading(false);
      }
    } catch (err) {
      console.error('Error during streaming: ', err)
    }
  }

  // function autoResize() {
  //   const textarea = inputChatRef.current
  //   if (textarea) {
  //     textarea.style.height = 0.5 // Reset height
  //     const computedStyle = window.getComputedStyle(textarea)
  //     const lineHeight = parseInt(computedStyle.lineHeight)
  //     const paddingTop = parseInt(computedStyle.paddingTop)
  //     const paddingBottom = parseInt(computedStyle.paddingBottom)
  //     const borderTop = parseInt(computedStyle.borderTopWidth)
  //     const borderBottom = parseInt(computedStyle.borderBottomWidth)
  //     const scrollHeight = textarea.scrollHeight
  //     const maxHeight = lineHeight * 1 + paddingTop + paddingBottom + borderTop + borderBottom
  //     textarea.style.height = Math.min(scrollHeight, maxHeight) + 'px'
  //   }
  // }

  const adjustTextareaHeight = (textarea) => {
    console.log(textarea.scrollHeight)
    textarea.style.height = '55px' // Reset height to auto
    textarea.style.height = `${textarea.scrollHeight}px` // Set to scrollHeight
  }

  const handleInputChange = (e) => {
    setInput(e.target.value)
    adjustTextareaHeight(e.target)
  }

  return (
    <div
      className={`botm ${cstmBoard && 'p-3 cstm-background'} `}
      style={{
        maxWidth: !maxwidth ? '650px' : '',
        position: 'fixed',
    bottom: 0,
    width: '420px'
      }}
    >
      <div className={`${!cstmBoard && 'input-box'} position-relative`}>
        {input.length===0&&<Dictaphone handleMicrophone={handleMicrophone} />}
        <textarea
          className={`input-area ${cstmBoard && 'cstm-send-input'} `}
          disabled={chatLoading}
          style={{
            color: cstmBoard && 'white',
            resize: 'none',
            cursor: 'pointer',
            maxHeight:'90px'
          }}
          ref={inputChatRef}
          autoFocus={true}
          onKeyDown={(e) => {
            if(e.keyCode === 13 && !e.shiftKey){
              e.preventDefault();
              handleGenerate()
            } 
           }}
          onChange={handleInputChange}
          value={input}
          type="text"
          placeholder="Search"
          // onInput={autoResize}
        ></textarea>
        <button
          className="cstm-icon send-icon cstm-send-icon"
          style={{
            // rotate: '90deg',
            marginTop: '14px',
            marginRight: '8px',
            color: chatLoading || input.length === 0 ? '#ccc' : '#4BC641',
            cursor: chatLoading || input.length === 0 ? 'unset' : 'pointer',
            bottom:'16px'
          }}
          disabled={chatLoading || input.length === 0}
          onClick={() => handleGenerate()}
        >
          <img src={SendIcon} alt="send" style={{ cursor: 'pointer' }} />
        </button>
        <p className="brand-name">{subText}</p>
      </div>
    </div>
  )
}

Input.propTypes = {
  addMessage: PropTypes.func,
  toggleLoading: PropTypes.func,
  chatLoading: PropTypes.bool,
  category: PropTypes.string,
}
