const initialState = {
  isAuthenticated: false,
  userData: [],
  isLoadingAuth: false,
}

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_START_AUTH':
      return {
        ...state,
        isLoadingAuth: true,
      }
    case 'LOADER_STOP_AUTH':
      return {
        ...state,
        isLoadingAuth: false,
      }
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        userData: action.payload,
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        userData: [],
      }
    default:
      return state
  }
}
