/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { CRow, CCol, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
import { toast } from 'src/helpers/toast'
import * as Yup from 'yup'
import CustomModal from '../../../components/CustomModal'
import { axiosPOST, axiosPOSTMeta, axiosPUT } from 'src/utility/apis'
import addDoc from '../../../assets/images/avatars/add-document-icon.svg'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { TextField } from '@mui/material'

const AddModalCategory = ({ visible, closeModal, deptId, iseditObj }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (values) => {
    if (iseditObj) {
      let payload = {
        department_id: deptId,
        label: values.name,
      }
      try {
        setIsLoading(true)
        const result = await axiosPUT(`category/${iseditObj.id}`, payload)
        if (result?.detail) {
          setIsLoading(false)
          toast('Category already exist', 'error')
        } else {
          setIsLoading(false)
          closeModal()
        }
      } catch (err) {
        setIsLoading(false)
        toast('Something went wrong !!', 'error')
      }
    } else {
      let payload = {
        department_id: deptId,
        label: values.name,
      }
      try {
        setIsLoading(true)
        const result = await axiosPOST('category/', payload)
        if (result?.data?.id) {
          setIsLoading(false)
          closeModal()
        } else if (result.status === 422) {
          setIsLoading(false)
          toast('Category already exist', 'error')
        }
      } catch (err) {
        setIsLoading(false)
        toast('Something went wrong !!', 'error')
      }
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(50, 'Maximum 50 characters allowed'),
  })
  return (
    <>
      <CustomModal
        isCustomBoard={true}
        visible={visible}
        closeModal={closeModal}
        modalSize="md"
        noMinHeight={true}
        headerText={iseditObj ? 'Edit category' : 'Add category'}
      >
        <Formik
          initialValues={{
            name: iseditObj ? iseditObj.label : '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
            <Form>
              <CRow className="mb-3">
                <CCol md={12} className="ps-lg-2">
                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Name"
                        fullWidth
                        defaultValue={values?.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      <ErrorMessage name="name" component="div" className="error" />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <div className="d-flex justify-content-end">
                <button type="button" onClick={closeModal} className="btn btn-secondary">
                  Cancel
                </button>
                <button
                  disabled={isSubmitting|| !(values?.name?.trim())}
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit(values) // Prevent default form submission
                  }}
                  // type='submit'
                  style={{ marginLeft: '8px' }}
                >
                  {isLoading && <CSpinner size="sm" style={{ marginRight: '8px' }}></CSpinner>}{' '}
                  {iseditObj ? 'Save' : 'Add'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  )
}

AddModalCategory.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  // loadWordList: PropTypes.func,
  isEdit: PropTypes.bool,
  editData: PropTypes.array,
}

export default AddModalCategory
