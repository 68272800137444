import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
import { Configuration } from 'src/utility/configuration'
import { toast } from '../../helpers/toast'
import { logout } from '../auth/actions'

export const ActionTypes = {
  LOADER_START: 'LOADER_START',
  LOADER_STOP: 'LOADER_STOP',
  SET_DEPARTMENT: 'SET_DEPARTMENT',
}
export const getDepartmentList = (navigate, count, page = 1, search = '', id,userRole) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOADER_START })
    let apiEndpoint = '';

    // Determine the API endpoint based on user type
    switch (userRole?.user_type) {
      case 1:
        apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.department.list}/?record_count=${count}&page=${page}&search=${search}&org_id=${id}`;
        break;
      case 2:
        apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.department.list}/?record_count=${count}&page=${page}&search=${search}&org_id=${id}`;
        break;
      case 3:
        apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.department.list}/user/?record_count=${count}&page=${page}&search=${search}&user_id=${userRole?.id}`;
        break;
      default:
        dispatch({ type: ActionTypes.LOADER_STOP });
        return; // Exit if no valid user type is provided
    }
    axios
      .get(apiEndpoint,
        {
          headers: getHeaders(),
        },
      )
      .then((response) => {
        dispatch({
          type: ActionTypes.SET_DEPARTMENT,
          payload: response.data,
        })

        dispatch({ type: ActionTypes.LOADER_STOP })
      })
      .catch((error) => {
        if (error?.response?.request?.status === 401) {
          dispatch(logout())
        }
        dispatch({ type: ActionTypes.LOADER_STOP })
      })
  }
}
