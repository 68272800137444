/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { CRow, CCol, CFormInput,CFormLabel } from '@coreui/react'
import PropTypes from 'prop-types'
import { toast } from 'src/helpers/toast'
import { useNavigate } from 'react-router-dom'
import { documentUpload } from 'src/redux/services/documentUpload'
import { useDispatch, useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'
import LoaderImg from '../../assets/images/avatars/loader.gif'
import { getDataSetCategoryList } from '../../redux/dataSetCategory/actions'
import * as Yup from 'yup'
import CustomModal from '../../components/CustomModal'
import { siteMap } from 'src/redux/services/siteMap'
import AddUrl from './AddUrl'
import addDoc from '../../assets/images/avatars/add-document-icon.svg'
import { getDocumentList } from 'src/redux/documentManagment/actions'
import { getOrganizationList } from 'src/redux/organization/actions'
import { getDepartmentList } from 'src/redux/department/actions'
import { getCategoryList } from 'src/redux/category/actions'
import { Autocomplete, TextField } from '@mui/material'
import addAudioImg from '../../assets/adminBoard/audioImg.svg'
import xmlImg from '../../assets/adminBoard/xml.svg'
import folderAdd from '../../assets/adminBoard/folder-add.svg'

const AddModal = ({
  visible,
  closeModal,
  isEdit,
  editData,
  setShowProgressbar,
  setUploadPercentage,
  setUploadingCategory,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [docFile, setDocFile] = useState([])
  const [loading, setLoading] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [fileError, setFileError] = useState('')
  const [urlFile, setUrlFile] = useState([])
  const [fileType, setFileType] = useState('doc')
  const [screen, setScreen] = useState(1)
  const inputRef = useRef(null)
  const prevTemperature = !Number.isNaN(parseFloat(editData?.temperature))
  ? parseFloat(editData.temperature)
  : 0
  const [tempatureVal, setTempatureVal] = useState(prevTemperature)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [formErrorObj, setFormErrorObj] = useState(undefined)
  const [sourceType, setSourceType] = useState('audio')
  const [sitemapUrl, setSiteMapUrl] = useState('')
  const [isAddButtonDisable, setIsAddButtonDisable] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const departmentSelector = useSelector((state) => state.department)
  const organizationSelector = useSelector((state) => state.organization)
  const categorySelector = useSelector((state) => state.category)
  const [organizationArr, setOrganizationArr] = useState([])
  const [departmentArr, setDepartmentArr] = useState([])
  const [categoriesArr, setCategoriesArr] = useState([])
  const userRole = useSelector((state) => state.auth.userData?.user_type)
  const orgId = useSelector((state) => state.auth.userData?.organization_id)
  const department_id = useSelector((state) => state.auth.userData?.department_id)
  const authState = useSelector((state) => state.auth);
  useEffect(() => {
    if (userRole === 1) {
      dispatch(getOrganizationList(navigate, 10, 1, ''))
      const transformedData = organizationSelector?.data?.data?.map((item) => ({
        label: item.name,
        id: item.id,
      }))
      setOrganizationArr(transformedData)
    }
    if (userRole === 2) {
      dispatch(getDepartmentList(navigate, 10, 1, '', orgId,authState?.userData))
      const transformedData = departmentSelector?.data?.data?.map((item) => ({
        label: item.name,
        id: item.id,
      }))
      setDepartmentArr(transformedData)
    }
    if (userRole === 3) {
      // dispatch(getCategoryList(navigate, 10, 1, '', department_id))
      dispatch(getDepartmentList(navigate, 10, 1, '', orgId,authState?.userData))
       const transformedData = categorySelector?.data?.data?.length > 0
    ? categorySelector.data.data.map((item) => ({
        label: item.label,
        id: item.id,
      }))
    : [];
      setCategoriesArr(transformedData)
    }
  }, [])
  
  const handleOrganizationChange = (id) => {
    setSelectedOrganization(id)
    setSelectedDepartment('')
    setSelectedCategory('')
    setDepartmentArr([])
    setCategoriesArr([])
  }
  
  const handleDepartmentChange = (id) => {
    setSelectedDepartment(id)
    setSelectedCategory('')
  }
  
  const handleCategoryChange = (id) => {
    setSelectedCategory(id)
    setUploadingCategory(id)
  }
  
  useEffect(() => {
    setDepartmentArr([])
    if (selectedOrganization) {
      // setDepartmentArr([])
      dispatch(getDepartmentList(navigate, 10, 1, '', selectedOrganization,authState?.userData))
      setDepartmentArr([])
    }
  }, [selectedOrganization])
  
  useEffect(() => {
    const transformedData = departmentSelector?.data?.data?.map((item) => ({
      label: item.name,
      id: item.id,
    }))
    setDepartmentArr(transformedData)
  }, [departmentSelector])
  useEffect(() => {
    if (selectedDepartment) {
      dispatch(getCategoryList(navigate, 10, 1, '', selectedDepartment))
      setCategoriesArr([])
    }
  }, [selectedDepartment])
  
  useEffect(() => {
    const transformedData = categorySelector?.data?.data?.length > 0
    ? categorySelector.data.data.map((item) => ({
        label: item.label,
        id: item.id,
      }))
    : [];
    setCategoriesArr(transformedData)
  }, [categorySelector])
  console.log("categorySelector",categorySelector?.data?.data)
  useEffect(() => {
    if (isEdit) {
      setTempatureVal(prevTemperature)
    }
    // eslint-disable-next-line
  }, [isEdit])
  
  // useEffect(() => {
  //   dispatch(getDataSetCategoryList(navigate))
  // }, [])
  
  /**
   * Validate form
  *
  * @param {object} inputObj Input object
  * @param {string} inputObj.docCategoryId Document category id
  * @returns {void}
  */
 const validateForm = async (inputObj) => {
   let output = undefined
   let validateSchema = undefined
   let errorMsg = ''
   
   try {
     validateSchema = Yup.object().shape({
       docCategoryId: Yup.string().required('This field is required'),
      })
      
      validateSchema.validateSync({ docCategoryId: inputObj?.docCategoryId })
    } catch (errorObj) {
      errorMsg = typeof errorObj?.message === 'string' ? errorObj.message : 'Error'
      output = { ...output, docCategoryId: errorMsg }
    }
    
    setFormErrorObj(output)
    if (typeof output === 'undefined') {
      return true
    }
    
    return false
  }
  
  const documentApi = async () => {
    let fileObj
    const form_data = new FormData()
    const files = docFile ? docFile : []
    
    for (fileObj of files) {
      form_data.append('files', fileObj)
    }
    
    // form_data.append('temperature', tempatureVal)
    form_data.append('category_id', selectedCategory)
    form_data.append("user_id",authState?.userData?.id)
    
    setLoading(true)
    
    setIsAddButtonDisable(true)
    setShowProgressbar(true)
    setTimeout(() => {
      closeModal()
      setDepartmentArr([])
      setCategoriesArr([])
      setSelectedOrganization('')
    }, 800)
    // setTimeout(() => {
      //   dispatch(getDocumentList(userRole, orgId, department_id, 1, ''))
      // }, 3000)
      try {
        await documentUpload(form_data, setUploadPercentage).then((res) => {
          if (res) {
            toast(res.message, 'success')
            if (files.length > 0) {
              setIsFileUploading(false)
            }
            setTempatureVal(0)
            setIsUpload(!isUpload)
            closeModal()
            setShowProgressbar(false)
            // dispatch(getDocumentList(userRole, orgId, department_id, 1, '',authState?.userData?.id))
            setLoading(false)
            setDepartmentArr([])
            setCategoriesArr([])
            setSelectedOrganization('')
          } else if (typeof res?.message === 'string' && res.message.trim() !== '') {
            // toast(res.message, 'success')
            if (files.length > 0) {
              setIsFileUploading(true)
            }
            setIsUpload(!isUpload)
            setLoading(false)
            setTempatureVal(0)
            closeModal()
            setDepartmentArr([])
            setCategoriesArr([])
            setSelectedOrganization('')
            // dispatch(getDocumentList(userRole, orgId, department_id, 1, '',authState?.userData?.id))
            setShowProgressbar(false)
          } else if (res?.status === 422) {
            toast.error('Failed to add.')
            setIsFileUploading(false)
            setShowProgressbar(false)
          } else if (res?.status === 401) {
            toast.error('Something went wrong, Please try again later.')
          setIsFileUploading(false)
          setShowProgressbar(false)
        } else {
          toast('Document upload failed', 'error')
          setIsFileUploading(false)
          setShowProgressbar(false)
        }
      })
    } catch (err) {
      setShowProgressbar(false)
      toast.error('Document upload failed')
    }
  }
  
  const urlApi = async () => {
    if (sourceType !== 'audio' && sourceType !== 'gdrive') {
      if (!(sitemapUrl.includes('http') || sitemapUrl.includes('https'))) {
        setFileError('Enter a valid URL')
        return
      }
    }
    setLoading(true)
    setIsAddButtonDisable(true)
    setTimeout(() => {
      closeModal()
      setDepartmentArr([])
      setCategoriesArr([])
      setSelectedOrganization('')
      dispatch(getDocumentList(userRole, orgId, department_id, 1, '','',authState?.userData?.id))
      if (sourceType === 'audio') {
        setShowProgressbar(true)
      }
    }, 800)
    
    try {
      setIsAddButtonDisable(true)
      const res = await siteMap(
        selectedCategory,
        setUploadPercentage,
        sitemapUrl,
        sourceType,
        urlFile[0],
        '',
        authState?.userData?.id,
      )
      
      if (res?.success) {
        toast(res?.message, 'success')
        closeModal()
        setDepartmentArr([])
        setCategoriesArr([])
        setSelectedOrganization('')
        dispatch(getDocumentList(userRole, orgId, department_id, 1, '','',authState?.userData?.id))
        setShowProgressbar(false)
        
        setLoading(false)
        setIsAddButtonDisable(false)
      } else {
        setIsAddButtonDisable(false)
        dispatch(getDocumentList(userRole, orgId, department_id, 1, '','',authState?.userData?.id))
        setShowProgressbar(false)
        
        toast(res?.message, 'error')
        setLoading(false)
      }
    } catch (error) {
      setIsAddButtonDisable(false)
      
      setShowProgressbar(false)
      
      console.error('Error:', error)
      toast.error('An error occurred')
    }
  }
  
  const handleSubmit = async () => {
    setIsAddButtonDisable(true)
    
    if (fileType === 'doc') {
      documentApi()
    } else if (fileType === 'url') {
      urlApi()
    }
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 2px gray' // Highlight the drop area when dragging over
  }
  
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 1px white' // Restore the border when leaving
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.currentTarget.style.border = 'dashed 1px white' // Restore the border
    
    const droppedFiles = e.dataTransfer.files
    processDroppedFiles(droppedFiles)
  }
  
  const processDroppedFiles = (files) => {
    // Handle dropped files similar to the file input's onChange event
    let errMsg = ''
    let totalFileSize = 0
    const acceptTypes = ['pdf', 'docx']
    let newFiles = docFile ? docFile.slice() : []
    
    for (let i = 0; i < files.length; i++) {
      const fileObj = files[i]
      const filePortions = fileObj.name.split('.')
      const fileExtension = filePortions.pop().toLowerCase()
      
      if (!acceptTypes.includes(fileExtension)) {
        errMsg = 'Please upload either pdf, docx,zip,csv and doc'
        break
      }
      
      if (newFiles.length + 1 > 5) {
        errMsg = 'Only 5 files accepted'
        newFiles = []
        break
      }
      
      if (!Number.isNaN(parseFloat(fileObj.size))) {
        totalFileSize += parseFloat(fileObj.size)
      }
      
      if (totalFileSize > 100 * 1024 * 1024) {
        errMsg = 'Please select a file smaller than 100 MB'
        break
      }
      
      newFiles.push(fileObj)
    }
    
    if (errMsg.trim() === '') {
      setFileError('')
      setDocFile(newFiles)
    } else {
      setFileError(errMsg)
    }
  }
  
  useEffect(() => {
    if (docFile?.length === 0 && urlFile?.length === 0 && sitemapUrl?.length === 0) {
      setIsAddButtonDisable(true)
    } else {
      setIsAddButtonDisable(false)
    }
  }, [docFile, urlFile, sitemapUrl])
  const audioRef = useRef()
  return (
    <>
      <CustomModal
        isCustomBoard={true}
        visible={visible}
        closeModal={closeModal}
        modalSize="md"
        headerText={'Add Document / URL'}
        cancelButtonFn={(event) => {
          event.preventDefault()
          closeModal()
          setDepartmentArr([])
          setCategoriesArr([])
          setSelectedOrganization('')
        }}
        >
        <div>
          {screen === 1 && (
            <>
              <div className="d-flex mb-3">
                <div className="form-check" style={{ marginRight: '16px', cursor: 'pointer' }}>
                  <input
                    className="form-check-input"
                    style={{ border: '1px solid white' }}
                    type="radio"
                    name="fileType"
                    onChange={() => {
                      setFileType('doc')
                      setUrlFile([])
                      setFileError('')
                      setSourceType('audio')
                    }}
                    value="document"
                    id="flexRadioDefault3"
                    // defaultChecked
                    checked={fileType === 'doc'}
                  />
                  <label className="form-check-label">Document</label>
                </div>
                <div className="form-check" style={{ marginRight: '16px' }}>
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                style={{ border: '1px solid white' }}
                onChange={() => {
                  setSourceType('audio')
                  setUrlFile([])
                  setSiteMapUrl('')
                  setFileType('url')
                  setFileError('')
                }}
                value="audio"
                id="flexRadioDefault1"
                checked={fileType === 'url'&&sourceType=="audio"}
              />
              <label className="form-check-label">Audio</label>
            </div>
            <div className="form-check" style={{ marginRight: '16px' }}>
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                style={{ border: '1px solid white' }}
                onChange={() => {
                  setSourceType('video')
                  setUrlFile([])
                  setSiteMapUrl('')
                  setFileError('')
                  setFileType('url')
                }}
                value="video"
                id="flexRadioDefault2"
                checked={fileType === 'url'&&sourceType=="video"}
              />
              <label className="form-check-label">Video</label>
            </div>
                <div>


      
      {/* </CRow> */}
    </div>
                {/* <div className="form-check" style={{ marginRight: '16px' }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="fileType"
                    style={{ border: '1px solid white' }}
                    onChange={() => {
                      setFileType('url')
                      setDocFile([])
                      setFileError('')
                      setSourceType('audio')
                    }}
                    value="urls"
                    id="flexRadioDefault1"
                    checked={fileType === 'url'}
                  />
                  <label className="form-check-label">URL</label>
                </div> */}
              </div>

              {fileType === 'doc' && (
                <>
                  <CRow className="mb-3">
                    <CCol md={12} className="ps-lg-2">
                      <div className="mt-5">
                        {/* <CFormLabel>Document</CFormLabel> */}
                        <div
                          className="d-grid justify-content-center mb-4 pt-4 pb-2"
                          style={{
                            cursor: 'pointer',
                            border: 'dashed 1px white',
                            borderRadius: '18px',
                          }}
                          onClick={() => inputRef.current.click()}
                          onDragOver={(e) => handleDragOver(e)}
                          onDragLeave={(e) => handleDragLeave(e)}
                          onDrop={(e) => handleDrop(e)}
                        >
                          <div className="d-grid justify-content-center">
                            <img src={addDoc} alt="doc-image" />
                          </div>
                          <p className="mt-3 px-3">
                            Drag and drop files here, or click to select files.
                          </p>
                        </div>

                        <CFormInput
                          ref={inputRef}
                          style={{ display: 'none' }}
                          required
                          className="mb-2"
                          placeholder="Upload file"
                          name="doc"
                          type="file"
                          multiple={true}
                          accept=".pdf,.docx,.zip,.doc,.csv"
                          onChange={(event) => {
                            event.preventDefault()
                            let prevFileObj
                            let errMsg = ''
                            let fileObj
                            let totalFileSize = 0
                            let filePortions = []
                            const acceptTypes = ['pdf', 'docx']
                            let files = docFile ? docFile : []
                            for (let i = 0; i < event?.target?.files.length; i++) {
                              fileObj = event?.target?.files?.[i]

                              filePortions = (
                                typeof fileObj?.name === 'string' ? fileObj.name : ''
                              ).split('.')
                              filePortions = filePortions.pop()
                              filePortions = (
                                typeof filePortions === 'string' ? filePortions : ''
                              ).toLowerCase()
                              if (!acceptTypes.includes(filePortions)) {
                                errMsg = 'Please upload either pdf, docx'
                              }
                              console.warn("hello man its working",!acceptTypes.includes(filePortions))

                              // if (files.length + 1 > 5) {
                              //   errMsg = 'Only 5 files accepted'
                              //   files = []
                              // }

                              for (prevFileObj of files) {
                                if (!Number.isNaN(parseFloat(prevFileObj?.size))) {
                                  totalFileSize += parseFloat(prevFileObj?.size)
                                }
                              }

                              if (!Number.isNaN(parseFloat(fileObj?.size))) {
                                totalFileSize += parseFloat(fileObj?.size)
                              }

                              // if (errMsg.trim() === '' && totalFileSize > 5000000) {
                              if (errMsg.trim() === '' && totalFileSize > 100 * 1024 * 1024) {
                                errMsg = 'Please select a file smaller than 100 MB'
                              }

                              if (typeof errMsg === 'string' && errMsg.trim() !== '') {
                                setFileError(errMsg)
                              } else {
                                setFileError('')
                                setDocFile((state) => {
                                  return [...state, event?.target?.files?.[i]]
                                })
                              }
                            }
                          }}
                        />
                        <span style={{ color: 'red' }}>{fileError}</span>

                        <p style={{ fontSize: '14px', color: 'gray' }}>
                          Please ensure that your file is in either .pdf or .docx format. Maximum
                          of the total file should be 100 MB. A user can only upload one file.
                        </p>
                      </div>
                    </CCol>
                  </CRow>
                  {docFile.length > 0 ? (
                    <>
                      {docFile.map((itemObj, itemIndex) => {
                        return (
                          <>
                            <CRow className="my-2" key={'documentUploadRow' + itemIndex}>
                              <CCol md={10} className="ps-lg-2 text-truncate">
                                {typeof itemObj?.name === 'string'
                                  ? `${itemObj.name} (${parseInt(itemObj.size / 1024)} KBB)`
                                  : ''}
                              </CCol>
                              <CCol
                                md={2}
                                className="d-flex justify-content-md-end justify-content-sm-start"
                              >
                                <span
                                  className={`${isFileUploading ? 'd-none' : ''}`}
                                  onClick={(event) => {
                                    event.preventDefault()
                                    let prevFiles = [...docFile]
                                    prevFiles.splice(itemIndex, 1)
                                    setDocFile(prevFiles)
                                  }}
                                >
                                  <CIcon
                                    icon={cilTrash}
                                    color="danger"
                                    size="lg"
                                    title="Delete"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>
                                <span
                                  className={`${
                                    !isFileUploading ? 'd-none' : ''
                                  } d-flex justify-content-md-end justify-content-sm-start`}
                                >
                                  <img src={LoaderImg} alt="Loader" className="w-50" />
                                </span>
                              </CCol>
                            </CRow>
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {fileType === 'url' && (
                <>
                  {/* <AddUrl
                    sourceType={sourceType}
                    sitemapUrl={sitemapUrl}
                    setSiteMapUrl={setSiteMapUrl}
                    setSourceType={setSourceType}
                    setIsAddButtonDisable={setIsAddButtonDisable}
                    setUrlFile={setUrlFile}
                    urlFile={urlFile}
                    setFileError={setFileError}
                    fileError={fileError}
                    fileType={fileType}
                    setFileType={setFileType}
                  /> */}
                     <div>
            <CFormLabel className="mt-2">
              {sourceType === 'sitemap'
                ? 'Sitemap URL'
                : sourceType === 'video'
                  ? 'Video URL' :
                  sourceType === 'gdrive' ?
                    'Google Drive Folder Id'
                    : 'Audio File'}
            </CFormLabel>

            {sourceType === 'audio' ? (
              <>
                <div
                  className="d-grid justify-content-center mb-4 pt-4 pb-2"
                  style={{
                    cursor: 'pointer',
                    border: 'dashed 1px white',
                    borderRadius: '18px',
                  }}
                  onClick={() => {
                    audioRef.current.click()
                  }}
                >
                  <div className="d-grid justify-content-center">
                    <img src={addAudioImg} />
                  </div>
                  <p className="mt-3">Choose an Audio File.</p>
                </div>

                <CFormInput
                  ref={audioRef}
                  style={{ display: 'none' }}
                  required
                  className="mb-2"
                  name="audio"
                  type="file"
                  accept=".mp3"
                  onChange={(event) => {
                    event.preventDefault()
                    let prevFileObj
                    let errMsg = ''

                    let fileObj
                    let totalFileSize = 0
                    let filePortions = []
                    const acceptTypes = ['mp3']
                    // let files = urlFile ? urlFile : []

                    fileObj = event?.target?.files?.[0]

                    filePortions = (typeof fileObj?.name === 'string' ? fileObj.name : '').split(
                      '.',
                    )
                    filePortions = filePortions.pop()
                    filePortions = (
                      typeof filePortions === 'string' ? filePortions : ''
                    ).toLowerCase()
                    if (!acceptTypes.includes(filePortions)) {
                      errMsg = 'Please upload an mp3 file'
                      setIsAddButtonDisable(true)
                      setUrlFile([])
                    }

                    // if (files.length + 1 > 5) {
                    //   errMsg = 'Only 5 files accepted'
                    //   files = []
                    // }

                    // for (prevFileObj of files) {
                    //   if (!Number.isNaN(parseFloat(prevFileObj?.size))) {
                    //     totalFileSize += parseFloat(prevFileObj?.size)
                    //   }
                    // }

                    // if (!Number.isNaN(parseFloat(fileObj?.size))) {
                    //   totalFileSize += parseFloat(fileObj?.size)
                    // }

                    if (errMsg.trim() === '' && totalFileSize > 5000000) {
                      errMsg = 'Please select a file smaller than 5 MB'
                    }

                    if (typeof errMsg === 'string' && errMsg.trim() !== '') {
                      setFileError(errMsg)
                    } else {
                      setFileError('')
                      let obj = []
                      obj.push(event?.target?.files?.[0])
                      setUrlFile(obj)
                      setIsAddButtonDisable(false)
                    }
                  }}

                // onChange={(event) => {
                //   setAudioFile(event?.target?.files?.[0])
                // }}
                />
              </>
            ) : (
              <div
                className="mb-4 pt-4 pb-2"
                style={{
                  cursor: 'pointer',
                  border: 'dashed 1px white',
                  borderRadius: '18px',
                }}
              >
                <div className="d-grid justify-content-center mb-4 pt-2 pb-2">
                 {sourceType === 'gdrive' ? <img src={folderAdd} /> : <img src={xmlImg} />} 
                </div>
                <div className="px-2">
                  <CFormInput
                    required
                    className="cstm-textbox"
                    placeholder="Enter URL"
                    name="url"
                    type="text"
                    onChange={(event) => {
                      setSiteMapUrl(event.target.value)
                      setFileError(null)
                    }}
                  />
                </div>
              </div>
            )}
            {/* <span style={{ color: 'red' }}>{sitemapUrlError}</span> */}
            <span style={{ color: 'red' }}>{fileError}</span>
          </div>
                  {urlFile.length > 0 ? (
                    <>
                      {urlFile.map((itemObj, itemIndex) => {
                        return (
                          <>
                            <CRow className="my-2" key={'documentUploadRow' + itemIndex}>
                              <CCol md={10} className="ps-lg-2 text-truncate">
                                {typeof itemObj?.name === 'string' ? itemObj?.name : ''}
                              </CCol>
                              <CCol
                                md={2}
                                className="d-flex justify-content-md-end justify-content-sm-start"
                              >
                                <span
                                  className={`${isFileUploading ? 'd-none' : ''}`}
                                  onClick={(event) => {
                                    event.preventDefault()
                                    let prevFiles = [...urlFile]
                                    prevFiles.splice(itemIndex, 1)
                                    setUrlFile(prevFiles)
                                  }}
                                >
                                  <CIcon
                                    icon={cilTrash}
                                    color="danger"
                                    size="lg"
                                    title="Delete"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>
                                <span
                                  // eslint-disable-next-line prettier/prettier
                                  className={`${
                                    !isFileUploading ? 'd-none' : ''
                                  } d-flex justify-content-md-end justify-content-sm-start`}
                                >
                                  <img src={LoaderImg} alt="Loader" className="w-50" />
                                </span>
                              </CCol>
                            </CRow>
                          </>
                        )
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </>
               )} 
            </>
          )}

          {screen === 2 && (
            <>
              <div style={{ minHeight: '348px' }}>
                {userRole === 1 && (
                  <div className="mb-3">
                    <Autocomplete
                      options={organizationArr || []}
                      fullWidth
                      className="form-select form-control mb-4"
                      onChange={(e, val) => {
                        handleOrganizationChange(val?.id)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            '.MuiOutlinedInput-input': {
                              background: '#0b1f0b !important',
                              borderRadius: '14px !important',
                              border: '0px !important',
                              padding: '16px 14px 12px 14px !important',
                            },
                          }}
                          label="Select Organization"
                        />
                      )}
                      PaperComponent={({ children }) => (
                        <div
                          style={{
                            border: '1px solid #285624',
                            background: '#0b1f0b',
                            borderRadius: '0 0 20px 20px',
                            color: '#fff',
                            width: '440px',
                            borderTop: '0',
                            // borderTopRightRadius: "0",
                            // borderTopLeftRadius: "0",
                            marginTop: '10px',
                            maxHeight: '280px',
                            overflowY: 'auto',
                          }}
                        >
                          {children}
                        </div>
                      )}
                    />
                  </div>
                )}

                {(userRole === 1 || userRole === 2 || userRole===3) && (
                  <div className="mb-3">
                    <Autocomplete
                      options={departmentArr || []}
                      fullWidth
                      className="form-select form-control mb-4"
                      onChange={(e, val) => {
                        handleDepartmentChange(val?.id)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            '.MuiOutlinedInput-input': {
                              background: '#0b1f0b !important',
                              borderRadius: '14px !important',
                              border: '0px !important',
                              padding: '16px 14px 12px 14px !important',
                            },
                          }}
                          label="Select Department"
                        />
                      )}
                      PaperComponent={({ children }) => (
                        <div
                          style={{
                            border: '1px solid #285624',
                            background: '#0b1f0b',
                            borderRadius: '0 0 20px 20px',
                            color: '#fff',
                            width: '440px',
                            borderTop: '0',
                            // borderTopRightRadius: "0",
                            // borderTopLeftRadius: "0",
                            marginTop: '10px',
                            maxHeight: '280px',
                            overflowY: 'auto',
                          }}
                        >
                          {children}
                        </div>
                      )}
                    />
                  </div>
                )}

                <div className="mb-3">
                  <Autocomplete
                    options={categoriesArr || []}
                    fullWidth
                    className="form-select form-control mb-4"
                    onChange={(e, val) => {
                      handleCategoryChange(val?.id)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          '.MuiOutlinedInput-input': {
                            background: '#0b1f0b !important',
                            borderRadius: '14px !important',
                            border: '0px !important',
                            padding: '16px 14px 12px 14px !important',
                          },
                        }}
                        label="Select Category"
                      />
                    )}
                    PaperComponent={({ children }) => (
                      <div
                        style={{
                          border: '1px solid #285624',
                          background: '#0b1f0b',
                          borderRadius: '0 0 20px 20px',
                          color: '#fff',
                          width: '440px',
                          borderTop: '0',
                          // borderTopRightRadius: "0",
                          // borderTopLeftRadius: "0",
                          marginTop: '10px',
                          maxHeight: '280px',
                          overflowY: 'auto',
                        }}
                      >
                        {children}
                      </div>
                    )}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={() => {
              closeModal()
              setDepartmentArr([])
              setCategoriesArr([])
              setSelectedOrganization('')
            }}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          {screen === 1 ? (
            <button
              disabled={isAddButtonDisable}
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault()
                setScreen(2)
              }}
              style={{ marginLeft: '8px' }}
            >
              Next
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault()
                handleSubmit(e) // Prevent default form submission
              }}
              // type='submit'
              style={{ marginLeft: '8px' }}
            >
              Add
            </button>
          )}
        </div>
      </CustomModal>
    </>
  )
}

AddModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  // loadWordList: PropTypes.func,
  isEdit: PropTypes.bool,
  editData: PropTypes.array,
}

export default AddModal
