import { store } from 'src/redux/store'

export const getHeaders = () => {
  const token = store.getState().auth?.userData?.token || ''
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
}

export const getFormHeaders = () => {
  const token = store.getState().auth?.userData?.token || ''

  return {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${token}`,
  }
}

export function capitalizeFirstLetter(string = '') {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)
}

/**
 * Get server error message
 */
export const getServerErrorMsg = (errorObj) => {
  if (typeof errorObj?.data?.detail === 'string') {
    return errorObj.data.detail
  } else if (typeof errorObj?.error === 'string') {
    return errorObj.error
  } else if (typeof errorObj === 'string') {
    return errorObj
  }

  const strArr1 = Array.isArray(errorObj?.data?.detail) ? errorObj.data.detail : []
  if (strArr1.length < 1) {
    return ''
  }

  let str1 = ''
  for (let i = 0; i < strArr1.length; i++) {
    str1 = (i > 0 ? ', ' : '') + (typeof strArr1?.[i]?.msg === 'string' ? strArr1[i].msg : '')
  }

  return str1
}
