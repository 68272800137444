/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import chatImage from '../../assets/adminBoard/chat.svg'
import closeChatImage from '../../assets/adminBoard/close-chat.svg'
import trashImage from '../../assets/adminBoard/trash.svg'
import uploaddImg from '../../assets/adminBoard/uploadd.svg'
import pdfImage from '../../assets/adminBoard/pdf.svg'
import docImage from '../../assets/adminBoard/DOCUMENT.svg'
import mp3Image from '../../assets/adminBoard/mp3.svg'
import xmlImage from '../../assets/adminBoard/xmlIcon.svg'
import uploadImage from '../../assets/adminBoard/upload.svg'
import urlImage from '../../assets/adminBoard/doc.svg'
import pulse from '../../assets/adminBoard/pulse.svg'
import AddModal from './AddModal'
import { useNavigate } from 'react-router-dom'
import { getDocumentList } from 'src/redux/documentManagment/actions'
import { useDispatch, useSelector } from 'react-redux'
import ConfirmDelete from 'src/components/ConfirmDelete'
import { axiosDELETE } from 'src/utility/apis'
import { toast } from 'react-toastify'
import { Configuration } from 'src/utility/configuration'
import { logout } from 'src/redux/auth/actions'
import Chat from './chatComponents/chat'
import { clearDocumentEditListData, getDocumentEditList } from 'src/redux/documentEdit/actions'
import TranscriptModal from './TranscriptModal'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle, cilPen, cilSave } from '@coreui/icons'
import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
import { organization } from 'src/redux/organization/reducer'
import { Paginate } from 'src/components'
import NoDataImage from '../../assets/adminBoard/nodata.svg'
import { makeStyles } from '@mui/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { ExpandMore, FilterAlt, Search } from '@mui/icons-material'
import { getOrganizationList } from 'src/redux/organization/actions'
import { saveId, saveTitle } from 'src/redux/historyManagment/actions'
import { clearHistory } from 'src/redux/historyManagment/actions'
import { clearMessages } from 'src/redux/message/action'
import { flushSync } from 'react-dom';
import uuid from 'react-uuid'

let isEditLabel = false
export default function CustomBoard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [allDocs, setAllDocs] = useState([])
  const { isLoading, data } = useSelector((state) => state.documentList)
  const [isDocModalVisible, setIsDocModalVisible] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const [loading, setLoading] = useState(null)
  const { selectedCategory } = useSelector((state) => state.chatHistory)
  const { categoryTitleName } = useSelector((state) => state.chatHistory)
  const [categoryId, setCategoryId] = useState(selectedCategory)
  const [categoryTitle, setCategoryTitle] = useState(categoryTitleName)
  const [showProgressbar, setShowProgressbar] = useState(false)
  const [uploadingCategory, setUploadingCategory] = useState(null)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [transcriptModalVisible, setTranscriptModalVisible] = useState(false)
  const [newLabel, setNewLabel] = useState(null)
  const [firstMessage, setFirstMessage] = useState(false)
  const [organizationArr, setOrganizationArr] = useState([])
  const [isSearchPanel, setIsSearchPanel] = useState(false)
  const [docCount, setDocCount] = useState(25)
  const [isDeleteButtonDisable, setIsDeleteButtonDisable] = useState(false)
  const { data: transcript, isLoading: isTranscriptLoading } = useSelector(
    (state) => state.documentEditList,
  )
  const userRole = useSelector((state) => state.auth.userData?.user_type)
  const orgId = useSelector((state) => state.auth.userData?.organization_id)
  const department_id = useSelector((state) => state.auth.userData?.department_id)
  const [documents, setDocuments] = useState([])
  const [expandChat, setExpandChat] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const debounceDelay = 300 // Set your desired debounce delay in milliseconds
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const organizationSelector = useSelector((state) => state.organization)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [isFullFilter, setIsFullFilter] = useState(false)
  const authState = useSelector((state) => state.auth);
  const useStyles = makeStyles({
    input: {
      '&:focus': {
        outline: 'none',
        border: '1px solid transparent',
      },
    },
    label: {
      transform: 'translate(14px, 12px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
  })
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const debounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func(...args), delay)
    }
  }
  const handleLoadMore = (length) => {
    if (docCount < length) {
      setDocCount((prev) => +prev + 50)
    }
  }

  useEffect(() => {
    dispatch(saveId(categoryId))
  }, [categoryId])
  useEffect(() => {
    dispatch(saveTitle(categoryTitle))
  }, [categoryTitle])
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSearch = debounce((query) => {
    setTimeout(() => {
      const results = dispatch(
        getDocumentList(userRole, orgId, department_id, currentPage, query, selectedFilter?.id,authState?.userData?.id),
      ) // Replace with your actual search function
    }, 500)
  }, debounceDelay)

  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value
    setSearchTerm(newSearchTerm)
    handleSearch(newSearchTerm)
  }


  useEffect(() => {
    if (documents?.length > 0) {
      let everyDoc = []
      documents?.map((itm) => {
        everyDoc.push(itm.documents)
      })
      let docs = everyDoc.flat()

      if (isEditLabel) {
        return
      } else {
        setTimeout(() => {
          let obj = docs?.filter((itm) => itm?.status === 'Processing')
          if (obj?.length > 0 && !showProgressbar && !isEditLabel) {
            getAllUploadedDocs()
          }
        }, 10000)
      }
    }
  }, [documents])

  const handlePageClick = (e) => {
    const selectedPage = e.selected + 1
    setCurrentPage(selectedPage)
    dispatch(getDocumentList(userRole, orgId, department_id, selectedPage, searchTerm,'',authState?.userData?.id))
  }

  // useEffect(() => {
  //   if (data && documents?.length === 0 && !isEditLabel) {
  //     setDocuments(data?.data)
  //   } else {
  //     if (documents?.length > 0) {
  //       documents?.filter((obj) => obj?.isEditable === true)
  //       if (isEditLabel) {
  //         return
  //       } else {
  //         if (!showProgressbar && !isEditLabel) {
  //           setDocuments(data?.data)
  //         }
  //       }
  //     }
  //   }
  // }, [data])
  useEffect(()=>{
   if(data?.data) {
     setDocuments(data?.data)
   }
  },[data])

  const closeModal = () => {
    setIsDocModalVisible(false)
  }
  useEffect(() => {
    if (!showProgressbar && !isEditLabel) {
      getAllUploadedDocs()
    }
    if (uploadingCategory&&documents.length>0) {
      setExpandedAccordion(documents?.findIndex((item) => item.id === +uploadingCategory))
    }
  }, [showProgressbar])
  const getAllUploadedDocs = async () => {
    dispatch(getDocumentList(userRole, orgId, department_id, currentPage, searchTerm,'',authState?.userData?.id))
    setAllDocs(data)
  }

  const loadWordList = () => {
    dispatch(getDocumentList(userRole, orgId, department_id, currentPage, searchTerm,'',authState?.userData?.id))
  }

  const handleDeleteFile = (dId, cId) => {
    if (cId === categoryId) {
      setCategoryId(null)
    }
    setDeleteModal(true)
    setDeleteItem(dId)
  }

  const onDelete = async () => {
    setIsDeleteButtonDisable(true)

    await axiosDELETE(`${Configuration.documentManagement.document}${deleteItem}`)
      .then((data) => {
        setIsDeleteButtonDisable(false)
        if (data.status === 200) {
          loadWordList()
          setIsDeleteButtonDisable(false)
          const msgTxt = typeof data?.message === 'string' ? data.message : 'Deleted successfully!'
          toast.success(msgTxt)
        } else toast.error('Failed to delete document. Try again.')
      })

      .catch((error) => {
        setLoading(false)
        if (error?.response?.request?.status === 422) {
          toast.error('Failed to delete document. Try again.')
        } else if (error?.response?.request?.status === 401) {
          dispatch(logout())
          setIsDeleteButtonDisable(false)
        } else {
          toast.error('Something went wrong , Please try again later !!')
        }
      })
    setLoading(null)
    setDeleteModal(false)
    setDeleteItem(null)
    setIsDeleteButtonDisable(false)
  }
  const onConfirmCancel = () => {
    setDeleteModal(false)
    setDeleteItem(null)
  }

  const handleTranscript = (id) => {
    setTranscriptModalVisible(true)
    dispatch(getDocumentEditList(navigate, 1, id))
  }
  const handleEditLabel = (i, index) => {
    isEditLabel = true
    // setDocuments((prevDocList) => {
    //   const updatedDocList = [...prevDocList?.data]
    //   console.log("updated",updatedDocList);
    //   updatedDocList[i].documents[index].isEditable = true
    //   return updatedDocList
    // })
    setDocuments((prevDocList) => {
      const updatedDocList = prevDocList.data.map((category) => ({
        ...category, 
        documents: category.documents.map((doc) => ({ ...doc }))
      }));
      updatedDocList[i].documents[index].isEditable = true;
      return { ...prevDocList, data: updatedDocList };  
    });
  }
  const handleLabelSave = (itm, index, i, ext) => {
    // setCurrentEditId(cId)
    // const url = process.env.REACT_APP_API_ENDPOINT}/Configuration.datasetCategory.category + id

    const payload = {
      file_name: `${newLabel}${ext ? `.${ext}` : ''}`,
    }

    axios
      .put(
        `${process.env.REACT_APP_API_ENDPOINT}/${Configuration.documentManagement.document}${itm.id}`,

        payload,
        {
          headers: getHeaders(),
        },
      )
      .then((response) => {})
      .catch((error) => {
        // setCategoryErrorMessage(error?.response?.data?.detail[0]?.msg)
        return error.response
      })
    setNewLabel(null)
    // getAllUploadedDocs()
    // setDocuments((prevDocList) => {
    //   const updatedDocList = [...prevDocList]
    //   updatedDocList[i].documents[index].isEditable = false
    //   updatedDocList[i].documents[index].document_name = `${newLabel}${ext ? `.${ext}` : ''}`
    //   return updatedDocList
    // })
    setDocuments((prevDocList) => {
      const updatedDocList = prevDocList.data.map((category) => ({
        ...category, 
        documents: category.documents.map((doc) => ({ ...doc }))
      }));
      updatedDocList[i].documents[index].isEditable = false
      updatedDocList[i].documents[index].document_name = `${newLabel}${ext ? `.${ext}` : ''}`
      return { ...prevDocList, data: updatedDocList };  
    })
    isEditLabel = false
    getAllUploadedDocs();
  }
  const [expandedAccordion, setExpandedAccordion] = useState(0)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false)
    setDocCount(25)
  }

  /**
   * Handle clear history
   */
  function handleClearHistory() {
    dispatch(clearHistory())
    dispatch(clearMessages())
    setCategoryId(null)
    setCategoryTitle(null)
  }

  useEffect(() => {
    if (userRole === 1) {
      dispatch(getOrganizationList(navigate, 10, 1, ''))
      const transformedData = organizationSelector?.data?.data?.map((item) => ({
        label: item.name,
        id: item.id,
      }))
      setOrganizationArr(transformedData)
    }
  }, [])

  useEffect(() => {
    if (selectedFilter) {
      dispatch(
        getDocumentList(
          userRole,
          orgId,
          department_id,
          currentPage,
          searchTerm,
          selectedFilter?.id,
        ),
      )
    } 
    else {
      dispatch(getDocumentList(userRole, orgId, department_id, currentPage, searchTerm,'',authState?.userData?.id))
    }
  }, [selectedFilter])
console.log("uploadPercentage",uploadPercentage)
  return (
    <div className="content-area d-flex justify-content-between">
      <div className="upload-contents">
        <div className="breif mb-4">
          <h5>Knowledge base</h5>
          <p>
            Our Multimodal AI Knowledgebase enables AI systems to answer questions using textual
            documents, videos, and audio uploads, fostering natural language understanding and
            document-based AI advancements. It offers a versatile repository for a wide range of
            media formats, making it a valuable resource in the field.
          </p>
        </div>

        <div
          className="upload-here mb-4"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsDocModalVisible(true)}
        >
          <div className="items">
            <img src={uploadImage} alt="" />
            <h6 className="mb-1">Upload File here</h6>
            <p className="mb-0">(Documents, Audio, Video URL)</p>
          </div>
          {/* <input type="file" /> */}
        </div>

        <div>
          <div className="d-flex justify-content-end mb-4 align-items-center">
            <div
              className={'search-cstm'}
              style={{
                marginRight: '8px',
                width: isSearchPanel && '300px',
                border: !isSearchPanel && '0',
              }}
            >
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                className="search-cstm-text"
                // className={isSearchPanel ? "search-cstm-text-open" : "search-cstm-text"}
                onChange={handleInputChange}
              />
            </div>
            <IconButton onClick={() => setIsSearchPanel(!isSearchPanel)}>
              <Search />
            </IconButton>

            <Autocomplete
              className={'filter-cstm'}
              forcePopupIcon={isFullFilter}
              disableClearable={isFullFilter}
              sx={{
                marginRight: '8px',
                width: isFullFilter && '250px',
                border: !isFullFilter && '0',
              }}
              id="fixed-tags-demo"
              value={selectedFilter}
              onChange={(event, newValue) => setSelectedFilter(newValue)}
              options={organizationArr || []}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip key={option.id} label={option.label} {...getTagProps({ index })} />
                ))
              }
              // style={{ width: 250 }}
              renderInput={(params) =>
                isFullFilter && (
                  <TextField
                    {...params}
                    placeholder="Select Organization"
                    className={classes.input}
                    InputLabelProps={{
                      classes: {
                        root: classes.label,
                      },
                    }}
                    sx={{
                      '.MuiOutlinedInput-input': {
                        background: 'transparent !important',
                        borderRadius: '14px !important',
                        border: '0px !important',
                        padding: '16px 14px 12px 14px !important',
                        visibility: !isFullFilter && 'hidden',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: '40px',
                        background: 'transparent',
                        border: isFullFilter && '1px solid #285624',
                        padding: '6px 16px',
                        borderRadius: '20px',
                        color: '#fff',
                      },
                    }}
                  />
                )
              }
              PaperComponent={({ children }) => (
                <div
                  style={{
                    border: '1px solid #285624',
                    background: '#121d12',
                    borderRadius: '2px 2px 20px 20px',
                    color: '#fff',
                    borderTop: '0',
                    // borderTopRightRadius: "2px",
                    // borderTopLeftRadius: "2px",
                    width: '245px',
                  }}
                >
                  {children}
                </div>
              )}
            />

            {userRole === 1 && (
              <IconButton
                onClick={() => {
                  setIsFullFilter((prevIsFullFilter) => {
                    const newIsFullFilter = !prevIsFullFilter
                    if (!newIsFullFilter) {
                      setSelectedFilter(null)
                    }
                    return newIsFullFilter
                  })
                }}
              >
                <FilterAlt />
              </IconButton>
            )}
          </div>

          {isLoading && (
            <div className="upload-items uploded">
              <div className="uploaded-data d-flex">
                <div className="progress-sc me-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center mx-2">Loading </div>
                    <img src={pulse} alt="loader" width={36} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {data?.data?.total_count > 10 && (
            <div className="mt-4 d-flex justify-content-end">
              <Paginate
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                totalCount={data?.data?.total_count}
                limit={10}
              />
            </div>
          )}
          <div style={{display:documents?.data?.length === 0?'none':'block'}} className='wrapper-doc'>
          {!isLoading &&documents?.data?.length>0&&
            documents?.data?.map((itm, i) => (
              <div className="d-flex doc-container" key={itm?.id}>
                <Accordion
                  key={itm?.id}
                  expanded={expandedAccordion === i}
                  onChange={handleChange(i)}
                  style={{
                    background: '#121d12',
                    color: '#fff',
                    width: '92%',
                    margin: '0',
                    borderRadius: '18px',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore style={{ color: 'white' }} />}
                    aria-controls={`panel${i + 1}-content`}
                    id={`panel${i + 1}-header`}
                    color="white"
                  >
                    <Typography style={{ wordBreak: 'break-word' }}>
                      {itm?.category_name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className='d-flex gap-1 flex-column'>
                    {showProgressbar && itm?.id&& (
                      <div className="upload-items">
                        <div className="up-icon">
                          <img src={uploaddImg} alt="" />
                        </div>

                        <div className="uploaded-data d-flex">
                          <div className="progress-sc me-3">
                            <h6 className="mb-0">Uploading ...</h6>
                            <div className="progress mt-3" style={{ height: '5px' }}>
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${uploadPercentage}%`, background: '#4bc641' }}
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {itm?.documents?.slice(0, docCount)?.map((itm, index) => (
                      <div className="upload-items uploded" key={itm?.id}>
                        <div className="up-icon">
                          <img
                            src={
                              itm?.file_name?.slice(-4).includes('pdf')
                                ? pdfImage
                                : itm?.file_name?.slice(-4).includes('doc')
                                ? docImage
                                : itm?.file_name?.slice(-4).includes('xml')
                                ? xmlImage
                                : itm?.file_name?.slice(-4).includes('mp3')
                                ? mp3Image
                                : urlImage
                            }
                            alt=""
                          />
                        </div>
                        <div className="uploaded-data d-flex">
                          <div className="progress-sc me-3">
                            <h6 className="mb-0">
                              {itm?.isEditable === true ? (
                                <input
                                  onChange={(e) => {
                                    setNewLabel(e.target.value)
                                  }}
                                  defaultValue={itm?.file_name?.split('.')[0]}
                                  type="text"
                                  className="edit-input"
                                  style={{
                                    background: 'transparent',
                                    borderBottom: '1px solid white',
                                    borderTop: '0px',
                                    borderRight: '0px',
                                    borderLeft: '0px',
                                    color: '#fff',
                                  }}
                                />
                              ) : (
                                <span>{itm?.file_name}</span>
                              )}

                              {itm?.status === 'Completed' && (
                                <CIcon
                                  style={{
                                    marginLeft: '12px',
                                    cursor: newLabel?.length !== 0 && 'pointer',
                                    color: newLabel?.length === 0 && 'gray',
                                  }}
                                  icon={itm?.isEditable === true ? cilCheckCircle : cilPen}
                                  onClick={() => {
                                    if (newLabel?.length === 0) {
                                      //disable icon
                                    } else {
                                      newLabel?.length === 0 ||
                                        newLabel === undefined ||
                                        (newLabel === null && setNewLabel(itm?.file_name))
                                      itm?.isEditable === true
                                        ? handleLabelSave(
                                            itm,
                                            index,
                                            i,
                                            itm?.file_name?.split('.')[1],
                                          )
                                        : handleEditLabel(i, index, itm?.id)
                                    }
                                  }}
                                />
                              )}
{/* 
                              {itm?.status === 'Completed' &&
                                (itm?.file_name?.slice(-4).includes('pdf') ? (
                                  <span
                                    onClick={() => handleTranscript(itm?.id)}
                                    className="cstm-transcript"
                                  >
                                    view
                                  </span>
                                ) : itm?.file_name?.slice(-4).includes('doc') ? (
                                  <span
                                    onClick={() => handleTranscript(itm?.id)}
                                    className="cstm-transcript"
                                  >
                                    view
                                  </span>
                                ) : itm?.file_name?.slice(-4).includes('xml') ? (
                                  <span
                                    onClick={() => handleTranscript(itm?.id)}
                                    className="cstm-transcript"
                                  >
                                    view
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => handleTranscript(itm?.id)}
                                    className="cstm-transcript"
                                  >
                                    transcript
                                  </span>
                                ))} */}
                            </h6>
                          </div>
                          {itm?.status !== 'Completed' && (
                            <div className="close-icon d-flex">
                              <input
                                type="button"
                                className="btn btn-primary btn-sm me-2 text-white px-4"
                                style={{
                                  border:
                                    itm?.status === 'Invalid'
                                      ? '1px solid #bf0000'
                                      : itm?.status === 'Processing'
                                      ? '1px solid #ffa500'
                                      : '0',

                                  background:
                                    itm?.status === 'Invalid'
                                      ? '#bf0000'
                                      : itm?.status === 'Processing'
                                      ? '#ffa500'
                                      : '',
                                }}
                                value={
                                  itm?.status === 'Processing'
                                    ? 'Processing'
                                    : itm?.status === 'Invalid'
                                    ? 'Failed'
                                    : ''
                                }
                              />
                              <span
                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              >
                                <img
                                  src={trashImage}
                                  alt=""
                                  width={18}
                                  onClick={() => handleDeleteFile(itm?.id, itm?.category_id)}
                                />
                              </span>
                            </div>
                          )}
                          {itm?.status === 'Completed' && (
                            <span
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            >
                              <img
                                src={trashImage}
                                alt=""
                                width={18}
                                onClick={() => handleDeleteFile(itm?.id, itm?.category_id)}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                    {docCount < itm?.documents?.length && itm?.documents?.length > 25 && (
                      <div className="loadmore">
                        <span
                          onClick={() => handleLoadMore(itm?.documents?.length)}
                          className="loadmorespan"
                        >
                          Load more
                        </span>
                      </div>
                    )}
                    {itm?.documents?.length === 0 && !showProgressbar && (
                      <p className="text-center">No documents</p>
                    )}
                  </AccordionDetails>
                </Accordion>
                <div style={{ margin: '8px 8px 0 0' }}>
                  {itm?.documents?.length !== 0 ? (
                    <button
                      style={{
                        height: '30px',
                        width: '104px',
                        background: categoryId === itm?.id && 'transparent',
                        color: categoryId === itm?.id && '#fff',
                      }}
                      onClick={() => {
                        var sessionId = uuid()
                        localStorage.setItem('sessionId', sessionId)
                        if (categoryId === itm?.id) {
                          setCategoryId(null)
                          handleClearHistory()
                        } else {
                          setCategoryId(itm?.id)
                          setCategoryTitle(itm?.category_name)
                          dispatch(clearHistory())
                          dispatch(clearMessages())
                          setFirstMessage(true)
                        }
                      }}
                      className="btn btn-primary"
                    >
                      {categoryId === itm?.id ? 'Selected' : 'Select'}
                    </button>
                  ) : (
                    <div
                      style={{
                        height: '30px',
                        width: '104px',
                        visibility: 'hidden',
                      }}
                    >
                      .
                    </div>
                  )}
                </div>
              </div>
            ))}
            </div>
        </div>
        {!isLoading && documents?.data?.length === 0 && (
          <div className="nodatas text-center">
            <img src={NoDataImage} alt="nodata" width={40} height={40} />
            <span style={{ marginLeft: '8px', fontWeight: '700' }}>No data available</span>
          </div>
        )}
      </div>

      <Chat
        categoryId={categoryId}
        categoryTitle={categoryTitle}
        setCategoryTitle={setCategoryTitle}
        setCategoryId={setCategoryId}
        expandChat={expandChat}
        setExpandChat={setExpandChat}
        setFirstMessage={setFirstMessage}
        firstMessage={firstMessage}
      />

      <div className="chatbox-icon" onClick={() => setExpandChat(!expandChat)}>
        <img src={expandChat ? closeChatImage : chatImage} alt="" />
      </div>
      {isDocModalVisible && (
        <AddModal
          setShowProgressbar={setShowProgressbar}
          setUploadingCategory={setUploadingCategory}
          visible={isDocModalVisible}
          closeModal={closeModal}
          setUploadPercentage={setUploadPercentage}
        />
      )}
      <ConfirmDelete
        isCustomBoard={true}
        visible={deleteModal}
        cancelDelete={onConfirmCancel}
        confirmDelete={onDelete}
        isDeleteButtonDisable={isDeleteButtonDisable}
        deleteButtonDisable={loading}
      />
      <TranscriptModal
        visible={transcriptModalVisible}
        transcriptData={transcript}
        isTranscriptLoading={isTranscriptLoading}
        onClose={() => {
          setTranscriptModalVisible(false)
          dispatch(clearDocumentEditListData())
        }}
      />
    </div>
  )
}
