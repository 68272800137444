import ReactSlider from 'react-slider'
import '../scss/rangeSlider.scss'

export default function RangeSlider({
  inputValue = 0,
  setValue = undefined,
  minimumValue = 0,
  maximumValue = 100,
  rangeStep = 10,
  rangeLabel = '',
  fieldLabel = 'Temperature',
}) {
  return (
    <>
      {typeof rangeLabel === 'string' && rangeLabel.trim() !== '' ? (
        <>
          <div className="row my-2">
            <div className="col-md-12">{rangeLabel}</div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="row my-2">
        <div className="col-md-6 d-flex justify-content-start">
          {typeof fieldLabel === 'string' ? fieldLabel : ''}
        </div>
        <div className="col-md-6 d-flex justify-content-md-end justify-content-sm-end">
          {inputValue}
        </div>
      </div>
      <div className="row my-2">
        <div className="col-md-12">
          <ReactSlider
            value={inputValue}
            min={minimumValue}
            max={maximumValue}
            step={rangeStep}
            marks={20}
            className="customSlider"
            trackClassName="customSlider-track"
            thumbClassName="customSlider-thumb"
            onAfterChange={(value) => {
              if (typeof setValue !== 'undefined') {
                setValue(value)
              }
            }}
          />
        </div>
      </div>
    </>
  )
}
