export const Configuration = {
  dashboard: {
    dashBoard: 'dashboard/',
  },
  user: {
    login: 'login/',
  },
  wordConversion: {
    getWordConversions: 'word_conversion/',
  },
  rephrasingManagement: {
    getWordParaphrase: 'word_paraphrase/',
  },
  queryManagement: {
    query: 'databases/',
  },
  documentManagement: {
    document: 'document/',
    regenerate: 'regenerate/',
    dataset: 'dataset/',
  },
  dataSet: {
    dataSource: 'datasource/',
    import: 'import',
    temperature: 'temperature',
  },
  dataLog: {
    logging: 'log/',
    approve: 'approve/',
    addtodatasource: 'add_to_datasource/',
    rank: 'rank/',
  },
  datasetCategory: {
    category: 'category/',
  },
  sync: {
    sync: 'sync/',
  },
  organization: {
    list: 'organization',
  },
  department: {
    list: 'department',
  },
  category: {
    list: 'category/',
  },
}
