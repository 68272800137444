/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef } from 'react'
import { ImpulseSpinner } from 'react-spinners-kit'
import ai3 from '../../../assets/adminBoard/aiLogo.svg'
import PropTypes from 'prop-types'
import Message from './message'

export default function Messages({
  messages,
  show,
  toggleLoading,
  category,
  categoryId,
  addMessage,
  streamingStatus,
  setStreamingStatus,
  runningStream,
  setRunningStream,
  setChatloading,
  isUserScroll,
  setIsUserScroll,
}) {
  const scrollRef = useRef(null)
  const viewRef = useRef(null)
  useEffect(updateScroll, [messages])

  useEffect(() => {
    if (!scrollRef.current) return // wait for the elementRef to be available
    const resizeObserver = new ResizeObserver(() => {
      updateScroll()
    })
    resizeObserver.observe(scrollRef.current)
    return () => resizeObserver.disconnect() // clean up
  }, [messages])

  function updateScroll() {
    if (!isUserScroll) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Fragment>
      <div ref={viewRef}></div>

      {messages.map((item) => {
        return (
          <div ref={scrollRef} key={item._id}>
            <Message
              id={item._id}
              me={item.me}
              msg={item.msg}
              _id={item._id}
              key={item._id}
              animate={item._id === messages[messages.length - 1]._id}
            />
          </div>
        )
      })}

      {show && (
        <div className="chat-panel-loading" style={{ background: '#5f605f41' }}>
          <div className="chatIconDiv">
            <div className="chatIcon">
              <img src={ai3} width="30" alt="" />
            </div>
          </div>
          <pre className="spinner-container" style={{ overflow: 'hidden' }}>
            <ImpulseSpinner frontColor="white" size={25} />
          </pre>
        </div>
      )}
    </Fragment>
  )
}

Messages.propTypes = {
  messages: PropTypes.array,
  show: PropTypes.bool,
  toggleLoading: PropTypes.func,
  category: PropTypes.string,
}
