import { useEffect, useRef, useState } from 'react'
import chartImg from '../../../assets/images/avatars/noDashboard.svg'
import { Pie } from 'react-chartjs-2'
import CIcon from '@coreui/icons-react'
import { cilTrash, cilFullscreen } from '@coreui/icons'
import CustomModal from '../../../components/CustomModal'
import pulseImg from '../../../assets/adminBoard/pulse.svg'
import axios from 'axios'
import { getHeaders } from 'src/utility/commonUtils'
import Chart from 'chart.js/auto';

const CreateNew = ({
  exportChartArr = [],
  isExportChartArrLoading = false,
  setIsExportChartArrLoading = () => {},
  getExportDatas = () => {},
}) => {
  const [isDeleteShow, setIsDeleteShow] = useState(false)
  const [deleteId, setDeleteId] = useState(0)
  const [deleteMsg, setDeleteMsg] = useState('')
  const [isZoomDataShow, setIsZoomDataShow] = useState(false)
  const [zoomData, setZoomData] = useState(undefined);
  const zoomchartRef = useRef(null); 
  const chartRefs = useRef([]);
  const chartInstances = useRef([]);

  /**
   * Delete chart item
   */
  const deleteChart = async () => {
    try {
      setDeleteMsg('')
      setIsExportChartArrLoading(true)
      await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/chart/${deleteId}`, {
        headers: getHeaders(),
      })
      setIsDeleteShow(false)
      getExportDatas()
    } catch (errorObj) {
      const errorMsg =
        typeof errorObj?.response?.data?.detail === 'string' ? errorObj.response.data.detail : ''
      setDeleteMsg(errorMsg)
      setIsExportChartArrLoading(false)
    }
  }
  // old chartjs implimentatiom working model
  // useEffect(() => {
  //   // Destroy previous chart instances to avoid memory leaks
  //   chartInstances.current.forEach((chartInstance) => {
  //     if (chartInstance) {
  //       chartInstance.destroy();
  //     }
  //   });

  //   // Clear the chart instances array before populating with new instances
  //   chartInstances.current = [];

  //   // Loop through exportChartArr and create a chart for each object
  //   exportChartArr.forEach((chartData, index) => {
  //     const ctx = chartRefs.current[index]?.getContext('2d');

  //     // Ensure the canvas context exists before creating the chart
  //     if (ctx) {
  //       const newChartInstance = new Chart(ctx, {
  //         type: chartData.chart_data.type,
  //         data: chartData.chart_data.data,
  //         options: chartData.chart_data.options,
  //       });

  //       // Store each chart instance in the array
  //       chartInstances.current[index] = newChartInstance;
  //     }
  //   });
  // }, [exportChartArr]); 

  // new implimentation 
  useEffect(() => {
    // Destroy previous chart instances to avoid memory leaks
    chartInstances.current.forEach((chartInstance) => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    });
  
    // Clear the chart instances array before populating with new instances
    chartInstances.current = [];
  
    // Loop through exportChartArr and create a chart for each object
    exportChartArr.forEach((chartData, index) => {
      const ctx = chartRefs.current[index]?.getContext('2d');
  
      // Ensure the canvas context exists before creating the chart
      if (ctx) {
        // Define the default options with white labels for axes and legends
        const defaultOptions = {
          plugins: {
            legend: {
              labels: {
                color: 'white', // Custom color for legend labels
              },
            },
            title: {
              display: true,
              text: 'Custom Chart Title',
              color: 'white',
              font: {
                size: 20,
              },
              padding: {
                top: 10,
                bottom: 30,
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: 'white', // Custom color for X-axis labels
              },
              title: {
                color: 'white', // Custom color for X-axis title
              },
              grid: {
                color: 'rgba(255, 255, 255, 0.2)', // Gridline color for X-axis
              },
            },
            y: {
              ticks: {
                color: 'white', // Custom color for Y-axis labels
              },
              title: {
                color: 'white', // Custom color for Y-axis title
              },
              grid: {
                color: 'rgba(255, 255, 255, 0.2)', // Gridline color for Y-axis
              },
              beginAtZero: true,
            },
          },
        };
  
        // Check if scales object from backend has any keys (x, y)
        const hasScales = chartData.chart_data.options?.scales && Object.keys(chartData.chart_data.options.scales).length > 0;
  
        // Merge the backend options and ensure colors are white where needed
        const mergedOptions = {
          ...defaultOptions,
          ...chartData.chart_data.options,
          plugins: {
            ...defaultOptions.plugins,
            ...chartData.chart_data.options.plugins,
            legend: {
              ...defaultOptions.plugins.legend,
              ...chartData.chart_data.options.plugins?.legend,
              labels: {
                ...defaultOptions.plugins.legend.labels,
                ...chartData.chart_data.options.plugins?.legend?.labels,
                color: 'white', // Ensure legend labels are white
              },
            },
            title: {
              ...defaultOptions.plugins.title,
              ...(chartData.chart_data.options.plugins?.title || {}),
              color: 'white', // Ensure title text is white
            },
          },
          scales: hasScales
            ? {
                ...defaultOptions.scales,
                ...chartData.chart_data.options.scales,
                x: {
                  ...defaultOptions.scales.x,
                  ...(chartData.chart_data.options.scales?.x || {}),
                  ticks: {
                    ...defaultOptions.scales.x.ticks,
                    ...(chartData.chart_data.options.scales?.x?.ticks || {}),
                    color: 'white', // Ensure X-axis ticks are white
                  },
                  title: {
                    ...defaultOptions.scales.x.title,
                    ...(chartData.chart_data.options.scales?.x?.title || {}),
                    color: 'white', // Ensure X-axis title is white
                  },
                },
                y: {
                  ...defaultOptions.scales.y,
                  ...(chartData.chart_data.options.scales?.y || {}),
                  ticks: {
                    ...defaultOptions.scales.y.ticks,
                    ...(chartData.chart_data.options.scales?.y?.ticks || {}),
                    color: 'white', // Ensure Y-axis ticks are white
                  },
                  title: {
                    ...defaultOptions.scales.y.title,
                    ...(chartData.chart_data.options.scales?.y?.title || {}),
                    color: 'white', // Ensure Y-axis title is white
                  },
                },
              }
            : {}, // If no scales, set scales to an empty object
        };
  
        console.log(mergedOptions);
  
        // Create a new chart instance with the updated options
        const newChartInstance = new Chart(ctx, {
          type: chartData.chart_data.type, // Line, Bar, Pie, etc.
          data: chartData.chart_data.data,
          options: mergedOptions,
        });
  
        // Store each chart instance in the array
        chartInstances.current[index] = newChartInstance;
      }
    });
  }, [exportChartArr]);
  
  
  
  
  
  
  
  
  
  
  
  

  const handleZoomClick = (chartData, index) => {
    setZoomData(chartData); // Set the chart data for zooming
    setIsZoomDataShow(true); // Open modal
  };



  useEffect(() => {
    let chartInstance;

    if (isZoomDataShow && zoomData) {
      const ctx = document.getElementById('zoomedChart')?.getContext('2d');

      if (ctx) {
        // Destroy existing chart instance if it exists
        if (zoomchartRef.current) {
          zoomchartRef.current.destroy();
        }

        // Create new chart instance
        chartInstance = new Chart(ctx, {
          type: zoomData.chart_data.type,
          data: zoomData.chart_data.data,
          options: zoomData.chart_data.options,
        });

        // Store the chart instance in the ref
        zoomchartRef.current = chartInstance;
      }
    }

    // Cleanup: destroy chart when modal is closed
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [isZoomDataShow]);
  
  return (
    <>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div
          className={`card-dashboard bg-color-transparent w-100 ${
            isExportChartArrLoading ? 'd-none' : ''
          }`}
        >
          {/* {Array.isArray(exportChartArr) && exportChartArr.length > 0 ? (
            <>
              <div className="row">
                {exportChartArr.map((itemObj, itemIndex) => {
                  return (
                    <>
                      <div
                        className="breif-chart text-white mb-4 col-md-5 col-sm-10 me-md-2"
                        key={'chartExp' + itemIndex}
                      >
                        <div className="row">
                          <div className="col-md-10">
                            <h5>
                              {typeof itemObj?.chart_data?.title === 'string'
                                ? itemObj.chart_data.title
                                : ''}
                            </h5>
                          </div>
                          <div className="col-md-2 d-flex align-items-start justify-content-end gap-2">
                            <div
                              onClick={(event) => {
                                event.preventDefault()
                                setZoomData(itemObj)
                                setIsZoomDataShow(true)
                              }}
                            >
                              <CIcon
                                icon={cilFullscreen}
                                color="danger"
                                size="lg"
                                title="Zoom In"
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                            <div
                              onClick={(event) => {
                                event.preventDefault()
                                setDeleteId(itemObj?.id)
                                setIsDeleteShow(true)
                              }}
                            >
                              <CIcon
                                icon={cilTrash}
                                color="danger"
                                size="lg"
                                title="Delete"
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </div>
                        </div>

                        {typeof itemObj?.chart_data?.pieChart !== 'undefined' ? (
                          <div>
                            <Pie
                              data={itemObj.chart_data.pieChart}
                              options={{
                                maintainAspectRatio: false,
                                plugins: {
                                  legend: {
                                    display: true,
                                    labels: {
                                      color: 'rgb(255, 255, 255)',
                                    },
                                  },
                                },
                              }}
                              style={{ cursor: 'pointer' }}
                              width={400}
                              height={400}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {Array.isArray(itemObj?.chart_data?.table?.tableDatas?.header) &&
                        Array.isArray(itemObj?.chart_data?.table?.tableDatas?.body) ? (
                          <>
                            <div className="table-responsive text-white ms-28px">
                              <table className="table">
                                {itemObj.chart_data.table.tableDatas.header.length > 0 ? (
                                  <>
                                    <thead>
                                      <tr>
                                        {itemObj.chart_data.table.tableDatas.header.map(
                                          (hText, hIndex) => {
                                            return (
                                              <th
                                                scope="col"
                                                key={'hIndex' + hIndex}
                                                className="text-color-white"
                                              >
                                                {typeof hText === 'string' ? hText : ''}
                                              </th>
                                            )
                                          },
                                        )}
                                      </tr>
                                    </thead>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {itemObj.chart_data.table.tableDatas.body.length > 0 ? (
                                  <>
                                    <tbody>
                                      {itemObj.chart_data.table.tableDatas.body.map(
                                        (bItemObj, bIndex) => {
                                          return (
                                            <>
                                              <tr key={'body' + bIndex}>
                                                {itemObj.chart_data.table.tableDatas.header.map(
                                                  (hText, hIndex) => {
                                                    return (
                                                      <td
                                                        key={
                                                          'hIndex_' + hIndex + '_bIndex_' + bIndex
                                                        }
                                                        className="text-color-white"
                                                      >
                                                        {bItemObj?.[hText]}
                                                      </td>
                                                    )
                                                  },
                                                )}
                                              </tr>
                                            </>
                                          )
                                        },
                                      )}
                                    </tbody>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </table>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )
                })}
              </div>
            </>
          ) : (
            <>
              <div style={{ marginTop: '30px', textAlign: 'center' }}>
                <img
                  src={chartImg}
                  alt="chart-img"
                  style={{ maxWidth: '100%', height: 'calc(100vh - 336px)' }}
                />
              </div>
              <div className="d-flex justify-content-center ">
                <h1 className="cstm-font">Choose a dashboard and create your customized charts</h1>
              </div>
            </>
          )} */}
     <div className='chartjs-container'>
  {exportChartArr.map((chartData, index) => (
    <div
      key={chartData.id}
      className='chart-item'
    >
      {/* Button container */}
      <div className="col-md-2 d-flex align-items-start justify-content-end gap-2 w-100">
        <div
          onClick={(event) => {
            event.preventDefault();
            handleZoomClick(chartData, index); 
          }}
        >
          <CIcon
            icon={cilFullscreen}
            color="danger"
            className='text-white'
            size="lg"
            title="Zoom In"
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div
          onClick={(event) => {
            event.preventDefault();
            setDeleteId(chartData?.id);
            setIsDeleteShow(true);
          }}
        >
          <CIcon
            icon={cilTrash}
            color="danger"
            className='text-white'
            size="lg"
            title="Delete"
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>

      {/* Chart container */}
      <div style={{ flexGrow: 1, height: 'calc(100% - 40px)' }}>
        <canvas ref={(el) => (chartRefs.current[index] = el)}></canvas>
      </div>
    </div>
  ))}
</div>

        </div>
        <div
          className={`card-dashboard ${!isExportChartArrLoading ? 'd-none' : ''}`}
          style={{ background: 'transparent' }}
        >
          <div className="upload-items uploded mb-4 mx-2">
            <div className="uploaded-data d-flex">
              <div className="progress-sc me-3">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center mx-2 text-white">Loading </div>
                  <img src={pulseImg} alt="loader" width={36} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleteShow ? (
        <>
          <CustomModal
            closeModal={() => {
              setIsDeleteShow(false)
            }}
            cancelButtonFn={() => {
              setIsDeleteShow(false)
            }}
            cancelButtonLabel="Cancel"
            okButtonLabel="Ok"
            onSubmit={(event) => {
              event.preventDefault()
              deleteChart()
            }}
            visible={isDeleteShow}
            okButtonDisabled={isExportChartArrLoading}
          >
            {typeof deleteMsg === 'string' && deleteMsg.trim() !== '' ? (
              <>
                <div className="text-danger">{deleteMsg}</div>
              </>
            ) : (
              <></>
            )}
            <div>Are you sure you want to delete this item?</div>
          </CustomModal>
        </>
      ) : (
        <></>
      )}
      {isZoomDataShow ? (
        <>
          <CustomModal
            closeModal={() => {
              setIsZoomDataShow(false)
              setZoomData(undefined)
            }}
            cancelButtonFn={() => {
              setIsZoomDataShow(false)
              setZoomData(undefined)
            }}
            cancelButtonLabel="Cancel"
            visible={isZoomDataShow}
            okButtonDisabled={isExportChartArrLoading}
            isCustomBoard={true}
            modalSize="xl"
            // headerText={
            //   'hi'
            // }
          >
            {/* Chart rendering inside modal */}
          <div style={{ width: '100%', height: '600px' }}>
            <canvas id="zoomedChart"></canvas>
          </div>
          </CustomModal>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default CreateNew
