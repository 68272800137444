import { toast as toasts } from 'react-toastify'
import '../scss/_custom.scss'

export const toast = (message, type) => {
  toasts(message, {
    hideProgressBar: true,
    theme: 'colored',
    autoClose: true,
    type: type,
  })
}
