/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import microphoneImage from '../../../assets/adminBoard/microphone-start.png'
import microphoneStopImage from '../../../assets/adminBoard/microphone-stop.png'
const Dictaphone = ({ handleMicrophone }) => {
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition()

  const [micImage, setMicImage] = useState(microphoneImage)
  const [isListening, setIsListening] = useState(true)

  useEffect(() => {
    if (listening) {
      handleMicrophone(transcript)
    }
  }, [transcript])

  const toggleMicrophone = (value) => {
    setIsListening(!isListening)
    if (listening && !isListening) {
      SpeechRecognition.stopListening()
      setMicImage(microphoneImage)
      resetTranscript()
    } else {
      SpeechRecognition.startListening({ continuous: true })
      setMicImage(microphoneStopImage)
    }
  }

  if (!browserSupportsSpeechRecognition) {
    return <span>{"Browser doesn't support speech recognition."}</span>
  }

  return (
    <div>
      <button
        className="mic-icon "
        onClick={(e) => {
          toggleMicrophone(e.target.value)
        }}
      >
        <img src={micImage} width={25} height={25} alt="Microphone" />
      </button>
    </div>
  )
}
export default Dictaphone
