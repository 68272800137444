/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './scss/_restructure.scss'
import './scss/_admin.scss'
import './scss/_all.scss'
import './scss/_leftsidebar.scss'
import NewLandingPage from './views/NewLandingPage'
import DashboardLayout from './views/UserDashboard/dashboardLayout'
import Layout from './views/CustomBoard/layout'
import CustomBoardChartEmbed from './views/CustomBoard/Chart/chartEmbed'
import CustomBoard from './views/CustomBoard/index'
import CustomBoardDatalog from './views/CustomBoard/Datalog/index.js'
import CustomBoardChart from './views/CustomBoard/Chart/dashboardLayout.js'
import EmbedDashboard from './views/UserDashboard/embedDashboard'
import CustomBoardEmbedDashboard from './views/CustomBoard/Chart/embedDashboard'
import EmbedChat from './views/CustomBoard/embedChat'
import UserManagment from './views/CustomBoard/UserManagment'
import UserCreation from './views/CustomBoard/UserCreation'
import Department from './views/CustomBoard/UserManagment/department'
import Category from './views/CustomBoard/UserManagment/category'
import { logout } from './redux/auth/actions'
import OpenChat from './views/OpenChat'
import Insurance from './views/Insurance'
import Policy from './views/Policy'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Index = React.lazy(() => import('./views/ChatGPT/Index'))
const NewLogin = React.lazy(() => import('./views/CustomBoard/Login/index.js'))
const QueryIndex = React.lazy(() => import('./views/QueryGPT/Index'))
const DocumentIndex = React.lazy(() => import('./views/DocumentGPT/Index'))
const ChartEmbed = React.lazy(() => import('./views/UserDashboard/chartEmbed'))

const LogoutTimeOut = 30 * 60 * 1000 // 30 minutes timeout

const App = () => {
  const [inactiveTime, setInactiveTime] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    let timeoutId

    const resetTimer = () => {
      clearTimeout(timeoutId)
      setInactiveTime(0)
      timeoutId = setTimeout(logoutUser, LogoutTimeOut) // 10 minutes timeout
    }

    const logoutUser = () => {
      dispatch(logout())
    }

    const handleActivity = () => {
      if (inactiveTime >= LogoutTimeOut) {
        logoutUser()
      } else {
        resetTimer()
      }
    }

    const handleMouseMove = () => {
      setInactiveTime(0) // Reset inactive time on mouse move
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('keydown', handleActivity)

    resetTimer() // Initial setup

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('keydown', handleActivity)
    }
  }, [inactiveTime])

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Default" element={<NewLandingPage />} />
          <Route exact path="/user-dashboard" name="Dashboard" element={<DashboardLayout />} />
          <Route exact path="/user-dashboard/:id" name="Dashboard" element={<DashboardLayout />} />
          <Route
            exact
            path="/embed-dashboard/:id"
            name="EmbedDashboard"
            element={<EmbedDashboard />}
          />
          <Route
            exact
            path="/datasetCategorySearch"
            name="Dataset category Page"
            element={<Index />}
          />
          <Route exact path="/ai-query-gpt" name="AI Query Page" element={<QueryIndex />} />
          <Route exact path="/doc-gpt" name="Document Search" element={<DocumentIndex />} />
          <Route exact path="/embedChart/:id" element={<ChartEmbed />} />
          <Route exact path="/login" name="Login" element={<NewLogin />} />
        </Routes>
        <Layout>
          <Routes>
            <Route exact path="/Policy" name="Policy" element={<Policy/>} /> 
            <Route exact path="/InsuranceAdvisor" name="InsuranceAdvisor" element={<Insurance/>} />
            <Route exact path="/OpenChat" name="OpenChat" element={<OpenChat/>} />
            <Route exact path="/mydashboard" name="Dashboard" element={<CustomBoard />} />
            <Route exact path="/mydatalog" name="Datalog" element={<CustomBoardDatalog />} />
            <Route exact path="/mychart" name="Chart" element={<CustomBoardChart />} />
            <Route
              exact
              path="/user-management"
              name="User Management"
              element={<UserManagment />}
            />
             <Route
              exact
              path="/user-creation"
              name="User Management"
              element={<UserCreation/>}
            />
            <Route exact path="/department/:id" name="Department" element={<Department />} />
            <Route exact path="/category/:id" name="Category" element={<Category />} />
            <Route exact path="/mychart/:id" name="Dashboard" element={<CustomBoardChart />} />
            <Route
              exact
              path="/mychart-embbed/:id"
              name="Dashboard"
              element={<CustomBoardChartEmbed />}
            />
            <Route
              exact
              path="/mychartembed-dashboard/:id"
              name="EmbedDashboard"
              element={<CustomBoardEmbedDashboard />}
            />
            <Route exact path="/chat/embed" name="EmbedChat" element={<EmbedChat />} />
            <Route exact path="/chat/embed/:id" name="EmbedChat" element={<EmbedChat />} />
          </Routes>
        </Layout>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
