/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { CRow, CCol, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
import { toast } from 'src/helpers/toast'
import * as Yup from 'yup'
import CustomModal from '../../../components/CustomModal'
import { axiosPOST, axiosPOSTMeta, axiosPUT } from 'src/utility/apis'
import addDoc from '../../../assets/images/avatars/add-document-icon.svg'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { TextField } from '@mui/material'

const UserModalUpdate = ({ visible, closeModal, iseditObj, orgID }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async (values) => {
    if (iseditObj) {
      let payload = {
        name: values.name,
      }
      try {
        setIsLoading(true)
        let res = await axiosPUT(`department/${iseditObj.id}`, payload)
        if (res?.detail) {
          setIsLoading(false)
          toast(res?.data?.detail?.[0]?.msg, 'error')
        } else {
          setIsLoading(false)
          toast('Department updated successfully', 'success')
          closeModal()
        }
      } catch (err) {
        setIsLoading(false)
        toast('Something went wrong !!', 'error')
      }
    } else {
      let payload = {
        organization_id: orgID,
        name: values.name,
      }
      try {
        setIsLoading(true)
        let res = await axiosPOST('department/', payload)
        if (res.status === 422) {
          setIsLoading(false)
          toast(res?.data?.detail?.[0]?.msg, 'error')
        } else {
          setIsLoading(false)
          closeModal()
        }
      } catch (err) {
        setIsLoading(false)
        toast('Something went wrong !!', 'error')
      }
    }
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(50, 'Maximum 50 characters allowed'),
  })
  return (
    <>
      <CustomModal
        isCustomBoard={true}
        visible={visible}
        closeModal={closeModal}
        modalSize="md"
        noMinHeight
        headerText={iseditObj ? 'Edit User' : 'Add User'}
      >
        <Formik
          initialValues={{
            name: iseditObj ? iseditObj.name : '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleSubmit, setFieldValue,errors }) => (
            <Form>
              <CRow className="mb-3">
                <CCol md={12} className="ps-lg-2">
                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Name"
                        fullWidth
                        defaultValue={values?.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      <ErrorMessage name="name" component="div" className="error" />
                    </div>
                  </div>

                  {/* <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Username"
                        fullWidth
                        defaultValue={values?.username}
                        onChange={(e) => setFieldValue('username', e.target.value)}
                      />
                      <ErrorMessage name="username" component="div" className="error" />
                    </div>
                  </div> */}

                  {/* <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Password"
                        fullWidth
                        defaultValue={values?.password}
                        onChange={(e) => setFieldValue('password', e.target.value)}
                      />
                      <ErrorMessage name="password" component="div" className="error" />
                    </div>
                  </div> */}

                  {/* <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Email"
                        fullWidth
                        defaultValue={values?.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                      />
                      <ErrorMessage name="email" component="div" className="error" />
                    </div>
                  </div> */}
                </CCol>
              </CRow>
              <div className="d-flex justify-content-end">
                <button type="button" onClick={closeModal} className="btn btn-secondary">
                  Cancel
                </button>
                <button
                  disabled={isSubmitting || !(values?.name?.trim())}
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault()
                    handleSubmit(values) // Prevent default form submission
                  }}
                  // type='submit'
                  style={{ marginLeft: '8px' }}
                >
                  {isLoading && <CSpinner size="sm" style={{ marginRight: '8px' }}></CSpinner>}{' '}
                  {iseditObj ? 'Save' : 'Add'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  )
}

UserModalUpdate.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  // loadWordList: PropTypes.func,
  isEdit: PropTypes.bool,
  editData: PropTypes.array,
}

export default UserModalUpdate
