/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { CRow, CCol, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'
import { toast } from 'src/helpers/toast'
import * as Yup from 'yup'
import CustomModal from '../../../components/CustomModal'
import { axiosPOSTMeta, axiosPUT, axiosPUTMeta } from 'src/utility/apis'
import { ErrorMessage, Form, Formik } from 'formik'
import { getOrganizationList } from 'src/redux/organization/actions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'

const EditModal = ({ visible, closeModal, editObj }) => { 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (values) => {
    if (editObj) {
      setIsLoading(true)
      try {
        let resp = await axiosPUT(`organization/${editObj?.id}`,{ name: values?.name })
        dispatch(getOrganizationList(navigate, 10, 1, '', false))
        if (resp?.detail) {
          toast(resp?.data?.detail?.[0]?.msg, 'error')
          setIsLoading(false)
        } else {
          setIsLoading(false)
          closeModal()
          // setImage(null)
        }
      } catch (err) {
        toast('Something went wrong !!', 'error')
        setIsLoading(false)
      }
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file && event?.target?.files?.[0]?.type?.includes('image')) {
      setImage(file)
    }
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (file && event.dataTransfer.files[0].type.includes('image')) {
      setImage(file)
    }
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(50, 'Maximum 50 characters allowed'),
  })
  return (
    <>
      <CustomModal
        isCustomBoard={true}
        visible={visible}
        closeModal={closeModal}
        modalSize="md"
        noMinHeight={true}
        headerText={editObj ? 'Edit organization' : 'Add organization'}
      >
        <Formik
          initialValues={{
            name: editObj ? editObj.name : '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, handleSubmit, setFieldValue, isValid }) => (
            <Form>
              <CRow className="mb-3">
                <CCol md={12} className="ps-lg-2">
                  <div>
                    <div className="px-2 mb-2">
                      <TextField
                        label="Organization Name"
                        fullWidth
                        defaultValue={values?.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                      />
                      <ErrorMessage name="name" component="div" className="error" />
                    </div>
                  </div>
                </CCol>
              </CRow>
              <div className="d-flex justify-content-end">
                <button type="button" onClick={closeModal} className="btn btn-secondary">
                  Cancel
                </button>
                <button
                  disabled={
                    isSubmitting ||
                    !(
                      values?.name?.trim()
                    )
                  }
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleSubmit(values) // Prevent default form submission
                  }}
                  // type='submit'
                  style={{ marginLeft: '8px' }}
                >
                  {isLoading && <CSpinner size="sm" style={{ marginRight: '8px' }}></CSpinner>}{' '}
                  {editObj ? 'Save' : 'Add'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  )
}



export default EditModal
