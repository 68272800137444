import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CContainer, CHeader, CHeaderBrand, CHeaderToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'
import { setSidebar } from 'src/redux/feature/sidebarSlice'

const DashboardHeader = () => {
  const dispatch = useDispatch()
  const { sidebarShow } = useSelector((state) => state.sidebar)

  return (
    <CHeader position="sticky">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => dispatch(setSidebar(!sidebarShow))}>
          <CIcon icon={cilMenu} size="lg" className="cstm-icon" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <h3>EWMB</h3>
        </CHeaderBrand>
      </CContainer>
    </CHeader>
  )
}

export default DashboardHeader
