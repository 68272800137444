/* eslint-disable react/jsx-no-target-blank */
import CIcon from '@coreui/icons-react'
import logoImg from '../assets/images/logo/whitecubet-logo-with-text.svg'
import { useNavigate } from 'react-router-dom'
import { cilAddressBook, cilBarChart } from '@coreui/icons'

export default function NoAuthHeader() {
  const navigate = useNavigate()

  return (
    <>
      <header className="ewmb-header" id="ewmb-header">
        <nav className="navbar navbar-expand-lg navbar-bgcolor" style={{ background: '#050f05' }}>
          <div className="container-fluid px-4">
            <a
              className="navbar-brand"
              href="''"
              onClick={(event) => {
                event.preventDefault()
                navigate('/')
              }}
            >
              <img src={logoImg} alt="Cubet" width={100} style={{ minWidth: '150px' }} />
            </a>
            <div className="d-flex">
              <CIcon
                icon={cilBarChart}
                style={{
                  width: '36px',
                  height: '36px',
                  marginRight: '22px',
                  cursor: 'pointer',
                  color: '#4BC641',
                  // padding: ' 0 6px',
                }}
                onClick={() => navigate('/user-dashboard')}
              />
              <CIcon
                icon={cilAddressBook}
                style={{
                  width: '36px',
                  height: '36px',
                  marginRight: '22px',
                  cursor: 'pointer',
                  color: '#4BC641',
                  // padding: ' 0 6px',
                }}
                onClick={() => navigate('/mydashboard')}
              />
              <a
                className="connect text-center connect-bgcolor d-md-block d-none fw-bold"
                target="_blank"
                href="https://cubettech.com/"
              >
                <span>Connect Now</span>
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}
