// **  Initial State
const initialState = {
  isLoading: false,
  data: null,
  isFileUpload: false,
  file: null,
  helpDeskTemperature: 0,
  tendersTemperature: 0,
  documentTemperature: 0,
}

export const dataSet = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADER_START':
      return {
        ...state,
        isLoading: true,
      }
    case 'LOADER_STOP':
      return {
        ...state,
        isLoading: false,
      }
    case 'SET_DATA_SET':
      return {
        ...state,
        data: action.payload,
      }
    case 'FILE_LOADER_START':
      return {
        ...state,
        isFileUpload: true,
      }
    case 'FILE_LOADER_STOP':
      return {
        ...state,
        isFileUpload: false,
      }
    case 'SET_FILE':
      return {
        ...state,
        file: action.payload,
      }
    case 'SET_HELPDESK_TEMPERATURE_VALUE':
      return {
        ...state,
        helpDeskTemperature: action.payload,
      }
    case 'SET_TENDERS_TEMPERATURE_VALUE':
      return {
        ...state,
        tendersTemperature: action.payload,
      }
    case 'SET_DOCUMENT_TEMPERATURE_VALUE':
      return {
        ...state,
        documentTemperature: action.payload,
      }
    default:
      return state
  }
}
