import React from 'react'

import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'

const Paginate = ({ handlePageClick, currentPage, totalCount, limit }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        pageCount={Math.ceil(totalCount / limit)}
        forcePage={currentPage - 1}
        previousLabel="<"
        renderOnZeroPageCount={null}
        containerClassName={'pagination '}
        subContainerClassName={'pages pagination'}
        breakClassName={'page-item'}
        breakLinkClassName={'pagination__link--break'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item '}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        disabledClassName={'pagination__link--disabled'}
      />
    </div>
  )
}

Paginate.propTypes = {
  handlePageClick: PropTypes.func,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  limit: PropTypes.number,
}

export default React.memo(Paginate)
